import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

export default function AppSettingsPayout({ onSubmit, appSettings, isView }) {
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (appSettings) {
      const {
        subwalletGuid,
        payout_merchant_id,
        payout_merchant_key,
        paytm_min_withdraw_amount,
        gateway_merchant_id,
        gateway_merchant_key,
        industry_type,
        channel_id_for_website,
        channel_id_for_mobile,
      } = appSettings;
      setValue("subwalletGuid", subwalletGuid);
      setValue("payout_merchant_id", payout_merchant_id);
      setValue("payout_merchant_key", payout_merchant_key);
      setValue("paytm_min_withdraw_amount", paytm_min_withdraw_amount);
      setValue("gateway_merchant_id", gateway_merchant_id);
      setValue("gateway_merchant_key", gateway_merchant_key);
      setValue("industry_type", industry_type);
      setValue("channel_id_for_website", channel_id_for_website);
      setValue("channel_id_for_mobile", channel_id_for_mobile);
    }
  }, [appSettings]);

  return (
    <div className="row">
      <div className="col-12 col-md-6">
        <div className="portlet">
          <div className="horizontal_filter_card">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row mb-4">
                <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                  Sub wallet guid
                </label>
                <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <input
                    {...register("subwalletGuid", {
                      required: "Wallet guid is required.",
                    })}
                    aria-invalid={errors.subwalletGuid ? "true" : "false"}
                    className="form-control form-control-lg"
                    type="text"
                    placeholder="Enter wallet guid"
                    disabled={isView}
                  />
                  {errors.subwalletGuid && (
                    <p role="alert" className="text-danger">
                      {errors.subwalletGuid?.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                  Payment merchant id
                </label>
                <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <input
                    {...register("payout_merchant_id", {
                      required: "Payment merchant id is required.",
                    })}
                    aria-invalid={errors.payout_merchant_id ? "true" : "false"}
                    className="form-control form-control-lg"
                    type="text"
                    placeholder="Enter payment merchant id"
                    disabled={isView}
                  />
                  {errors.payout_merchant_id && (
                    <p role="alert" className="text-danger">
                      {errors.payout_merchant_id?.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                  Payout merchant key
                </label>
                <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <input
                    {...register("payout_merchant_key", {
                      required: "Payout key is required.",
                    })}
                    aria-invalid={errors.payout_merchant_key ? "true" : "false"}
                    className="form-control form-control-lg"
                    type="text"
                    placeholder="Enter payout key"
                    disabled={isView}
                  />
                  {errors.payout_merchant_key && (
                    <p role="alert" className="text-danger">
                      {errors.payout_merchant_key?.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                  Paytm minimum withdraw amount
                </label>
                <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <input
                    {...register("paytm_min_withdraw_amount", {
                      required: "Minimum withdraw amount is required.",
                    })}
                    aria-invalid={
                      errors.paytm_min_withdraw_amount ? "true" : "false"
                    }
                    className="form-control form-control-lg"
                    type="text"
                    placeholder="Enter minimum withdraw amount"
                    disabled={isView}
                  />
                  {errors.paytm_min_withdraw_amount && (
                    <p role="alert" className="text-danger">
                      {errors.paytm_min_withdraw_amount?.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                  Gateway merchant id
                </label>
                <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <input
                    {...register("gateway_merchant_id", {
                      required: "Merchant id is required.",
                    })}
                    aria-invalid={errors.gateway_merchant_id ? "true" : "false"}
                    className="form-control form-control-lg"
                    type="text"
                    placeholder="Enter merchant id"
                    disabled={isView}
                  />
                  {errors.gateway_merchant_id && (
                    <p role="alert" className="text-danger">
                      {errors.gateway_merchant_id?.message}
                    </p>
                  )}
                </div>
              </div>

              <div className="row mb-4">
                <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                  Gateway merchant key
                </label>
                <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <input
                    {...register("gateway_merchant_key", {
                      required: "Merchant key is required.",
                    })}
                    aria-invalid={
                      errors.gateway_merchant_key ? "true" : "false"
                    }
                    className="form-control form-control-lg"
                    type="text"
                    placeholder="Enter merchant key"
                    disabled={isView}
                  />
                  {errors.gateway_merchant_key && (
                    <p role="alert" className="text-danger">
                      {errors.gateway_merchant_key?.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                  Industry Type
                </label>
                <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <input
                    {...register("industry_type", {
                      required: "Industry type is required.",
                    })}
                    aria-invalid={errors.industry_type ? "true" : "false"}
                    className="form-control form-control-lg"
                    type="text"
                    placeholder="Enter industry type"
                    disabled={isView}
                  />
                  {errors.industry_type && (
                    <p role="alert" className="text-danger">
                      {errors.industry_type?.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                  Channel id for website
                </label>
                <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <input
                    {...register("channel_id_for_website", {
                      required: "Channel id is required.",
                    })}
                    aria-invalid={
                      errors.channel_id_for_website ? "true" : "false"
                    }
                    className="form-control form-control-lg"
                    type="text"
                    placeholder="Enter channel id"
                    disabled={isView}
                  />
                  {errors.channel_id_for_website && (
                    <p role="alert" className="text-danger">
                      {errors.channel_id_for_website?.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                  Channel id for mobile
                </label>
                <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <input
                    {...register("channel_id_for_mobile", {
                      required: "Channel id is required.",
                    })}
                    aria-invalid={
                      errors.channel_id_for_mobile ? "true" : "false"
                    }
                    className="form-control form-control-lg"
                    type="text"
                    placeholder="Enter channel id"
                    disabled={isView}
                  />
                  {errors.channel_id_for_mobile && (
                    <p role="alert" className="text-danger">
                      {errors.channel_id_for_mobile?.message}
                    </p>
                  )}
                </div>
              </div>

              {!isView && (
                <div className="row justify-content-end">
                  <div className="col-12 col-md-12">
                    <div className="text-start">
                      <button className="btn btn-primary btn-lg common_hover">
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
