import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as API from "../../utils/api.service";
import { ADMIN } from "../../app.config";
import settingsIcon from "../../assets/images/site_icons/settings_h_svg.svg";
// import appIcon from "../../assets/images/site_icons/app_h_svg.svg";
import chartIcon from "../../assets/images/site_icons/chart_h_svg.svg";
import groupChatIcon from "../../assets/images/site_icons/Group-chat_h_svg.svg";
import userIcon from "../../assets/images/site_icons/w_svg_m.svg";
import { Breadcrumb } from "../../components/common";
import { setUserDetails } from "../../store/slices/subAdminSlice";
import { TOAST_ERROR } from "../../utils/common.service";

const Navbar = ({ header, subHeader }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userDetails: { data: { user_type = "" } = {} } = {} } = useSelector((state) => state.subAdmin);
  const userData = useSelector((state) => state.subAdmin);
  let admindata = userData.userDetails.data;
  
  const handleLogout = async () => {
    try {
      await API.logout();
      localStorage.removeItem(ADMIN);
      dispatch(setUserDetails({}));
      navigate("/login", { state: user_type === "admin" || user_type === "sub_admin" ? "" : "organizer" });
    } catch (err) {
      TOAST_ERROR(err.message);
    }
  };

  return (
    <div className="header">
      {/* BEGIN Desktop Sticky Header */}
      <div className="sticky-header" id="sticky-header-desktop">
        {/* BEGIN Header Holder */}
        <div className="header-holder header-holder-desktop">
          <div className="header-container container-fluid g-5">
            <div className="header-wrap header-wrap-block justify-content-start">
              <h4 className="header-title">{header}</h4>
              <i className="header-divider"></i>
            </div>
            <div className="header-wrap hstack gap-4">
              {/* BEGIN Dropdown */}
              <div className="dropdown">
                <Link to="/app-settings" className="menu-item-link">
                  <img src={settingsIcon} alt="settings_h_svg" />
                  <div className="btn-marker">
                    <i className="marker marker-dot text-primary"></i>
                  </div>
                </Link>
                {/* <button className="btn btn-flat-primary btn-icon war_custom_bg" data-bs-toggle="dropdown">
                  <img src={settingsIcon} alt="settings_h_svg" />
                  <div className="btn-marker">
                    <i className="marker marker-dot text-primary"></i>
                  </div>
                </button> */}
              </div>
              {/* END Dropdown */}

              {/* BEGIN Dropdown */}
              {/* <div className="dropdown">
                <button className="btn btn-flat-primary btn-icon war_custom_bg" data-bs-toggle="dropdown">
                  <img src={appIcon} alt="app_h_svg" />
                  <div className="btn-marker">
                    <i className="marker marker-dot text-primary"></i>
                  </div>
                </button>
              </div> */}
              {/* END Dropdown */}

              {/* <button
                className="btn btn-flat-primary btn-icon war_custom_bg"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvas-settings"
              >
                <img src={chartIcon} alt="chart_h_svg" />
              </button> */}
              <Link to="/" className="menu-item-link">
                <img src={chartIcon} alt="chart_h_svg" />
                <div className="btn-marker">
                  <i className="marker marker-dot text-primary"></i>
                </div>
              </Link>
              {/* <button
                className="btn btn-flat-primary btn-icon war_custom_bg"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvas-todo"
              >
                <img src={groupChatIcon} alt="Group-chat_h_svg" />
              </button> */}

              <Link to="/groups/chat-groups" className="menu-item-link">
                <img src={groupChatIcon} alt="Group-chat_h_svg" />
                <div className="btn-marker">
                  <i className="marker marker-dot text-primary"></i>
                </div>
              </Link>

              {/* BEGIN Dropdown */}
              <div className="dropdown custom-dd">
                <button className="btn btn-flat-primary widget13 war_custom_bg" data-bs-toggle="dropdown">
                  {/* <!-- BEGIN Avatar --> */}
                  <div className="avatar avatar-info widget13-avatar">
                    <div className="avatar-display">
                      <i className="fa fa-user-alt"></i>
                    </div>
                  </div>
                  {/* <!-- END Avatar --> */}
                </button>
                <div className="dropdown-menu" data-popper-placement="bottom-start">
                  <Link className="dropdown-item" to="/admin-edit-profile">
                    <i className="fa fa-user" aria-hidden="true"></i>
                    Edit Profile
                  </Link>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a role="button" className="dropdown-item" onClick={handleLogout}>
                    <i className="fa fa-sign-out" aria-hidden="true"></i> 
                    Logout
                  </a>
                </div>
              </div>
              {/* END Dropdown */}
            </div>
          </div>
        </div>
        {/* END Header Holder */}
      </div>
      {/* END Desktop Sticky Header */}

      {/* BEGIN Header Holder */}
      <Breadcrumb />
      {/* <div className="header-holder header-holder-desktop custom_holder_bg">
        <div className="header-container container-fluid g-5">
          <div className="header-wrap header-wrap-block justify-content-start">
            <div className="breadcrumb breadcrumb-transparent mb-0">
              <Link to="/" className="breadcrumb-item disable_text">
                <span className="breadcrumb-text">Dashboard</span>
              </Link>
              <Link to="/" className="breadcrumb-item">
                <span className="breadcrumb-text">{subHeader}</span>
              </Link>
            </div>
          </div>
        </div>
      </div> */}
      {/* END Header Holder */}

      {/* BEGIN Mobile Sticky Header */}
      <div className="sticky-header" id="sticky-header-mobile">
        <div className="header-holder header-holder-mobile">
          <div className="header-container container-fluid g-5">
            <div className="header-wrap">
              <button className="btn btn-flat-primary btn-icon" id="minimize-maximize-btn-mobile" data-toggle="aside">
                <i className="fa fa-bars"></i>
              </button>
            </div>
            <div className="header-wrap header-wrap-block justify-content-start px-3">
              <h4 className="header-brand">
                <img src={userIcon} alt="w_svg_m" />
                War94
              </h4>
            </div>
            <div className="header-wrap hstack gap-2">
              <div className="dropdown">
                <button className="btn btn-flat-primary widget13" data-bs-toggle="dropdown">
                  <div className="avatar avatar-info widget13-avatar">
                    <div className="avatar-display">
                      <i className="fa fa-user-alt"></i>
                    </div>
                  </div>
                </button>
                <div className="dropdown-menu dropdown-menu-wide dropdown-menu-end dropdown-menu-animated overflow-hidden py-0">
                  <div className="portlet border-0">
                    <div className="portlet-header bg-primary rounded-0">
                      <div className="rich-list-item w-100 p-0 d-flex flex-row justify-content-center">
                        <div className="rich-list-prepend">
                          <div className="avatar avatar-label-light avatar-circle">
                            <div className="avatar-display">
                              <img src={admindata.profile_image} alt="profile_image" />
                            </div>
                          </div>
                        </div>
                        <div className="rich-list-content ml-2">
                          <h3 className="rich-list-title text-white">{admindata.name}</h3>
                          <span className="rich-list-subtitle text-white">{admindata.email}</span>
                        </div>
                        {/* <div className="rich-list-append">
                          <span className="badge badge-label-light fs-6">9+</span>
                        </div> */}
                      </div>
                    </div>
                    <div className="portlet-body p-0">
                      <div className="grid-nav grid-nav-flush grid-nav-action grid-nav-no-rounded">
                        <div className="grid-nav-row">
                          <Link to="/" className="grid-nav-item">
                            <div className="grid-nav-icon">
                              <i className="fa-solid fa-chart-simple"></i>
                            </div>
                            <span className="grid-nav-content">Dashboard</span>
                          </Link>
                          <Link to="/admin-edit-profile" className="grid-nav-item">
                            <div className="grid-nav-icon">
                              <i className="far fa-address-card"></i>
                            </div>
                            <span className="grid-nav-content">Profile</span>
                          </Link>
                          <a href="/groups/chat-groups" className="grid-nav-item">
                            <div className="grid-nav-icon">
                              <i className="far fa-comments"></i>
                            </div>
                            <span className="grid-nav-content">Chat</span>
                          </a>
                        </div>
                        <div className="grid-nav-row">
                          <Link to="/app-settings" className="grid-nav-item">
                            <div className="grid-nav-icon">
                              <i className="fa-solid fa-gear"></i>
                            </div>
                            <span className="grid-nav-content">App Settings</span>
                          </Link>
                          <Link to="/push-notifications" className="grid-nav-item">
                            <div className="grid-nav-icon">
                              <i className="far fa-bell"></i>
                            </div>
                            <span className="grid-nav-content">Notification</span>
                          </Link>
                          
                        </div>
                      </div>
                    </div>
                    <div className="portlet-footer portlet-footer-bordered rounded-0">
                      <button onClick={handleLogout} className="btn btn-label-danger">
                        Sign out
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* END Mobile Sticky Header */}

      {/* BEGIN Header Holder */}
      <div className="header-holder header-holder-mobile">
        <div className="header-container container-fluid g-5">
          <div className="header-wrap header-wrap-block justify-content-start w-100">
            {/* BEGIN Breadcrumb */}
            <div className="breadcrumb breadcrumb-transparent mb-0">
              <a href="/" className="breadcrumb-item">
                <div className="breadcrumb-icon">
                  <i data-feather="home"></i>
                </div>
                <span className="breadcrumb-text">Events</span>
              </a>
            </div>
            {/* END Breadcrumb */}
          </div>
        </div>
      </div>
      {/* END Header Holder */}
    </div>
  );
};

export default Navbar;
