import React from "react";

export default function AppSettingsHeader({ step, onStepChange, appSettings }) {
  const handleLinkClick = (newStep) => {
    onStepChange(newStep);
  };
  return (
    <div className="row">
      <div className="col-12">
        <div className="portlet">
          <div className="horizontal_filter_card">
            <ul className="nav custom_scroller justify-content-start">
              <li className="nav-item">
                <a
                  className={`nav-link ${step === 1 ? "active" : ""}`}
                  onClick={() => handleLinkClick(1)}
                  role="button"
                >
                  Welcome
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${step === 23 ? "active" : ""}`}
                  onClick={() => handleLinkClick(23)}
                  role="button"
                >
                  General
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${step === 2 ? "active" : ""}`}
                  onClick={() => handleLinkClick(2)}
                  role="button"
                >
                  World Chat
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${step === 3 ? "active" : ""}`}
                  onClick={() => handleLinkClick(3)}
                  role="button"
                >
                  Group Chat
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${step === 26 ? "active" : ""}`}
                  onClick={() => handleLinkClick(26)}
                  role="button"
                >
                  Report Limit
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${step === 4 ? "active" : ""}`}
                  onClick={() => handleLinkClick(4)}
                  role="button"
                >
                  Add/Withdraw
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${step === 5 ? "active" : ""}`}
                  onClick={() => handleLinkClick(5)}
                  role="button"
                >
                  Email Config
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${step === 6 ? "active" : ""}`}
                  onClick={() => handleLinkClick(6)}
                  role="button"
                >
                  Payments Gateway
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${step === 7 ? "active" : ""}`}
                  onClick={() => handleLinkClick(7)}
                  role="button"
                >
                  Payout Gateway
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${step === 8 ? "active" : ""}`}
                  onClick={() => handleLinkClick(8)}
                  role="button"
                >
                  App Update
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${step === 9 ? "active" : ""}`}
                  onClick={() => handleLinkClick(9)}
                  role="button"
                >
                  Pro Conditions
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${step === 10 ? "active" : ""}`}
                  onClick={() => handleLinkClick(10)}
                  role="button"
                >
                  TDS & GST
                </a>
              </li>
              {/* <li className="nav-item">
                <a className={`nav-link ${step === 11 ? "active" : ""}`} onClick={() => handleLinkClick(11)}>
                  KYC
                </a>
              </li>
              <li className="nav-item">
                <a className={`nav-link ${step === 12 ? "active" : ""}`} onClick={() => handleLinkClick(12)}>
                  Gold Membership
                </a>
              </li> */}
              <li className="nav-item">
                <a
                  className={`nav-link ${step === 13 ? "active" : ""}`}
                  onClick={() => handleLinkClick(13)}
                  role="button"
                >
                  SMS Gateway
                </a>
              </li>
              {/* <li className="nav-item">
                <a className={`nav-link ${step === 14 ? "active" : ""}`} onClick={() => handleLinkClick(14)}>
                  Push Notification
                </a>
              </li> */}
              <li className="nav-item">
                <a
                  className={`nav-link ${step === 15 ? "active" : ""}`}
                  onClick={() => handleLinkClick(15)}
                  role="button"
                >
                  Location
                </a>
              </li>
              {/* <li className="nav-item">
                <a className={`nav-link ${step === 16 ? "active" : ""}`} onClick={() => handleLinkClick(16)}>
                  Spotlight
                </a>
              </li> */}
              <li className="nav-item">
                <a
                  className={`nav-link ${step === 17 ? "active" : ""}`}
                  onClick={() => handleLinkClick(17)}
                  role="button"
                >
                  Referral
                </a>
              </li>

              <li className="nav-item">
                <a
                  className={`nav-link ${step === 18 ? "active" : ""}`}
                  onClick={() => handleLinkClick(18)}
                  role="button"
                >
                  Blacklist Words
                </a>
              </li>

              <li className="nav-item">
                <a
                  className={`nav-link ${step === 19 ? "active" : ""}`}
                  onClick={() => handleLinkClick(19)}
                  role="button"
                >
                  Blacklist APK
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${step === 20 ? "active" : ""}`}
                  onClick={() => handleLinkClick(20)}
                  role="button"
                >
                  Ads Settings
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${step === 21 ? "active" : ""}`}
                  onClick={() => handleLinkClick(21)}
                  role="button"
                >
                  Colors
                </a>
              </li>
              {appSettings.country === 99 && (
                <li className="nav-item">
                  <a
                    className={`nav-link ${step === 24 ? "active" : ""}`}
                    onClick={() => handleLinkClick(24)}
                  >
                    Blocked Image Labels
                  </a>
                </li>
              )}
              <li className="nav-item">
                <a
                  className={`nav-link ${step === 22 ? "active" : ""}`}
                  onClick={() => handleLinkClick(22)}
                  role="button"
                >
                  Email Purpose
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${step === 25 ? "active" : ""}`}
                  onClick={() => handleLinkClick(25)}
                  role="button"
                >
                  Support Chat Bot
                </a>
              </li>
              {/* <li className="nav-item">
                <a className={`nav-link ${step === 21 ? "active" : ""}`} onClick={() => handleLinkClick(21)}>
                  Business Settings
                </a>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
