import warningIcon from "../../../assets/images/site_icons/waring_icg.svg";

const WarningCard = ({ message }) => {
  return (
    <div className="row">
      <div className="col-12 col-md-12 col-lg-12">
        <div className="bg_warning_referrel mt-4">
          <div className="payment_dv_info">
            <img src={warningIcon} alt="image" />
            <div className="company_info_c">
              <span>Warning!</span>
              <span>By editing {message}, user might notified the changes</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WarningCard;
