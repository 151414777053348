import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "../../utils/api.service";
import { setLoader } from "./masterSlice";

export const getGroupPermissionList = createAsyncThunk(
  "groupPermissionList",
  async ({ page, filters }, { dispatch }) => {
    try {
      dispatch(setLoader(true));
      let { data: groupPermissionList } = await API.getGroupPermissionList(page, filters);
      groupPermissionList = {
        ...groupPermissionList,
        results: [
          ...groupPermissionList?.results.map((item) => ({
            ...item,
            value: item.id,
            label: item.name,
          })),
        ],
      };
      dispatch(setLoader(false));
      return groupPermissionList;
    } catch (error) {
      dispatch(setLoader(false));
      throw error;
    }
  }
);

export const getGroupMenuList = createAsyncThunk("groupMenuList", async (_, { dispatch }) => {
  try {
    dispatch(setLoader(true));
    let { data: groupMenuList } = await API.getGroupMenuList();
    groupMenuList = groupMenuList.map((item) => ({
      ...item,
      value: item.id,
      label: item.name,
    }));
    // groupMenuList = {
    //   ...groupMenuList,
    //   results: [
    //     ...groupMenuList.map((item) => ({
    //       ...item,
    //       value: item.id,
    //       label: item.name,
    //     })),
    //   ],
    // };
    dispatch(setLoader(false));
    return groupMenuList;
  } catch (error) {
    dispatch(setLoader(false));
    throw error;
  }
});

export const getChatGroupList = createAsyncThunk("chatGroupList", async ({ page, filters }, { dispatch }) => {
  try {
    dispatch(setLoader(true));
    let { data: chatGroupList } = await API.getChatGroupList(page, filters);
    dispatch(setLoader(false));
    return chatGroupList;
  } catch (error) {
    dispatch(setLoader(false));
    throw error;
  }
});

const initialState = {
  groupPermissionList: {
    data: {},
    error: null,
  },
  groupMenuList: {
    data: [],
    error: null,
  },
  chatGroupList: {
    data: {},
    error: null,
  },
};

const groupSlice = createSlice({
  name: "GROUP",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getGroupPermissionList.fulfilled, (state, action) => {
        state.groupPermissionList.data = action.payload;
      })
      .addCase(getGroupPermissionList.rejected, (state, action) => {
        state.groupPermissionList.error = action.error.message;
      })
      .addCase(getGroupMenuList.fulfilled, (state, action) => {
        state.groupMenuList.data = action.payload;
      })
      .addCase(getGroupMenuList.rejected, (state, action) => {
        state.groupMenuList.error = action.error.message;
      })
      .addCase(getChatGroupList.fulfilled, (state, action) => {
        state.chatGroupList.data = action.payload;
      })
      .addCase(getChatGroupList.rejected, (state, action) => {
        state.chatGroupList.error = action.error.message;
      });
  },
});

export default groupSlice.reducer;
