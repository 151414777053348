import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "../../utils/api.service";
import { setLoader } from "./masterSlice";

export const getOrganizerList = createAsyncThunk("organizerList", async ({ page, filters }, { dispatch }) => {
  try {
    dispatch(setLoader(true));
    const { data: organizerList } = await API.getOrganizerList(page, filters);
    dispatch(setLoader(false));
    return organizerList;
  } catch (error) {
    dispatch(setLoader(false));
    throw error;
  }
});

// export const getOrganizerDetails = createAsyncThunk("organizerDetails", async (id, { dispatch }) => {
//   try {
//     dispatch(setLoader(true));
//     let { data: organizerDetails } = await API.getOrganizerDetails(id);
//     let tempObj = {};
//     for (let parent of organizerDetails?.permissions_groups) {
//       for (let child of parent.menus_permission || parent.permission) {
//         tempObj[child.name] = true;
//       }
//     }
//     organizerDetails.permissions = tempObj;
//     dispatch(setLoader(false));
//     return organizerDetails;
//   } catch (error) {
//     dispatch(setLoader(false));
//     throw error;
//   }
// });

const initialState = {
  organizerList: {
    data: {},
    error: null,
  },
  // organizerDetails: {
  //   data: {},
  //   error: null,
  // },
};

const organizerSlice = createSlice({
  name: "ORGANIZER",
  initialState,
  reducers: {
    // setOrganizerDetails: (state, action) => {
    //   state.organizerDetails.data = action.payload;
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrganizerList.fulfilled, (state, action) => {
        state.organizerList.data = action.payload;
      })
      .addCase(getOrganizerList.rejected, (state, action) => {
        state.organizerList.error = action.error.message;
      });
    // .addCase(getOrganizerDetails.fulfilled, (state, action) => {
    //   state.organizerDetails.data = action.payload;
    // })
    // .addCase(getOrganizerDetails.rejected, (state, action) => {
    //   state.organizerDetails.error = action.error.message;
    // });
  },
});

export const { setOrganizerDetails } = organizerSlice.actions;
export default organizerSlice.reducer;
