import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "../../utils/api.service";
import { setLoader } from "./masterSlice";

export const getPostList = createAsyncThunk("postList", async ({ page, filtersQuery }, { dispatch }) => {
  try {
    dispatch(setLoader(true));
    const { data: postList } = await API.getPostList(page, filtersQuery);
    dispatch(setLoader(false));
    return postList;
  } catch (error) {
    dispatch(setLoader(false));
    throw error;
  }
});

export const getPostReportList = createAsyncThunk("postReportList", async ({ page, filters }, { dispatch }) => {
  try {
    dispatch(setLoader(true));
    const { data: postList } = await API.getPostReportList(page, filters);
    dispatch(setLoader(false));
    return postList;
  } catch (error) {
    dispatch(setLoader(false));
    throw error;
  }
});

const initialState = {
  postList: {
    data: {},
    error: null,
  },
  postReportList: {
    data: {},
    error: null,
  },
};

const postSlice = createSlice({
  name: "POST",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPostList.fulfilled, (state, action) => {
        state.postList.data = action.payload;
      })
      .addCase(getPostList.rejected, (state, action) => {
        state.postList.error = action.error.message;
      })
      .addCase(getPostReportList.fulfilled, (state, action) => {
        state.postReportList.data = action.payload;
      })
      .addCase(getPostReportList.rejected, (state, action) => {
        state.postReportList.error = action.error.message;
      });
  },
});

export default postSlice.reducer;
