import { Suspense, lazy, useState, useEffect } from "react";
// Routing
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { DashboardLayout } from "../layout/DashboardLayout";
import { capitalizeWords } from "../utils/common.service";
import { ADMIN } from "../app.config";
// import { Spinner } from "../components/common";
import GoldSubsciptionsPage from "../pages/GoldSubscriptions";
import GoldUsersPage from "../pages/GoldSubscriptions/GoldUsers";
import AddCountryWiseSettingsPage from "../pages/AppSettings/AddCountryWiseSettings";
import AddSubscriptionPage from "../pages/GoldSubscriptions/AddSubscription";
import AddCountryPage from "../pages/CountryList/AddCountry";

export const Loadable = (Component) => (props) => {
  return (
    <Suspense /* fallback={<Spinner />} */>
      <Component {...props} />
    </Suspense>
  );
};

const Dashboard = Loadable(lazy(() => import("../pages/Dashboard")));
const AdminEditProfile = Loadable(lazy(() => import("../pages/AdminEditProfile")));
const Banners = Loadable(lazy(() => import("../pages/Banners")));
const AddBanner = Loadable(lazy(() => import("../pages/Banners/AddBanner")));
const Events = Loadable(lazy(() => import("../pages/Events")));
const AddEvent = Loadable(lazy(() => import("../pages/Events/AddEvent")));
const ProductCategory = Loadable(lazy(() => import("../pages/ProductCategory")));
const Products = Loadable(lazy(() => import("../pages/Products")));
const AddProduct = Loadable(lazy(() => import("../pages/Products/AddProduct")));
const Orders = Loadable(lazy(() => import("../pages/Orders")));
const Advertisements = Loadable(lazy(() => import("../pages/Advertisements")));
const AddAdvertisement = Loadable(lazy(() => import("../pages/Advertisements/AddAdvertisement")));
const Games = Loadable(lazy(() => import("../pages/Games")));
const AddGame = Loadable(lazy(() => import("../pages/Games/AddGames")));
const Tournaments = Loadable(lazy(() => import("../pages/Tournaments")));
const AddTournament = Loadable(lazy(() => import("../pages/Tournaments/AddTournament")));
const TournamentRooms = Loadable(lazy(() => import("../pages/TournamentRooms")));
const AllMatches = Loadable(lazy(() => import("../pages/TournamentRooms/AllMatches")));
const MatchDetailed = Loadable(lazy(() => import("../pages/TournamentRooms/MatchDetailed")));
const RoomDetailed = Loadable(lazy(() => import("../pages/TournamentRooms/RoomDetailed")));
const Report = Loadable(lazy(() => import("../pages/Report")));
const ReportDetails = Loadable(lazy(() => import("../pages/Report/ReportUserDetails")));
const ChatReport = Loadable(lazy(() => import("../pages/ChatReport")));
const ChatReportDetails = Loadable(lazy(() => import("../pages/ChatReport/ChatReportDetails")));
const Payments = Loadable(lazy(() => import("../pages/Payment")));
const PaymentsDetails = Loadable(lazy(() => import("../pages/Payment/PaymentDetails")));
const VerificationBadge = Loadable(lazy(() => import("../pages/VerificationBadge")));
const ProVerificationList = Loadable(lazy(() => import("../pages/ProVerificationList")));
const UserVerification = Loadable(lazy(() => import("../pages/ProVerificationList/ProVerificationDetails")));
const Users = Loadable(lazy(() => import("../pages/Users")));
const UsersDetails = Loadable(lazy(() => import("../pages/Users/EditUserDetails")));
const AddUser = Loadable(lazy(() => import("../pages/Users/AddUser")));
const PlayedUserDetails = Loadable(lazy(() => import("../pages/Users/PlayedUserDetails")));
const SubAdmin = Loadable(lazy(() => import("../pages/SubAdmin")));
const AddSubAdmin = Loadable(lazy(() => import("../pages/SubAdmin/AddSubAdmin")));
const Reason = Loadable(lazy(() => import("../pages/Reason")));
const AddReason = Loadable(lazy(() => import("../pages/Reason/AddReason")));
const BlockedDevices = Loadable(lazy(() => import("../pages/BlockedDevices")));
const EditBlockedDevices = Loadable(lazy(() => import("../pages/BlockedDevices/EditBlockedDevices")));
const ContactUs = Loadable(lazy(() => import("../pages/ContactUs")));
const ContactUsDetails = Loadable(lazy(() => import("../pages/ContactUs/ContactUsDetails")));
const EditContactUs = Loadable(lazy(() => import("../pages/ContactUs/EditContactUs")));
const WithdrawMoney = Loadable(lazy(() => import("../pages/Withdraw")));
const SpotlightedMessage = Loadable(lazy(() => import("../pages/SpotlightedMessage")));
const SpotlightPrice = Loadable(lazy(() => import("../pages/SpotlightedMessage/SpotlightPrice")));
const PrivateOrgsDispute = Loadable(lazy(() => import("../pages/PrivateOrgsDispute")));
const PrivateOrgsDisputeDetails = Loadable(lazy(() => import("../pages/PrivateOrgsDispute/DisputeDetails")));
const PrivateOrganizers = Loadable(lazy(() => import("../pages/PrivateOrganizers")));
const AddOrganizer = Loadable(lazy(() => import("../pages/PrivateOrganizers/AddOrganizer")));
const PrivateOrganizersDetails = Loadable(lazy(() => import("../pages/PrivateOrganizers/PrivateOrganizersDetails")));
const AppSettings = Loadable(lazy(() => import("../pages/AppSettings")));
const CountryList = Loadable(lazy(() => import("../pages/CountryList")));
const Stories = Loadable(lazy(() => import("../pages/Stories")));
const AddStory = Loadable(lazy(() => import("../pages/Stories/AddStory")));
const EditStory = Loadable(lazy(() => import("../pages/Stories/EditStory")));
const CustomersDispute = Loadable(lazy(() => import("../pages/CustomersDispute")));
const CustomersDisputeDetails = Loadable(lazy(() => import("../pages/CustomersDispute/DisputeDetails")));
const EmailMarketing = Loadable(lazy(() => import("../pages/EmailMarketing")));
const AddEmailMarketing = Loadable(lazy(() => import("../pages/EmailMarketing/AddEmailMarketing")));
const PushNotification = Loadable(lazy(() => import("../pages/PushNotification")));
const AddPushNotification = Loadable(lazy(() => import("../pages/PushNotification/AddPushNotification")));
const Post = Loadable(lazy(() => import("../pages/Post")));
const AddPost = Loadable(lazy(() => import("../pages/Post/AddPost")));
const LiveSupport = Loadable(lazy(() => import("../pages/LiveSupport")));
const LiveSupportDetails = Loadable(lazy(() => import("../pages/LiveSupport/LiveSupportDetails")));
const TicketSupport = Loadable(lazy(() => import("../pages/TicketSupport")));
const PaypalList = Loadable(lazy(() => import("../pages/PaypalList")));
const PaypalDetails = Loadable(lazy(() => import("../pages/PaypalList/PaypalDetails")));
const StaticContents = Loadable(lazy(() => import("../pages/StaticContents")));
const StaticDetails = Loadable(lazy(() => import("../pages/StaticContents/StaticDetails")));
const PermissionGroups = Loadable(lazy(() => import("../pages/Groups/PermissionGroups")));
const AddGroup = Loadable(lazy(() => import("../pages/Groups/PermissionGroups/AddGroup")));
const ChatGroups = Loadable(lazy(() => import("../pages/Groups/ChatGroups")));
const ChatGroupDetails = Loadable(lazy(() => import("../pages/Groups/ChatGroups/GroupDetails")));
const WorldGroups = Loadable(lazy(() => import("../pages/Groups/WorldGroups")));
const P2PChat = Loadable(lazy(() => import("../pages/P2PChat")));
const Login = Loadable(lazy(() => import("../pages/Authorization/Login")));
const ForgotPassword = Loadable(lazy(() => import("../pages/Authorization/ForgotPassword")));
const OtpVerification = Loadable(lazy(() => import("../pages/Authorization/OtpVerification")));
const ResetPassword = Loadable(lazy(() => import("../pages/Authorization/ResetPassword")));
const UserKyc = Loadable(lazy(() => import("../pages/UserKyc")));
const BusinessReport = Loadable(lazy(() => import("../pages/BusinessReport")));
const AddMoney = Loadable(lazy(() => import("../pages/AddMoney")));
const CashToWinning = Loadable(lazy(() => import("../pages/CashToWinning")));

const Router = () => {
  const [subHeader, setSubHeader] = useState("");
  const [header, setHeader] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = location.pathname.split("/");

  useEffect(() => {
    if (currentPath[1]) setHeader(capitalizeWords(currentPath[1]));
    else setHeader("Dashboard");
    setSubHeader(capitalizeWords(currentPath[2] ? currentPath[2] : currentPath[1]));
  }, [currentPath]);

  const ADMIN_DETAILS = JSON.parse(localStorage.getItem(ADMIN));
  useEffect(() => {
    ADMIN_DETAILS?.user_type && (currentPath[1] === "login" || currentPath[2] === "login") && navigate("/");
  }, [ADMIN_DETAILS?.user_type, navigate, currentPath]);

  return (
    <Routes>
      <Route element={<DashboardLayout header={header} subHeader={subHeader} />}>
        <Route path="/" element={<Dashboard />} />
        <Route path="/business-reports" element={<BusinessReport />} />
        <Route path="/admin-edit-profile" element={<AdminEditProfile />} />
        <Route path="/banners" element={<Banners />} />
        <Route path="/banners/add-banner" element={<AddBanner />} />
        <Route path="/banners/edit-banner/:id" element={<AddBanner />} />
        <Route path="/banners/view-banner/:id" element={<AddBanner />} />

        <Route path="/events" element={<Events />} />
        <Route path="/events/add-event" element={<AddEvent />} />
        <Route path="/events/edit-event/:id" element={<AddEvent />} />
        <Route path="/events/view-event/:id" element={<AddEvent />} />

        <Route path="/product-category" element={<ProductCategory />} />
        <Route path="/products" element={<Products />} />
        <Route path="/products/add-product" element={<AddProduct />} />
        <Route path="/products/edit-product/:id" element={<AddProduct />} />
        <Route path="/products/view-product/:id" element={<AddProduct />} />

        <Route path="/orders" element={<Orders />} />

        <Route path="/advertisements" element={<Advertisements />} />
        <Route path="/advertisements/add-advertisement" element={<AddAdvertisement />} />
        <Route path="/advertisements/edit-advertisement/:id" element={<AddAdvertisement />} />
        <Route path="/advertisements/view-advertisement/:id" element={<AddAdvertisement />} />

        <Route path="/games" element={<Games />} />
        <Route path="/games/add-game" element={<AddGame />} />
        <Route path="/games/edit-game/:id" element={<AddGame />} />
        <Route path="/games/view-game/:id" element={<AddGame />} />

        <Route path="/tournaments" element={<Tournaments />} />
        <Route path="/tournaments/add-tournament/*" element={<AddTournament />} />
        <Route path="/tournaments/edit-tournament/:id/*" element={<AddTournament />} />
        <Route path="/tournaments/view-tournament/:id/*" element={<AddTournament />} />
        <Route path="/tournament-rooms" element={<TournamentRooms />} />
        <Route path="/tournament-rooms/all-matches/:id" element={<AllMatches />} />
        <Route path="/tournament-rooms/all-matches/:id/match-detailed/:id" element={<MatchDetailed />} />
        <Route path="/tournament-rooms/match-detailed/edit/:id/*" element={<RoomDetailed />} />

        <Route path="/report-users/:id" element={<Report />} />

        <Route path="/report-users" element={<Report />} />
        <Route path="/report-users/report-details/:id" element={<ReportDetails />} />
        <Route path="/report-post" element={<Report />} />
        <Route path="/report-post/report-details/:id" element={<ReportDetails />} />
        <Route path="/report-chat" element={<ChatReport />} />
        <Route path="/report-chat/report-details/:id" element={<ChatReportDetails />} />

        <Route path="/payments" element={<Payments />} />
        <Route path="/payments/payment-details/:id" element={<PaymentsDetails />} />

        <Route path="/add-money" element={<AddMoney />} />

        <Route path="/verification-badge" element={<VerificationBadge />} />
        <Route path="/pro-verification-requests" element={<ProVerificationList />} />
        <Route path="/user-verification/:id" element={<UserVerification />} />

        <Route path="/users" element={<Users />} />
        <Route path="/users/edit-user-details/:id" element={<UsersDetails />} />
        <Route path="/users/view-user-details/:id" element={<UsersDetails />} />
        <Route path="/users/view-played-user-details/:id" element={<PlayedUserDetails />} />
        <Route path="/users/add-user" element={<AddUser />} />

        <Route path="/sub-admin" element={<SubAdmin />} />
        <Route path="/sub-admin/add-sub-admin" element={<AddSubAdmin />} />
        <Route path="/sub-admin/edit-sub-admin/:id" element={<AddSubAdmin />} />
        <Route path="/sub-admin/view-sub-admin/:id" element={<AddSubAdmin />} />

        <Route path="/reason-lists" element={<Reason />} />
        <Route path="/reason-lists/add-reason" element={<AddReason />} />
        <Route path="/reason-lists/edit-reason/:id" element={<AddReason />} />
        <Route path="/reason-lists/view-reason/:id" element={<AddReason />} />

        <Route path="/blocked-devices" element={<BlockedDevices />} />
        <Route path="/blocked-devices/edit/:id" element={<EditBlockedDevices />} />
        <Route path="/blocked-ips" element={<BlockedDevices />} />
        <Route path="/blocked-ips/edit/:id" element={<EditBlockedDevices />} />
        <Route path="/cash-to-winning" element={<CashToWinning />} />

        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/contact-us/details/:id" element={<ContactUsDetails />} />
        <Route path="/contact-us/reply/:id" element={<EditContactUs />} />
        <Route path="/withdraw-money" element={<WithdrawMoney />} />
        <Route path="/spotlight-message" element={<SpotlightedMessage />} />
        <Route path="/spotlight-message/spotlight-price" element={<SpotlightPrice />} />

        <Route path="/private-orgs-dispute" element={<PrivateOrgsDispute />} />
        <Route path="/private-orgs-dispute/dispute-details/:id" element={<PrivateOrgsDisputeDetails />} />
        <Route path="/private-organizers" element={<PrivateOrganizers />} />
        <Route path="/private-organizers/add-organizer" element={<AddOrganizer />} />
        <Route path="/private-organizers/edit-organizer/:id" element={<AddOrganizer />} />
        <Route path="/private-organizers/view-organizer/:id" element={<AddOrganizer />} />
        <Route path="/private-organizers/private-organizers-details/:id" element={<PrivateOrganizersDetails />} />

        <Route path="/app-settings" element={<AppSettings />} />
        <Route path="/app-settings/add-settings" element={<AddCountryWiseSettingsPage />} />
        <Route path="/app-settings/edit-settings/:id" element={<AddCountryWiseSettingsPage />} />
        <Route path="/app-settings/view-settings/:id" element={<AddCountryWiseSettingsPage />} />

        <Route path="/country-list" element={<CountryList />} />
        <Route path="/country-list/add-country" element={<AddCountryPage />} />
        <Route path="/country-list/edit-country/:id" element={<AddCountryPage />} />
        <Route path="/country-list/view-country/:id" element={<AddCountryPage />} />

        <Route path="/stories" element={<Stories />} />
        <Route path="/stories/add-story" element={<AddStory />} />
        <Route path="/stories/edit-story/:id" element={<EditStory />} />
        <Route path="/stories/view-story/:id" element={<EditStory />} />

        <Route path="/customers-dispute" element={<CustomersDispute />} />
        <Route path="/customers-dispute/dispute-details/:id" element={<CustomersDisputeDetails />} />

        <Route path="/email-marketing" element={<EmailMarketing />} />
        <Route path="/email-marketing/add-email-marketing" element={<AddEmailMarketing />} />
        <Route path="/email-marketing/edit-email-marketing/:id" element={<AddEmailMarketing />} />

        <Route path="/live-chat-support" element={<LiveSupport />} />
        <Route path="/live-chat-support/live-chat-support-details/:id" element={<LiveSupportDetails />} />

        <Route path="/ticket-support" element={<TicketSupport />} />
        <Route path="/push-notifications" element={<PushNotification />} />
        <Route path="/push-notifications/add-push-notifications" element={<AddPushNotification />} />
        <Route path="/push-notifications/edit-push-notifications/:id" element={<AddPushNotification />} />

        <Route path="/post" element={<Post />} />
        <Route path="/post/add-post" element={<AddPost />} />
        <Route path="/post/edit-post/:id" element={<AddPost />} />
        <Route path="/post/view-post/:id" element={<AddPost />} />

        <Route path="/gold-subscriptions" element={<GoldSubsciptionsPage />} />
        <Route path="/gold-subscriptions/add-subscription" element={<AddSubscriptionPage />} />
        <Route path="/gold-subscriptions/edit-subscription/:id" element={<AddSubscriptionPage />} />
        <Route path="/gold-subscriptions/:id" element={<GoldUsersPage />} />

        <Route path="/paypal" element={<PaypalList />} />
        <Route path="/paypal/paypal-details/:id" element={<PaypalDetails />} />

        <Route path="/non-indian-withdraw" element={<PaypalList />} />
        <Route path="/non-indian-withdraw/details/:id" element={<PaypalDetails />} />

        <Route path="/static-contents" element={<StaticContents />} />
        <Route path="/static-contents/static-details/:id" element={<StaticDetails />} />

        <Route path="/groups/permission-groups" element={<PermissionGroups />} />
        <Route path="/groups/permission-groups/add-group" element={<AddGroup />} />
        <Route path="/groups/permission-groups/edit-group/:id" element={<AddGroup />} />
        <Route path="/groups/permission-groups/view-group/:id" element={<AddGroup />} />
        <Route path="/groups/chat-groups" element={<ChatGroups />} />
        <Route path="/groups/chat-groups/group-details/:id" element={<ChatGroupDetails />} />
        <Route path="/groups/world-groups" element={<WorldGroups />} />
        <Route path="/personal-chat" element={<P2PChat />} />
        <Route path="/groups" element={<P2PChat />} />
        <Route path="/kyc-verified-users" element={<UserKyc />} />
      </Route>

      {/* Authorization page */}
      <Route path="/login" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/otp-verification" element={<OtpVerification />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/organizer/login" element={<Login />} />
    </Routes>
  );
};

export default Router;
