import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "../../utils/api.service";
import { setLoader } from "./masterSlice";

export const getStoryList = createAsyncThunk(
  "storyList",
  async ({ page, filters }, { dispatch }) => {
    try {
      dispatch(setLoader(true));
      const { data: storyList } = await API.getStoryList(page, filters);
      dispatch(setLoader(false));
      return storyList;
    } catch (error) {
      dispatch(setLoader(false));
      throw error;
    }
  }
);

const initialState = {
  storyList: {
    data: [],
    error: null,
  },
};

const storySlice = createSlice({
  name: "STORY",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getStoryList.fulfilled, (state, action) => {
        state.storyList.data = action.payload;
      })
      .addCase(getStoryList.rejected, (state, action) => {
        state.storyList.error = action.error.message;
      });
  },
});

export default storySlice.reducer;
