import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";
import Footer from "../Footer";
import { getCountryList } from "../../store/slices/masterSlice";
import { getFilteredGameList, getGameList } from "../../store/slices/gameSlice";
import { ADMIN } from "../../app.config";
import { getReasonTypeList } from "../../store/slices/reasonSlice";
import { getUsersList } from "../../store/slices/userSlice";
import { getGroupPermissionList } from "../../store/slices/groupSlice";
import { getCategoryList } from "../../store/slices/productSlice";
import { getUserDetails, setUserDetails } from "../../store/slices/subAdminSlice";

export const DashboardLayout = ({ header, subHeader, children }) => {
  const dispatch = useDispatch();

  const ADMIN_DETAILS = JSON.parse(localStorage.getItem(ADMIN));
  useEffect(() => {
    if (ADMIN_DETAILS?.token) {
      dispatch(getCountryList());
      dispatch(getGameList({ page: 1 }));
      dispatch(getFilteredGameList({ page: 1, filters: "&is_active=1" }));
      dispatch(getReasonTypeList());
      dispatch(getUsersList({ page: 1 }));
      dispatch(getGroupPermissionList({ page: 1 }));
      dispatch(getCategoryList({ page: 1 }));
      dispatch(getUserDetails({ id: ADMIN_DETAILS?.id, user_type: ADMIN_DETAILS.user_type }));
    }
  }, [ADMIN_DETAILS?.token]);

  return ADMIN_DETAILS?.token ? (
    <div className="holder">
      <div className="aside">
        <Sidebar />
      </div>
      <div className="wrapper">
        <Navbar header={header} subHeader={subHeader} />
        {children}
        <Outlet />
        <Footer />
      </div>
    </div>
  ) : (
    <Navigate to="/login" />
  );
};
