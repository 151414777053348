import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "../../utils/api.service";
import { setLoader } from "./masterSlice";

export const getGameList = createAsyncThunk("gameList", async ({ page, filters }, { dispatch }) => {
  try {
    dispatch(setLoader(true));
    const { data: gameList } = await API.getGameList(page, filters);
    dispatch(setLoader(false));
    return gameList;
  } catch (error) {
    dispatch(setLoader(false));
    throw error;
  }
});

export const getFilteredGameList = createAsyncThunk("filteredGameList", async ({ page, filters }, { dispatch }) => {
  try {
    dispatch(setLoader(true));
    const { data: filteredGameList } = await API.getGameList(page, filters);
    dispatch(setLoader(false));
    return filteredGameList;
  } catch (error) {
    dispatch(setLoader(false));
    throw error;
  }
});

export const getTournamentGameList = createAsyncThunk("tournamentGameList", async ({ page, filters }, { dispatch }) => {
  try {
    dispatch(setLoader(true));
    const { data: tournamentGameList } = await API.getGameList(page, filters);
    dispatch(setLoader(false));
    return tournamentGameList;
  } catch (error) {
    dispatch(setLoader(false));
    throw error;
  }
});

export const getGameIdFormatList = createAsyncThunk("gameIdFormatList", async (_, { dispatch }) => {
  try {
    dispatch(setLoader(true));
    const { data: gameIdFormatList } = await API.getGameIdFormatList();
    dispatch(setLoader(false));
    return gameIdFormatList;
  } catch (error) {
    dispatch(setLoader(false));
    throw error;
  }
});

export const getGamePlatformList = createAsyncThunk("gamePlatformList", async (_, { dispatch }) => {
  try {
    dispatch(setLoader(true));
    const { data: gamePlatformList } = await API.getGamePlatformList();
    dispatch(setLoader(false));
    return gamePlatformList;
  } catch (error) {
    dispatch(setLoader(false));
    throw error;
  }
});

export const getTournamentTypeList = createAsyncThunk("tournamentTypeList", async (_, { dispatch }) => {
  try {
    dispatch(setLoader(true));
    const { data: tournamentTypeList } = await API.getTournamentTypeList();
    dispatch(setLoader(false));
    return tournamentTypeList;
  } catch (error) {
    dispatch(setLoader(false));
    throw error;
  }
});

export const getMapListByGameId = createAsyncThunk("mapListByGameId", async (id, { dispatch }) => {
  try {
    dispatch(setLoader(true));
    const { data: mapListByGameId } = await API.getMapListByGameId(id);
    dispatch(setLoader(false));
    return mapListByGameId;
  } catch (error) {
    dispatch(setLoader(false));
    throw error;
  }
});

export const getTournamentTypeByGameId = createAsyncThunk("tournamentTypeByGameId", async (id, { dispatch }) => {
  try {
    const { data: tournamentTypeByGameId } = await API.getTournamentTypeByGameId(id);
    return tournamentTypeByGameId;
  } catch (error) {
    throw error;
  }
});

const initialState = {
  gameList: {
    data: {},
    error: null,
  },
  filteredGameList: {
    data: {},
    error: null,
  },
  tournamentGameList: {
    data: {},
    error: null,
  },
  gameIdFormatList: {
    data: [],
    error: null,
  },
  gamePlatformList: {
    data: [],
    error: null,
  },
  tournamentTypeList: {
    data: [],
    error: null,
  },
  mapListByGameId: {
    data: [],
    error: null,
  },
  tournamentTypeByGameId: {
    data: [],
    error: null,
  },
};

const gameSlice = createSlice({
  name: "GAME",
  initialState,
  reducers: {
    setGameList: (state) => {
      state.gameList.data = {};
    },
    setFilteredGameList: (state) => {
      state.filteredGameList.data = {};
    },
    setMapListByGameId: (state) => {
      state.mapListByGameId.data = [];
    },
    setTournamentTypeBygameId: (state) => {
      state.tournamentTypeByGameId.data = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGameList.fulfilled, (state, action) => {
        state.gameList.data = action.payload;
      })
      .addCase(getGameList.rejected, (state, action) => {
        state.gameList.error = action.error.message;
      })
      .addCase(getFilteredGameList.fulfilled, (state, action) => {
        state.filteredGameList.data = action.payload;
      })
      .addCase(getFilteredGameList.rejected, (state, action) => {
        state.filteredGameList.error = action.error.message;
      })
      .addCase(getTournamentGameList.fulfilled, (state, action) => {
        state.tournamentGameList.data = action.payload;
      })
      .addCase(getTournamentGameList.rejected, (state, action) => {
        state.tournamentGameList.error = action.error.message;
      })
      .addCase(getGameIdFormatList.fulfilled, (state, action) => {
        state.gameIdFormatList.data = action.payload;
      })
      .addCase(getGameIdFormatList.rejected, (state, action) => {
        state.gameIdFormatList.error = action.error.message;
      })
      .addCase(getGamePlatformList.fulfilled, (state, action) => {
        state.gamePlatformList.data = action.payload;
      })
      .addCase(getGamePlatformList.rejected, (state, action) => {
        state.gamePlatformList.error = action.error.message;
      })
      .addCase(getTournamentTypeList.fulfilled, (state, action) => {
        state.tournamentTypeList.data = action.payload;
      })
      .addCase(getTournamentTypeList.rejected, (state, action) => {
        state.tournamentTypeList.error = action.error.message;
      })
      .addCase(getMapListByGameId.fulfilled, (state, action) => {
        state.mapListByGameId.data = action.payload;
      })
      .addCase(getMapListByGameId.rejected, (state, action) => {
        state.mapListByGameId.error = action.error.message;
      })
      .addCase(getTournamentTypeByGameId.fulfilled, (state, action) => {
        state.tournamentTypeByGameId.data = action.payload;
      })
      .addCase(getTournamentTypeByGameId.rejected, (state, action) => {
        state.tournamentTypeByGameId.error = action.error.message;
      });
  },
});

export const { setMapListByGameId, setTournamentTypeBygameId, setFilteredGameList } = gameSlice.actions;
export default gameSlice.reducer;
