import React from "react";
import { Link } from "react-router-dom";

export default function DisputeHeader({
  route,
  path,
  search,
  handleSearch,
  user_type,
  handlePage,
}) {
  return (
    <div className="row">
      <div className="col-12">
        <div className="portlet">
          <div className="horizontal_filter_card d-flex justify-content-between">
            <div className="search_group w-25">
              <input
                onChange={(e) => handleSearch(e.target.value)}
                className="form-control form-control-lg"
                value={search}
                type="text"
                placeholder="Search..."
              />
            </div>
            {(((user_type === "organizer_admin" ||
              user_type === "organizer_sub_admin") &&
              route === "/customers-dispute") ||
              route === "/private-orgs-dispute") && (
              <div className="row">
                <div className="col-12 col-md-12">
                  <div className="horizontal_filter_card p-0">
                    <ul className="nav justify-content-end">
                      <li className="nav-item" onClick={handlePage}>
                        <Link
                          className={`nav-link ${path === "" ? "active" : ""}`}
                          to={`${route}`}
                        >
                          All Dispute
                        </Link>
                      </li>
                      <li className="nav-item" onClick={handlePage}>
                        <Link
                          className={`nav-link ${
                            path === "pending" ? "active" : ""
                          }`}
                          to={`${route}?dispute_status=pending`}
                        >
                          Pending
                        </Link>
                      </li>
                      <li className="nav-item" onClick={handlePage}>
                        <Link
                          className={`nav-link ${
                            path === "dispute" ? "active" : ""
                          }`}
                          to={`${route}?dispute_status=dispute`}
                        >
                          Opened
                        </Link>
                      </li>
                      <li className="nav-item" onClick={handlePage}>
                        <Link
                          className={`nav-link ${
                            path === "escalate" ? "active" : ""
                          }`}
                          to={`${route}?dispute_status=escalate`}
                        >
                          Escalated
                        </Link>
                      </li>
                      <li className="nav-item" onClick={handlePage}>
                        <Link
                          className={`nav-link ${
                            path === "approved" ? "active" : ""
                          }`}
                          to={`${route}?dispute_status=approved`}
                        >
                          Approved
                        </Link>
                      </li>
                      <li className="nav-item" onClick={handlePage}>
                        <Link
                          className={`nav-link ${
                            path === "disagree" ? "active" : ""
                          }`}
                          to={`${route}?dispute_status=disagree`}
                        >
                          Disagree
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
