import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
// import { useDispatch, useSelector } from "react-redux";
import { DropZone } from "../../common";
import uploadImageIcon from "../../../assets/images/site_icons/Duotone.svg";
import { convertToBase64 } from "../../../utils/common.service";
// import * as API from "../../../utils/api.service";
// import { uploadImageOnAWS } from "../../../utils/aws.service";
// import { setLoader } from "../../../store/slices/masterSlice";

export default function CountrySetting({
  onSubmit,
  appSettings,
  isView,
}) {
  const [previewImage, setPreviewImage] = useState("");
  // const dispatch = useDispatch();
  const {
    handleSubmit,
    register,
    setValue,
    clearErrors,
    watch,
    control,
    formState: { errors },
  } = useForm();

  const handleImageChange = async (file) => {
   
    if (file) {
      setValue("flag", file);
      setPreviewImage(await convertToBase64(file));
      clearErrors("flag");
    }
  };
  
  useEffect(() => {
    if (appSettings) {
      const {
        name,
        full_name,
        phonecode,
        iso,
        iso3,
        flag,
        is_active
      } = appSettings;
      setValue("name", name);
      setValue("full_name", full_name);
      setValue("phonecode", phonecode);
      setValue("iso", iso);
      setValue("iso3", iso3);
      setValue("flag",flag);
      setValue("is_active",is_active);
      setPreviewImage(flag);
    }
  }, [appSettings, setValue]);

  return (
    <div className="row">
      <div className="col-12 col-md-6">
        <div className="portlet">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="horizontal_filter_card">
              <div className="row mb-4">
                <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                  Status
                </label>
                <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <div className="form-check form-switch">
                    <input
                      {...register("is_active")}
                      className="form-check-input pointer"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckDefault"
                      disabled={isView}
                    />
                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                      {watch("is_active") ? "Active" : "Inactive"}
                    </label>
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                  Name
                </label>
                <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <input
                    {...register("name", {
                      required: "Name is required.",
                      pattern: {
                        value: /^(?![\s-])[\w\s-]+(?<=\S)$/,
                        message: "White-Space not allowed",
                      },
                      maxLength: {
                        value: 250,
                        message: "Name should not exceed 250 characters.",
                      },
                    })}
                    aria-invalid={errors.name ? "true" : "false"}
                    className="form-control form-control-lg"
                    type="text"
                    placeholder="Enter Name..."
                    disabled={isView}
                  />
                  {errors.name && (
                    <p role="alert" className="text-danger">
                      {errors.name?.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                 Full Name
                </label>
                <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <input
                    {...register("full_name", {
                      required: "Full Name is required.",
                      pattern: {
                        value: /^(?![\s-])[\w\s-]+(?<=\S)$/,
                        message: "White-Space not allowed",
                      },
                      maxLength: {
                        value: 250,
                        message: "Full Name should not exceed 250 characters.",
                      },
                    })}
                    aria-invalid={errors.full_name ? "true" : "false"}
                    className="form-control form-control-lg"
                    type="text"
                    placeholder="Enter Full Name..."
                    disabled={isView}
                  />
                  {errors.full_name && (
                    <p role="alert" className="text-danger">
                      {errors.full_name?.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                  PhoneCode
                </label>
                <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <input
                    {...register("phonecode", {
                      required: "PhoneCode is required.",
                      pattern: {
                        value: /^[0-9]+$/,
                        message: "Must be a number.",
                      },
                    })}
                    aria-invalid={errors.phonecode ? "true" : "false"}
                    className="form-control form-control-lg"
                    type="text"
                    placeholder="Enter PhoneCode"
                    disabled={isView}
                  />
                  {errors.phonecode && (
                    <p role="alert" className="text-danger">
                      {errors.phonecode?.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                  ISO
                </label>
                <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <input
                    {...register("iso", {
                      required: "Iso Field is required.",
                    })}
                    aria-invalid={errors.iso ? "true" : "false"}
                    className="form-control form-control-lg"
                    type="text"
                    placeholder="Enter Iso"
                    disabled={isView}
                  />
                  {errors.iso && (
                    <p role="alert" className="text-danger">
                      {errors?.iso?.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                  ISO3
                </label>
                <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <input
                    {...register("iso3", {
                      required: "Iso3 Field is required.",
                    })}
                    aria-invalid={errors.iso3 ? "true" : "false"}
                    className="form-control form-control-lg"
                    type="text"
                    placeholder="Enter Iso3"
                    disabled={isView}
                  />
                  {errors.iso3 && (
                    <p role="alert" className="text-danger">
                      {errors?.iso3?.message}
                    </p>
                  )}
                </div>
              </div>

              <div className="row mb-4">
                <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                  Flag Image
                </label>
                <div
                  className={`${
                    isView ? "disabled" : ""
                  } col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12`}
                >
                  <Controller
                    name="flag"
                    control={control}
                    defaultValue={null}
                    rules={{ required: "Flag Image is required." }}
                    render={({ field }) => (
                      <DropZone onDrop={(file) => handleImageChange(file)}>
                        <div className="container">
                          <label
                            htmlFor="et_pb_contact_brand_file_request_0"
                            className="et_pb_contact_form_label"
                          >
                            {!watch("flag") ? (
                              <>
                                <img src={uploadImageIcon} alt="Duotone" />
                                Enter
                                <span>Quick file uploader</span>
                                <span>
                                  Drag & Drop or choose files from computer
                                </span>
                              </>
                            ) : (
                              <>
                                <img
                                  src={
                                    previewImage ||
                                    watch("flag")
                                  }
                                  alt="Duotone"
                                  height={150}
                                  width={150}
                                  className="rounded"
                                />
                                <span>
                                  {watch("flag")?.name ||
                                    watch("flag")}
                                </span>
                              </>
                            )}
                          </label>
                          <input
                            type="file"
                            id="et_pb_contact_brand_file_request_0"
                            className="file-upload"
                            accept="image/*"
                            onChange={(e) =>
                              handleImageChange(e.target.files[0])
                            }
                          />
                        </div>
                      </DropZone>
                    )}
                  />
                  {errors.flag && (
                    <p role="alert" className="text-danger">
                      {errors.flag.message}
                    </p>
                  )}
                </div>
              </div>
              {!isView && (
                <div className="row justify-content-end">
                  <div className="col-12 col-md-12">
                    <div className="text-start">
                      <button className="btn btn-primary btn-lg common_hover">
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
