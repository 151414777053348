import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "../../utils/api.service";

export const getCountryList = createAsyncThunk("countryList", async (_, { dispatch }) => {
  try {
    dispatch(setLoader(true));
    let { data: countryList } = await API.getCountryList();
    countryList = countryList?.map((item) => ({
      ...item,
      value: item.id,
      label: item.name,
    }));
    dispatch(setLoader(false));
    return countryList;
  } catch (error) {
    dispatch(setLoader(false));
    throw error;
  }
});

export const getCountryListByFirstLetter = createAsyncThunk(
  "countryListByFirstLetter",
  async (startsWith, { dispatch }) => {
    try {
      dispatch(setLoader(true));
      let { data: countryListByFirstLetter } = await API.getCountryList(startsWith);
      countryListByFirstLetter = countryListByFirstLetter?.map((item) => ({
        ...item,
        value: item.id,
        label: item.name,
      }));
      dispatch(setLoader(false));
      return countryListByFirstLetter;
    } catch (error) {
      dispatch(setLoader(false));
      throw error;
    }
  }
);

export const getAppSettingsList = createAsyncThunk("appSettingsList", async ({ page, filters }, { dispatch }) => {
  try {
    dispatch(setLoader(true));
    const { data: appSettingsList } = await API.getAppSettingsList(page, filters);
    dispatch(setLoader(false));
    return appSettingsList;
  } catch (error) {
    dispatch(setLoader(false));
    throw error;
  }
});

export const getGoldSubscriptionsList = createAsyncThunk(
  "goldSubscriptionsList",
  async ({ page, filters }, { dispatch }) => {
    try {
      dispatch(setLoader(true));
      const { data: goldSubscriptionsList } = await API.getGoldSubscriptionsList(page, filters);
      dispatch(setLoader(false));
      return goldSubscriptionsList;
    } catch (error) {
      dispatch(setLoader(false));
      throw error;
    }
  }
);

export const getCountrySearchListNew = createAsyncThunk(
  "countrySearchList",
  async ({ startsWith }, { dispatch }) => {
    try {
      dispatch(setLoader(true));
      const { data: countrySearchList } = await API.getCountryFilter(startsWith);
      dispatch(setLoader(false));
      return countrySearchList;
    } catch (error) {
      dispatch(setLoader(false));
      throw error;
    }
  }
);

export const getCountryPagination = createAsyncThunk(
  "countryPagination",
  async ({ page, startsWith }, { dispatch }) => {
    try {
      dispatch(setLoader(true));
      const { data: countryPagination } = await API.getCountryPagination(page, startsWith);
      dispatch(setLoader(false));
      return countryPagination;
    } catch (error) {
      dispatch(setLoader(false));
      throw error;
    }
  }
);

const initialState = {
  isLoading: false,
  isModalOpen: {
    modalType: "",
    isOpen: false,
    data: null,
  },
  countryList: {
    data: [],
    error: null,
  },
  countryListByFirstLetter: {
    data: [],
    error: null,
  },
  appSettingsList: {
    data: {},
    error: null,
  },
  goldSubscriptionsList: {
    data: {},
    error: null,
  },
  countrySearchList: {
    data: [],
    error: null,
  },
  countryPagination: {
    data: [],
    error: null
  }
};

const masterSlice = createSlice({
  name: "MASTER_SLICE",
  initialState,
  reducers: {
    setLoader: (state, action) => {
      state.isLoading = action.payload;
    },
    setModalStatus: (state, action) => {
      const { modalType, isOpen, data } = action.payload;
      state.isModalOpen.modalType = modalType;
      state.isModalOpen.isOpen = isOpen;
      state.isModalOpen.data = data;
    },
    setCountryListByFirstLetter: (state) => {
      state.countryListByFirstLetter.data = [];
      state.countryListByFirstLetter.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCountryList.fulfilled, (state, action) => {
        state.countryList.data = action.payload;
      })
      .addCase(getCountryList.rejected, (state, action) => {
        state.countryList.error = action.error.message;
      })
      .addCase(getCountryListByFirstLetter.fulfilled, (state, action) => {
        state.countryListByFirstLetter.data = action.payload;
      })
      .addCase(getCountryListByFirstLetter.rejected, (state, action) => {
        state.countryListByFirstLetter.error = action.error.message;
      })
      .addCase(getAppSettingsList.fulfilled, (state, action) => {
        state.appSettingsList.data = action.payload;
      })
      .addCase(getAppSettingsList.rejected, (state, action) => {
        state.appSettingsList.error = action.error.message;
      })
      .addCase(getGoldSubscriptionsList.fulfilled, (state, action) => {
        state.goldSubscriptionsList.data = action.payload;
      })
      .addCase(getGoldSubscriptionsList.rejected, (state, action) => {
        state.goldSubscriptionsList.error = action.error.message;
      }) 
      .addCase(getCountrySearchListNew.fulfilled, (state, action) => {
        state.countrySearchList.data = action.payload;
      })
      .addCase(getCountrySearchListNew.rejected, (state, action) => {
        state.countrySearchList.error = action.error.message;
      })
      .addCase(getCountryPagination.fulfilled, (state, action) => {
        state.countryPagination.data = action.payload;
      })
      .addCase(getCountryPagination.rejected, (state, action) => {
        state.countryPagination.error = action.error.message;
      });
  },
});

export const { setLoader, setModalStatus, setCountryListByFirstLetter } = masterSlice.actions;
export default masterSlice.reducer;
