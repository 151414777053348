import { configureStore } from "@reduxjs/toolkit";
import masterSlice from "./slices/masterSlice";
import gameSlice from "./slices/gameSlice";
import storySlice from "./slices/storySlice";
import tournamentSlice from "./slices/tournamentSlice";
import bannerSlice from "./slices/bannerSlice";
import eventSlice from "./slices/eventSlice";
import advertisementSlice from "./slices/advertisementSlice";
import userSlice from "./slices/userSlice";
import reasonSlice from "./slices/reasonSlice";
import postSlice from "./slices/postSlice";
import blockSlice from "./slices/blockSlice";
import subAdminSlice from "./slices/subAdminSlice";
import groupSlice from "./slices/groupSlice";
import spotlightSlice from "./slices/spotlightSlice";
import productSlice from "./slices/productSlice";
import organizerSlice from "./slices/organizerSlice";
import disputeSlice from "./slices/disputeSlice";
import supportSlice from "./slices/supportSlice";

const store = configureStore({
  reducer: {
    master: masterSlice,
    game: gameSlice,
    story: storySlice,
    tournament: tournamentSlice,
    banner: bannerSlice,
    user: userSlice,
    event: eventSlice,
    advertisement: advertisementSlice,
    reason: reasonSlice,
    post: postSlice,
    block: blockSlice,
    subAdmin: subAdminSlice,
    group: groupSlice,
    spotlight: spotlightSlice,
    product: productSlice,
    organizer: organizerSlice,
    dispute: disputeSlice,
    support: supportSlice,
  },
});

export default store;
