import { useMemo, useState } from "react";
import Select from "react-select";
import { useSelector } from "react-redux";

const PageSubComponent = ({
  handleSelectCountry,
  selectCountry,
  handleSelectAdvertisementType,
  selectAdvertisementType,
  handleNavigation,
  buttonName,
  handleSearch,
  children,
}) => {
  const {
    countryList: { data: countryList = [] },
  } = useSelector((state) => state.master); 

  const showCountryList = useMemo(() => {
    return countryList.map((country) => {
      return (
        <option key={country.id} value={country.value}>
          {country.label}
        </option>
      );
    });
  }, [countryList]);
  
  const advertisementDetails = [
    { id: 1, label: "General", value: "general" },
    { id: 2, label: "Post", value: "post" },
    { id: 3, label: "World Chat", value: "world_chat" }
  ]

  const advertisementList = useMemo(() => {
    return advertisementDetails.map((e) => {
      return (
        <option key={e.id} value={e.value}>
          {e.label}
        </option>
      );
    });
  }, [advertisementDetails]);


  const pathname = window.location.href.split('/')[3];

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="portlet">
            <div className="horizontal_filter_card">
              {/* <form> */}
                <div className="row justify-content-between align-items-center">
                  <div className="row col-lg-7 col-12">
                    {children && children}
                    <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6 mb-2 mb-sm-0">
                      <Select
                        options={countryList}
                        value={selectCountry}
                        onChange={(country) => handleSelectCountry(country)}
                        isSearchable
                        isClearable
                        placeholder="Select country"
                      />
                    </div>
                    {
                      pathname === "advertisements" ?
                        <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6">
                          <Select
                            options={advertisementDetails}
                            value={selectAdvertisementType}
                            onChange={(advertisementType) => handleSelectAdvertisementType(advertisementType)}
                            isSearchable
                            isClearable
                            placeholder="Select Advertisement Type"
                          />
                        </div> : null
                    }
                  </div>
                  <div className="d-flex justify-content-end col-lg-5 col-md-5 col-sm-6 col-12">
                    <div className="search_group mr-3 mb-2 mb-sm-2">
                      <input
                        className="form-control form-control-lg"
                        type="text"
                        onChange={(e) => handleSearch(e.target.value)}
                        placeholder="Search"
                      />
                    </div>
                    {buttonName && (
                      <button onClick={handleNavigation} className="btn btn-success btn-lg btn-sm btn-md common_hover">
                        <div className="icon_bg_square">
                          <i className="fa fa-plus" aria-hidden="true"></i>
                        </div>
                        {buttonName}
                      </button>
                    )}
                  </div>
                </div>
              {/* </form> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageSubComponent;
