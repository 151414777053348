import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";

export default function AppSettingsBlockImageLabels({
  onSubmit,
  appSettings,
  isView,
}) {
  const [blockImageList, setBlockImageList] = useState([]);
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (appSettings?.labels_to_check) {
      setBlockImageList(
        JSON.parse(appSettings?.labels_to_check?.replaceAll("'", '"'))
      );
    }
  }, [appSettings]);

  const handleRemoveBlockedApk = (i) => {
    let tempBlockImageList = [...blockImageList];
    tempBlockImageList.splice(i, 1);
    setBlockImageList(tempBlockImageList);
    onSubmit({ labels_to_check: JSON.stringify(tempBlockImageList) });
  };

  const handleAddBlockedApk = (data) => {
    const tempBlockImageList = [...blockImageList];
    tempBlockImageList.push(data.labels_to_check);
    setBlockImageList(tempBlockImageList);
    data.labels_to_check = JSON.stringify(tempBlockImageList);
    onSubmit(data);
    reset();
  };

  const showBlockImageList = useMemo(() => {
    return blockImageList?.map((item, i) => {
      return (
        <div
          key={i}
          className="d-flex justify-content-start my-2 p-2 mx-2 border rounded"
          style={{ height: "fit-content" }}
        >
          <p className="my-auto mr-2">{item}</p>
          {!isView && (
            <i
              onClick={() => handleRemoveBlockedApk(i)}
              className="fa fa-close text-danger pointer my-auto"
              aria-hidden="true"
            ></i>
          )}
        </div>
      );
    });
  }, [appSettings?.labels_to_check, blockImageList]);

  return (
    <div className="row">
      <div className="col-12 col-md-6">
        <div className="portlet">
          <div className="horizontal_filter_card">
            <form onSubmit={handleSubmit(handleAddBlockedApk)}>
              <div className="row mb-4">
                <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                  Block Image Labels
                </label>
                <div className="col-10 col-xl-10 col-lg-10 col-md-10 ">
                  <input
                    {...register("labels_to_check", {
                      required: "Image label is required.",
                    })}
                    aria-invalid={errors.labels_to_check ? "true" : "false"}
                    className="form-control form-control-lg"
                    type="text"
                    placeholder="Enter labels"
                    disabled={isView}
                  />
                  {errors.labels_to_check && (
                    <p role="alert" className="text-danger">
                      {errors.labels_to_check?.message}
                    </p>
                  )}
                </div>
                {!isView && (
                  <div className="col-2 col-md-2 col-sm-2">
                    <button className="btn btn-primary btn-lg common_hover w-100">
                      Add
                    </button>
                  </div>
                )}
              </div>
            </form>
            <div className="w-100 list-box d-flex justify-content-start flex-wrap">
              {showBlockImageList}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
