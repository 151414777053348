import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { INDIAN_STATE_LIST } from "../../../app.config";

export default function AppSettingsLocation({ onSubmit, appSettings, isView }) {
  const [blockedStatesList, setBlockedStateList] = useState([]);
  const {
    handleSubmit,
    register,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (appSettings?.states) {
      setBlockedStateList(
        JSON.parse(appSettings?.states?.replaceAll("'", '"'))
      );
    }
  }, [appSettings]);
  
  const handleRemoveBlockedApk = (i) => {
    let tempBlockedStatesList = [...blockedStatesList];
    tempBlockedStatesList.splice(i, 1);
    setBlockedStateList(tempBlockedStatesList);
    onSubmit({ states: JSON.stringify(tempBlockedStatesList) });
  };

  const handleAddBlockedApk = (data) => {
    const tempBlockedStatesList = [...blockedStatesList];
    tempBlockedStatesList.push(data.states);
    setBlockedStateList(tempBlockedStatesList);
    data.states = JSON.stringify(tempBlockedStatesList);
    onSubmit(data);
    reset();
  };

  const showBlockedStates = useMemo(() => {
    return blockedStatesList?.map((item, i) => {
      return (
        <div
          key={i}
          className="d-flex justify-content-start my-2 p-2 mx-2 border rounded"
          style={{ height: "fit-content" }}
        >
          <p className="my-auto mr-2">{item}</p>
          {!isView && (
            <i
              onClick={() => handleRemoveBlockedApk(i)}
              className="fa fa-close text-danger pointer my-auto"
              aria-hidden="true"
            ></i>
          )}
        </div>
      );
    });
  }, [appSettings?.states, blockedStatesList]);

  const showStateList = useMemo(() => {
    return INDIAN_STATE_LIST.map((item) => {
      if (!blockedStatesList.includes(item.name))
        return (
          <option key={item.id} value={item.name}>
            {item.name}
          </option>
        );
    });
  }, [blockedStatesList]);

  return (
    <div className="row">
      <div className="col-12 col-md-6">
        <div className="portlet">
          <div className="horizontal_filter_card">
            <form onSubmit={handleSubmit(handleAddBlockedApk)}>
              <div className="row mb-4">
                <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                {/* (For India Only) */}
                  Service Exclude State 
                </label>
                <div className="col-10 col-xl-10 col-lg-10 col-md-10 ">
                  <select
                    {...register("states", {
                      required: "State is required.",
                    })}
                    aria-invalid={errors.states ? "true" : "false"}
                    className="form-control form-control-lg"
                    type="text"
                    defaultValue={""}
                    disabled={isView}
                  >
                    <option value="">Select state</option>
                    {showStateList}
                  </select>
                  {errors.states && (
                    <p role="alert" className="text-danger">
                      {errors.states?.message}
                    </p>
                  )}
                </div>
                {!isView && (
                  <div className="col-2 col-md-2 col-sm-2">
                    <button className="btn btn-primary btn-lg common_hover w-100">
                      Add
                    </button>
                  </div>
                )}
              </div>
            </form>
            <div className="w-100 list-box d-flex justify-content-start flex-wrap">
              {showBlockedStates}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
