import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "../../utils/api.service";
import { setLoader } from "./masterSlice";

export const getAdvertisementList = createAsyncThunk("advertisementList", async ({ page, filters }, { dispatch }) => {
  try {
    dispatch(setLoader(true));
    const { data: advertisementList } = await API.getAdvertisementList(page, filters);
    dispatch(setLoader(false));
    return advertisementList;
  } catch (error) {
    dispatch(setLoader(false));
    throw error;
  }
});

const initialState = {
  advertisementList: {
    data: [],
    error: null,
  },
};

const advertisementSlice = createSlice({
  name: "ADVERTISEMENT",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAdvertisementList.fulfilled, (state, action) => {
        state.advertisementList.data = action.payload;
      })
      .addCase(getAdvertisementList.rejected, (state, action) => {
        state.advertisementList.error = action.error.message;
      });
  },
});

export default advertisementSlice.reducer;
