import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";

export default function AppSettingsEmailConfig({
  onSubmit,
  appSettings,
  emailPurposeList,
  isView,
}) {
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (appSettings) {
      const {
        email_name,
        email_backend,
        email_host,
        email_port,
        email_contact,
        email_use_tls,
        email_host_password,
      } = appSettings;
      setValue("email_name", email_name);
      setValue("email_backend", email_backend);
      setValue("email_host", email_host);
      setValue("email_port", email_port);
      setValue("email_host_user", email_contact);
      setValue("email_host_password", email_host_password);
      setValue("email_use_tls", email_use_tls);
    }
  }, [appSettings]);

  const showEmailPurposeList = useMemo(() => {
    return emailPurposeList?.results.map((item) => {
      return (
        <option value={item.id} key={item.id}>
          {item.email}
        </option>
      );
    });
  }, [emailPurposeList]);

  return (
    <div className="row">
      <div className="col-12 col-md-6">
        <div className="portlet">
          <div className="horizontal_filter_card">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row mb-4">
                <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                  Email Purpose
                </label>
                <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <select
                    {...register("email_name", {
                      required: "Email purpose is required.",
                    })}
                    aria-invalid={errors.email_name ? "true" : "false"}
                    className="form-select form-select-lg"
                    defaultValue={""}
                    disabled={isView}
                  >
                    <option value={""}>Select email purpose</option>
                    {showEmailPurposeList}
                  </select>
                  {errors.email_name && (
                    <p role="alert" className="text-danger">
                      {errors.email_name?.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                  Email backend
                </label>
                <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <input
                    {...register("email_backend", {
                      required: "Email backend is required.",
                    })}
                    aria-invalid={errors.email_backend ? "true" : "false"}
                    className="form-control form-control-lg"
                    type="text"
                    placeholder="Enter email backend"
                    disabled={isView}
                  />
                  {errors.email_backend && (
                    <p role="alert" className="text-danger">
                      {errors.email_backend?.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                  Email hoster
                </label>
                <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <input
                    {...register("email_host", {
                      required: "Email hoster is required.",
                    })}
                    aria-invalid={errors.email_host ? "true" : "false"}
                    className="form-control form-control-lg"
                    type="text"
                    placeholder="Enter host"
                    disabled={isView}
                  />
                  {errors.email_host && (
                    <p role="alert" className="text-danger">
                      {errors.email_host?.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                  Email port
                </label>
                <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <input
                    {...register("email_port", {
                      required: "Email port is required.",
                      pattern: {
                        value: /^[0-9]+$/,
                        message: "Must be a number.",
                      },
                    })}
                    aria-invalid={errors.email_host ? "true" : "false"}
                    className="form-control form-control-lg"
                    type="text"
                    placeholder="Enter port"
                    disabled={isView}
                  />
                  {errors.email_port && (
                    <p role="alert" className="text-danger">
                      {errors.email_port?.message}
                    </p>
                  )}
                </div>
              </div>
              {/* <div className="row mb-4">
                <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                  Email host user ID
                </label>
                <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <input
                    {...register("email_host_user", {
                      required: "Host user is required.",
                    })}
                    aria-invalid={errors.email_host_user ? "true" : "false"}
                    className="form-control form-control-lg"
                    type="text"
                    placeholder="Enter host user"
                  />
                  {errors.email_host_user && (
                    <p role="alert" className="text-danger">
                      {errors.email_host_user?.message}
                    </p>
                  )}
                </div>
              </div> */}
              <div className="row mb-4">
                <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                  Email host password
                </label>
                <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <input
                    {...register("email_host_password", {
                      required: "Host password is required.",
                    })}
                    aria-invalid={errors.email_host_password ? "true" : "false"}
                    className="form-control form-control-lg"
                    type="text"
                    placeholder="Enter host password"
                    disabled={isView}
                  />
                  {errors.email_host_password && (
                    <p role="alert" className="text-danger">
                      {errors.email_host_password?.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-12 col-xl-4 col-sm-4 col-lg-4 col-md-4 col-form-label text-sm-start">
                  Email user tls
                </label>
                <div className="col-12 col-xl-2 col-lg-2 col-md-2 col-sm-2">
                  <div className="form-check form-switch">
                    <input
                      {...register("email_use_tls")}
                      className="form-check-input pointer"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckDefault"
                      defaultChecked
                      disabled={isView}
                    />
                  </div>
                </div>
              </div>

              {!isView && (
                <div className="row justify-content-end">
                  <div className="col-12 col-md-12">
                    <div className="text-end">
                      <button className="btn btn-primary btn-lg common_hover">
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
