import React, { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { TOAST_ERROR, TOAST_SUCCESS } from "../../../utils/common.service";
import * as API from "../../../utils/api.service";
import { setLoader } from "../../../store/slices/masterSlice";
import { useDispatch } from "react-redux";

export default function AppSettingsEmailPurpose({
  emailPurposeList,
  getEmailPurposeList,
  isView,
}) {
  console.log("emailPurposeList: ", emailPurposeList);
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    try {
      dispatch(setLoader(true));
      const { message, code } = await API.addEmailPurpose(data);
      if (code === 1) {
        TOAST_SUCCESS(message);
        reset();
        getEmailPurposeList();
      } else TOAST_ERROR(message);
      dispatch(setLoader(false));
    } catch (err) {
      dispatch(setLoader(false));
      TOAST_ERROR(err.message);
    }
  };

  const handleRemoveEmailPurpose = async (id) => {
    try {
      dispatch(setLoader(true));
      const { message, code } = await API.deleteEmailPurpose(id);
      if (code === 1) {
        TOAST_SUCCESS(message);
        getEmailPurposeList();
      } else TOAST_ERROR(message);
      dispatch(setLoader(false));
    } catch (err) {
      dispatch(setLoader(false));
      TOAST_ERROR(err.message);
    }
  };

  const showEmailPurposeList = useMemo(() => {
    if (emailPurposeList?.results?.length === 0)
      return (
        <tr>
          <td colSpan={4}>No data found</td>
        </tr>
      );
    else
      return emailPurposeList?.results?.map((item) => {
        return (
          <tr key={item.id}>
            {!isView && (
              <th scope="row">
                <i
                  onClick={() => handleRemoveEmailPurpose(item.id)}
                  className="fa fa-trash text-danger mx-2 pointer"
                  aria-hidden="true"
                ></i>
              </th>
            )}
            <td>{item.email}</td>
            <td>{item.email_host_password}</td>
          </tr>
        );
      });
  }, [emailPurposeList]);

  return (
    <div className="row">
      <div className="col-12 col-md-6">
        <div className="portlet">
          <div className="horizontal_filter_card">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row mb-4">
                <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                  Email Host
                </label>
                <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <input
                    {...register("email", {
                      required: "Email host is required.",
                    })}
                    aria-invalid={errors.email ? "true" : "false"}
                    className="form-control form-control-lg"
                    type="text"
                    placeholder="Enter email host"
                    disabled={isView}
                  />
                  {errors.email && (
                    <p role="alert" className="text-danger">
                      {errors.email?.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                  Password
                </label>
                <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <input
                    {...register("email_host_password", {
                      required: "Password is required.",
                    })}
                    aria-invalid={errors.email_host_password ? "true" : "false"}
                    className="form-control form-control-lg"
                    type="text"
                    placeholder="Enter password"
                    disabled={isView}
                  />
                  {errors.email_host_password && (
                    <p role="alert" className="text-danger">
                      {errors.email_host_password?.message}
                    </p>
                  )}
                </div>
              </div>

              {!isView && (
                <div className="row justify-content-end">
                  <div className="col-12 col-md-12">
                    <div className="text-end">
                      <button className="btn btn-primary btn-lg common_hover">
                        Add
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-6">
        <div className="portlet">
          <div className="horizontal_filter_card">
            <div className="horizontal_filter_card">
              <div className="table-responsive table-responsive-lg">
                <table className="table mb-0">
                  <thead>
                    <tr>
                      {!isView && <th>Action</th>}
                      <th>Email </th>
                      <th>Email Host Password</th>
                    </tr>
                  </thead>
                  <tbody className="table-group-divider">
                    {showEmailPurposeList}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
