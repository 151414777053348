import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { isValidUrl } from "../../../utils/common.service";

export default function AppSettingsAppUpdate({
  onSubmit,
  appSettings,
  isView,
}) {
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (appSettings) {
      const {
        update_required,
        updated_version_android,
        updated_version_ios,
        ios_update_url,
        android_update_url,
      } = appSettings;
      setValue("update_required", update_required);
      setValue("updated_version_android", updated_version_android);
      setValue("updated_version_ios", updated_version_ios);
      setValue("ios_update_url", ios_update_url);
      setValue("android_update_url", android_update_url);
    }
  }, [appSettings]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col-12 col-md-12">
          <div className="portlet">
            <div className="horizontal_filter_card">
              <div className="row mb-4">
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="payment_info_container">
                    <div className="payment_blue_section">
                      <div className="payment_info-header mb-4">
                        <div className="payment_company_tag">
                          <span>Android App Update</span>
                        </div>
                      </div>
                      <div className="row mb-4">
                        <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                          Update Type
                        </label>
                        <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                          <select
                            {...register("update_required", {
                              required: "update required is required.",
                            })}
                            aria-invalid={
                              errors.update_required ? "true" : "false"
                            }
                            className="form-select form-select-lg"
                            defaultValue={""}
                            disabled={isView}
                          >
                            <option value="">Select update required</option>
                            <option value="soft">Soft</option>
                            <option value="hard">Hard</option>
                          </select>
                          {errors.update_required && (
                            <p role="alert" className="text-danger">
                              {errors.update_required?.message}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="row mb-4">
                        <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                          Android updated version
                        </label>
                        <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                          <input
                            {...register("updated_version_android", {
                              required: "Android updated version is required.",
                            })}
                            aria-invalid={
                              errors.updated_version_android ? "true" : "false"
                            }
                            className="form-control form-control-lg"
                            type="text"
                            placeholder="Enter android updated version"
                            disabled={isView}
                          />
                          {errors.updated_version_android && (
                            <p role="alert" className="text-danger">
                              {errors.updated_version_android?.message}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="row mb-4">
                        <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                          Android update url
                        </label>
                        <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                          <input
                            {...register("android_update_url", {
                              required: "Android update url is required.",
                              validate: (value) =>
                                isValidUrl(value) ||
                                "Invalid android update URL",
                            })}
                            aria-invalid={
                              errors.android_update_url ? "true" : "false"
                            }
                            className="form-control form-control-lg"
                            type="text"
                            placeholder="Enter android update url"
                            disabled={isView}
                          />
                          {errors.android_update_url && (
                            <p role="alert" className="text-danger">
                              {errors.android_update_url?.message}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="payment_info_container">
                    <div className="payment_blue_section">
                      <div className="payment_info-header mb-4">
                        <div className="payment_company_tag">
                          <span>IOS App Update</span>
                        </div>
                      </div>
                      <div className="row mb-4">
                        <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                          Update Type
                        </label>
                        <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                          <select
                            {...register("update_required", {
                              required: "update required is required.",
                            })}
                            aria-invalid={
                              errors.update_required ? "true" : "false"
                            }
                            className="form-select form-select-lg"
                            defaultValue={""}
                            disabled={isView}
                          >
                            <option value="">Select update required</option>
                            <option value="soft">Soft</option>
                            <option value="hard">Hard</option>
                          </select>
                          {errors.update_required && (
                            <p role="alert" className="text-danger">
                              {errors.update_required?.message}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="row mb-4">
                        <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                          IOS updated version
                        </label>
                        <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                          <input
                            {...register("updated_version_ios", {
                              required: "IOS updated version is required.",
                            })}
                            aria-invalid={
                              errors.updated_version_ios ? "true" : "false"
                            }
                            className="form-control form-control-lg"
                            type="text"
                            placeholder="Enter ios updated version"
                            disabled={isView}
                          />
                          {errors.updated_version_ios && (
                            <p role="alert" className="text-danger">
                              {errors.updated_version_ios?.message}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="row mb-4">
                        <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                          IOS update url
                        </label>
                        <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                          <input
                            {...register("ios_update_url", {
                              required: "IOS update url is required.",
                              validate: (value) =>
                                isValidUrl(value) || "Invalid IOS update URL",
                            })}
                            aria-invalid={
                              errors.ios_update_url ? "true" : "false"
                            }
                            className="form-control form-control-lg"
                            type="text"
                            placeholder="Enter ios update url"
                            disabled={isView}
                          />
                          {errors.ios_update_url && (
                            <p role="alert" className="text-danger">
                              {errors.ios_update_url?.message}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {!isView && (
                <div className="row justify-content-end">
                  <div className="col-12 col-md-12">
                    <div className="text-end">
                      <button className="btn btn-primary btn-lg common_hover">
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
