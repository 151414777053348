import React, { useState, useEffect } from "react";
import { COUNT_PER_PAGE, ITEM_PER_PAGE } from "../../../app.config";

const Pagination = ({ handlePageChange, page, totalCount }) => {
  const [index, setIndex] = useState(0);
  const [count, setCount] = useState(0);
  const pageCount = Math.ceil(totalCount / COUNT_PER_PAGE);
  const isNextPage = pageCount > page;
  // const isNextPage = pageCount >= page;
  const isPrevPage = page !== 1;

  const paginationIndex = COUNT_PER_PAGE * page;
  // console.log('pagination index', paginationIndex);
  const startIndex =
    totalCount > paginationIndex - ITEM_PER_PAGE ? paginationIndex - ITEM_PER_PAGE : totalCount - ITEM_PER_PAGE;
  // console.log('pagination startIndex', startIndex);

  const endIndex = totalCount > paginationIndex ? paginationIndex : totalCount;
  // console.log('pagination endIndex', endIndex);

  useEffect(() => {
    let count = pageCount > 2 ? 3 : pageCount;
    setCount(count);
  }, [pageCount]);

  const handlePage = (type) => {
    if (type === "next" && isNextPage) {
      const tempPage = page + 1;
      handlePageChange(tempPage);
      if (tempPage > 3 && pageCount > count) {
        setIndex((index) => index + 1);
        setCount((count) => count + 1);
      }
    } else if (type === "prev" && isPrevPage) {
      const tempPage = page - 1;
      handlePageChange(tempPage);
      if (tempPage >= 2 && count > 3) {
        setIndex((index) => index - 1);
        setCount((count) => count - 1);
      }
    }
  };

  const handlePageOnClick = (tempPage) => {
    if (tempPage >= 2 && page > tempPage && count > 3) {
      setIndex((index) => index - 1);
      setCount((count) => count - 1);
    } else if (tempPage > 3 && page < tempPage && pageCount > count) {
      setIndex((index) => index + 1);
      setCount((count) => count + 1);
    }
    handlePageChange(tempPage);
  };
  return (
    <>
      {totalCount ? (
        <div className="container-fluid g-5">
          <div className="pagination_dv">
            <div className="bottom_pagination_left">
              <p>
                Showing {startIndex > 1 ? startIndex : 1} to {endIndex} of {totalCount} entries
              </p>
            </div>
            <div className="bottom_pagination_right">
              <ul className="pagination justify-content-end">
                <li className={`${!isPrevPage ? "disabled" : "pointer"} page-item only_bg`}>
                  <a className="page-link" onClick={() => handlePage("prev")}>
                    <i className="fa fa-angle-left"></i>
                  </a>
                </li>

                {(() => {
                  const pages = [];
                  for (let i = index; i < count; i++) {
                    pages.push(
                      <li key={i} className={`page-item pointer ${page === i + 1 ? "active" : ""}`}>
                        <a className="page-link" onClick={() => handlePageOnClick(i + 1)}>
                          {i + 1}
                        </a>
                      </li>
                    );
                  }
                  return pages;
                })()}
                <li className={`${!isNextPage ? "disabled" : "pointer"} page-item only_bg`}>
                  <a className="page-link" onClick={() => handlePage("next")}>
                    <i className="fa fa-angle-right"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Pagination;
