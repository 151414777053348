import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { isValidUrl } from "../../../utils/common.service";

export default function AppSettingsProConditions({
  onSubmit,
  appSettings,
  isView,
}) {
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (appSettings) {
      const {
        minimum_day_of_joined,
        min_follower,
        min_follower_days,
        match_played,
        match_played_days,
        minimum_reports_on_profile,
        minimum_reports_on_profile_in_days,
      } = appSettings;
      setValue("minimum_day_of_joined", minimum_day_of_joined);
      setValue("min_follower", min_follower);
      setValue("min_follower_days", min_follower_days);
      setValue("match_played", match_played);
      setValue("match_played_days", match_played_days);
      setValue("minimum_reports_on_profile", minimum_reports_on_profile);
      setValue(
        "minimum_reports_on_profile_in_days",
        minimum_reports_on_profile_in_days
      );
    }
  }, [appSettings]);

  return (
    <div className="row">
      <div className="col-12 col-md-8 col-lg-6">
        <div className="portlet">
          <div className="horizontal_filter_card">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row mb-4">
                <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                  Account Age/Seniority (in Days)
                </label>
                <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <input
                    {...register("minimum_day_of_joined", {
                      required: "Account age is required.",
                    })}
                    aria-invalid={
                      errors.minimum_day_of_joined ? "true" : "false"
                    }
                    className="form-control form-control-lg"
                    type="text"
                    placeholder="Enter account age"
                    disabled={isView}
                  />
                  {errors.minimum_day_of_joined && (
                    <p role="alert" className="text-danger">
                      {errors.minimum_day_of_joined?.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div className="row mb-4 w-100 mr-1">
                  <label className="col-12 col-xl-12 col-sm-12 col-lg-6 col-md-6 col-form-label text-sm-start">
                    Minimum followers
                  </label>
                  <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <input
                      {...register("min_follower", {
                        required: "Minimum followers is required.",
                      })}
                      aria-invalid={errors.min_follower ? "true" : "false"}
                      className="form-control form-control-lg w-100"
                      type="text"
                      placeholder="Enter minimum followers"
                      disabled={isView}
                    />
                    {errors.min_follower && (
                      <p role="alert" className="text-danger">
                        {errors.min_follower?.message}
                      </p>
                    )}
                  </div>
                </div>
                <div className="row mb-4 w-100 ml-1">
                  <label className="col-12 col-xl-12 col-sm-12 col-lg-6 col-md-6 col-form-label text-sm-start">
                    In Every Days
                  </label>
                  <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <input
                      {...register("min_follower_days", {
                        required: "Minimum follower days is required.",
                      })}
                      aria-invalid={errors.min_follower_days ? "true" : "false"}
                      className="form-control form-control-lg"
                      type="text"
                      placeholder="Enter minimum follower days"
                      disabled={isView}
                    />
                    {errors.min_follower_days && (
                      <p role="alert" className="text-danger">
                        {errors.min_follower_days?.message}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div className="row mb-4 w-100 mr-1">
                  <label className="col-12 col-xl-12 col-sm-12 col-lg-6 col-md-6 col-form-label text-sm-start">
                    Completed Tournaments
                  </label>
                  <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <input
                      {...register("match_played", {
                        required: "Match played count is required.",
                      })}
                      aria-invalid={errors.match_played ? "true" : "false"}
                      className="form-control form-control-lg"
                      type="text"
                      placeholder="Enter match played count"
                      disabled={isView}
                    />
                    {errors.match_played && (
                      <p role="alert" className="text-danger">
                        {errors.match_played?.message}
                      </p>
                    )}
                  </div>
                </div>
                <div className="row mb-4 w-100 ml-1">
                  <label className="col-12 col-xl-12 col-sm-12 col-lg-6 col-md-6 col-form-label text-sm-start">
                    In Every Days
                  </label>
                  <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <input
                      {...register("match_played_days", {
                        required: "Match played days is required.",
                      })}
                      aria-invalid={errors.match_played_days ? "true" : "false"}
                      className="form-control form-control-lg"
                      type="text"
                      placeholder="Enter match played days"
                      disabled={isView}
                    />
                    {errors.match_played_days && (
                      <p role="alert" className="text-danger">
                        {errors.match_played_days?.message}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div className="row mb-4 w-100 mr-1">
                  <label className="col-12 col-xl-12 col-sm-12 col-lg-6 col-md-6 col-form-label text-sm-start">
                    Minimum reports on profile
                  </label>
                  <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <input
                      {...register("minimum_reports_on_profile", {
                        required: "Minimum reports is required.",
                      })}
                      aria-invalid={
                        errors.minimum_reports_on_profile ? "true" : "false"
                      }
                      className="form-control form-control-lg"
                      type="text"
                      placeholder="Enter minimum reports on profile"
                      disabled={isView}
                    />
                    {errors.minimum_reports_on_profile && (
                      <p role="alert" className="text-danger">
                        {errors.minimum_reports_on_profile?.message}
                      </p>
                    )}
                  </div>
                </div>
                <div className="row mb-4 w-100 ml-1">
                  <label className="col-12 col-xl-12 col-sm-12 col-lg-6 col-md-6 col-form-label text-sm-start">
                    In Every Days
                  </label>
                  <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <input
                      {...register("minimum_reports_on_profile_in_days", {
                        required: "Minimum reports in days is required.",
                      })}
                      aria-invalid={
                        errors.minimum_reports_on_profile_in_days
                          ? "true"
                          : "false"
                      }
                      className="form-control form-control-lg"
                      type="text"
                      placeholder="Enter minimum reports in days"
                      disabled={isView}
                    />
                    {errors.minimum_reports_on_profile_in_days && (
                      <p role="alert" className="text-danger">
                        {errors.minimum_reports_on_profile_in_days?.message}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              {!isView && (
                <div className="row justify-content-end">
                  <div className="col-12 col-md-12">
                    <div className="text-start">
                      <button className="btn btn-primary btn-lg common_hover">
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
