import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import advertisementIcon from "../../assets/images/site_icons/advertisement.svg";
import advertisementSelectedIcon from "../../assets/images/site_icons/advertisement_selected.svg";
import bonusIcon from "../../assets/images/site_icons/bonus.svg";
import cartIcon from "../../assets/images/site_icons/cart.svg";
import noteIcon from "../../assets/images/site_icons/note.svg";
import permissionGroupIcon from "../../assets/images/site_icons/permission_g.svg";
import privateOrgDIcon from "../../assets/images/site_icons/private_org_d.svg";
import goldPremiumIcon from "../../assets/images/site_icons/gold_premium.svg";
import proMembershipIcon from "../../assets/images/site_icons/pro_membership.svg";
import spotlightMsgIcon from "../../assets/images/site_icons/spotlight_msg.svg";
import allPostIcon from "../../assets/images/site_icons/all_post.svg";
import ProBadgeIcon from "../../assets/images/site_icons/pro_badge.svg";
import deviceIcon from "../../assets/images/site_icons/device.svg";
import blockIpIcon from "../../assets/images/site_icons/block_ip.svg";
import reportPostIcon from "../../assets/images/site_icons/report_post.svg";
import reportChatIcon from "../../assets/images/site_icons/report_chat.svg";
import pushNotificationIcon from "../../assets/images/site_icons/push_noti.svg";
import emailIcon from "../../assets/images/site_icons/email.svg";
import worldChatIcon from "../../assets/images/site_icons/world_chat.svg";
import worldIcon from "../../assets/images/site_icons/world.svg";
import profileIcon from "../../assets/images/site_icons/w_svg_m.svg";
import dashboardIcon from "../../assets/images/site_icons/dadhboard_ic.svg";
import dashboardMIcon from "../../assets/images/site_icons/dashboard_m_svg.svg";
import dashboardSelectIcon from "../../assets/images/site_icons/dashboard_select_svg.svg";
import eventsIcon from "../../assets/images/site_icons/events.svg";
import eventSelectedIcon from "../../assets/images/site_icons/event_selected.svg";
import repostIcon from "../../assets/images/site_icons/repost-post.svg";
import gamesIcon from "../../assets/images/site_icons/games.svg";
import tournamentsIcon from "../../assets/images/site_icons/tournaments.svg";
import userIcon from "../../assets/images/site_icons/user.svg";
import subAdminIcon from "../../assets/images/site_icons/sub-admin.svg";
import verificationIcon from "../../assets/images/site_icons/verification_m_svg.svg";
import paymentsIcon from "../../assets/images/site_icons/payments.svg";
import addMoneyIcon from "../../assets/images/site_icons/addmoney.svg";
// import paypalMIcon from "../../assets/images/site_icons/paypal_m_svg.svg";
import withdrawIcon from "../../assets/images/site_icons/withdraw.svg";
import supportIcon from "../../assets/images/site_icons/support.svg";
import contactUsIcon from "../../assets/images/site_icons/contactus.svg";
import storiesIcon from "../../assets/images/site_icons/stories.svg";
import winningCupIcon from "../../assets/images/site_icons/winning_cup.svg";
// import couponIcon from "../../assets/images/site_icons/coupon.svg";
// import reportUserIcon from "../../assets/images/site_icons/report-user.svg";
// import settingsIcon from "../../assets/images/site_icons/settings.svg";
// import reasonMIcon from "../../assets/images/site_icons/reason_m_svg.svg";
import groupMIcon from "../../assets/images/site_icons/groups_m_svg.svg";
// import reasonListIcon from "../../assets/images/site_icons/reasonList_m_svg.svg";

const Sidebar = () => {

  const location = useLocation();
  // const navigate = useNavigate();
  const [openMenus, setOpenMenus] = useState({});
  const [isMaximized, setIsMaximized] = useState(true);

  useEffect(() => {
    const minimizeMaximizeBtn = document.getElementById('minimize-maximize-btn');
    const warBody = document.getElementById('war-body');

    if (warBody) {
      if (isMaximized) {
        warBody.classList.add('aside-desktop-maximized');
        warBody.classList.remove('aside-desktop-minimized');
      } else {
        warBody.classList.add('aside-desktop-minimized');
        warBody.classList.remove('aside-desktop-maximized');
      }
    }

    const handleToggle = () => {
      setIsMaximized((prevState) => !prevState);
    };

    if (minimizeMaximizeBtn) {
      minimizeMaximizeBtn.addEventListener('click', handleToggle);
    }

    return () => {
      if (minimizeMaximizeBtn) {
        minimizeMaximizeBtn.removeEventListener('click', handleToggle);
      }
    };
  }, [isMaximized]);

  const toggleSubMenu = (id) => {
    setOpenMenus((prevOpenMenus) => {
      // ...prevOpenMenus,
      // [id]: !prevOpenMenus[id]
      const newOpenMenus = {};
      // Close all menus
      for (let key in prevOpenMenus) {
        newOpenMenus[key] = false;
      }
      // Toggle the selected menu
      newOpenMenus[id] = !prevOpenMenus[id];
      return newOpenMenus;
    });
  };

  const path = "/" + location?.pathname?.split("/")?.[1];
  const path2 = "/" + location?.pathname?.split("/")?.[2];
  const { userDetails: { data: { permissions = {}, user_type = "" } = {} } = {} } = useSelector((state) => state.subAdmin);

  return (
    <>
      <div className="aside-header">
        <h3 className="aside-title">
          <img alt="w_svg_m" src={profileIcon} />
          War94
        </h3>
        <div className="aside-addon">
          <button className="btn btn-label-primary btn-icon btn-lg" id="minimize-maximize-btn" data-toggle="aside">
            <i className="fa fa-times aside-icon-minimize"></i>
            <i className="fa fa-thumbtack aside-icon-maximize"></i>
          </button>
        </div>
      </div>
      
      {user_type && (
        <div className="aside-body" data-simplebar data-simplebar-direction="ltr">
            <div className="menu" >
            {/* <div className="menu-item">
              <Link to="/" className="menu-item-link">
                <div className="menu-item-icon">
                  <img src={dashboardIcon} alt="dadhboard_ic" />
                </div>
                <span className="menu-item-text text-dark">Dashboard</span>
              </Link>
            </div> */}

 
            {/* BEGIN Menu Section */}
            {/* <div className="menu-section">
              <div className="menu-section-icon">
                <i className="fa fa-ellipsis-h"></i>
              </div>
              <h2 className="menu-section-text">{user_type === "sub_admin" ? "Sub Admin" : "Admin"} Control</h2>
            </div> */}
            {/* END Menu Section */}


            <div className={`menu-item ${path === "/" ? "active" : ""}`}>
              <Link to="/" className={`menu-item-link ${path === "/" ? "active": ""}`} onClick={() => toggleSubMenu("dashboard")}>
                <div className="menu-item-icon">
                  {/* <i className="fa fa-chart-pie"></i> */}
                  <img src={dashboardIcon} alt="dashboard_m_svg" className="default" />
                  <img src={dashboardIcon} alt="dashboard_m_svg" className="active_img" />
                </div>
                <span className="menu-item-text">Dashboard</span>
              </Link>
            </div>


            {(user_type === "admin" || permissions?.["View Events"] || permissions?.["View Banners"] || permissions?.["View Stories"] || permissions?.["View Advertisements"]) && (
              <>
                {/* BEGIN Menu Section */}
                <div className="menu-section">
                  <div className="menu-section-icon">
                    <i className="fa fa-ellipsis-h"></i>
                  </div>
                  <h2 className="menu-section-text">Base Control</h2>
                </div>
                {/* END Menu Section */}

                {(user_type === "admin" || permissions?.["View Events"]) && (
                  <div className={`menu-item ${path === "/events" ? "active" : ""}`} id="events">
                    <button className={`menu-item-link menu-item-toggle custom_edit_menu ${openMenus["events"] && path === "/events" ? "active" : ""}`} onClick={() => toggleSubMenu("events")}>
                      <div className="menu-item-icon">
                        <img src={eventsIcon} alt="event" className="default" />
                        <img src={eventSelectedIcon} alt="event_selected" className="active_img" />
                      </div>
                      <div className="df_menu_block">
                        <Link to="/events?is_active=1" className={`menu-item-text ${path === "/events" ? "text-white" : ""}`}>
                          Events
                        </Link>
                      </div>
                      <div className="menu-item-addon">
                        <i className="menu-item-caret caret"></i>
                      </div>
                    </button>
                    {/* <div className="df_menu_block">
                      <Link to="/events" className="menu-item-text">
                        Events
                      </Link>
                    </div> */}

                    {/* BEGIN Menu Submenu */}
                    {(openMenus["events"] && path === "/events") && 
                      (
                      <div className="menu-submenu">
                        <div className="menu-item">
                          <Link to="/events?is_active=1" className="menu-item-link">
                            <i className="menu-item-bullet"></i>
                            <span className="menu-item-text">Active Events</span>
                          </Link>
                        </div>
                        <div className="menu-item">
                          <Link to="/events?is_active=0" className="menu-item-link">
                            <i className="menu-item-bullet"></i>
                            <span className="menu-item-text">Inactive Events</span>
                          </Link>
                        </div>
                      </div>
                      )
                    }
                    {/* END Menu Submenu */}
                  </div>
                )}

                {(user_type === "admin" || permissions?.["View Banners"]) && (
                  <div className={`menu-item ${path === "/banners" ? "active" : ""}`} id="banners">
                    <button className={`menu-item-link menu-item-toggle custom_edit_menu ${openMenus["banners"] && path === "/banners" ? "active" : ""}`} onClick={() => toggleSubMenu("banners")}>
                      <div className="menu-item-icon">
                        <img src={repostIcon} alt="repost-post" className="default" />
                        <img src={repostIcon} alt="repost-post" className="active_img" />
                      </div>
                      <div className="df_menu_block">
                        <Link to="/banners?is_active=1" className={`menu-item-text ${path === "/banners" ? "text-white" : ""}`}>
                          Banners
                        </Link>
                      </div>
                      <div className="menu-item-addon">
                        <i className="menu-item-caret caret"></i>
                      </div>
                    </button>
                    {/* <div className="df_menu_block">
                      <Link to="/banners" className="menu-item-text">
                        Banners
                      </Link>
                    </div> */}

                    {/* BEGIN Menu Submenu */}
                    {(openMenus["banners"] && path === "/banners") && 
                      (
                        <div className="menu-submenu">
                          <div className="menu-item">
                            <Link to="/banners?is_active=1" className="menu-item-link">
                              <i className="menu-item-bullet"></i>
                              <span className="menu-item-text">Active Banners</span>
                            </Link>
                          </div>
                          <div className="menu-item">
                            <Link to="/banners?is_active=0" className="menu-item-link">
                              <i className="menu-item-bullet"></i>
                              <span className="menu-item-text">Inactive Banners</span>
                            </Link>
                          </div>
                        </div>
                      )
                    }
                    {/* END Menu Submenu */}
                  </div>
                )}

                {(user_type === "admin" || permissions?.["View Stories"]) && (
                  <div className={`menu-item ${path === "/stories" ? "active" : ""}`}>
                    <Link to="/stories" className="menu-item-link" onClick={() => toggleSubMenu("stories")}>
                      <div className="menu-item-icon">
                        {/* <i className="fa fa-chart-pie"></i> */}
                        <img src={storiesIcon} alt="stories" className="default" />
                        <img src={storiesIcon} alt="stories" className="active_img" />
                      </div>
                      <span className="menu-item-text">Story</span>
                    </Link>
                  </div>
                )}

                {(user_type === "admin" || permissions?.["View Advertisements"]) && (
                  <div className={`menu-item ${path === "/advertisements" ? "active" : ""}`} id="advertisements">
                    <button className={`menu-item-link menu-item-toggle custom_edit_menu ${openMenus["advertisements"] && path === "/advertisements" ? "active" : ""}`} onClick={() => toggleSubMenu("advertisements")}>
                      <div className="menu-item-icon">
                        <img src={advertisementIcon} alt="advertisements" className="default" />
                        <img src={advertisementSelectedIcon} alt="advertisement_selected" className="active_img" />
                      </div>
                      <div className="df_menu_block">
                        <Link to="/advertisements?is_active=1" className={`menu-item-text ${path === "/advertisements" ? "text-white" : ""}`}>
                          Advertisements
                        </Link>
                      </div>
                      <div className="menu-item-addon">
                        <i className="menu-item-caret caret"></i>
                      </div>
                    </button>
                    {/* <div className="df_menu_block">
                      <Link to="/advertisements" className="menu-item-text">
                        Advertisements
                      </Link>
                    </div> */}

                    {/* BEGIN Menu Submenu */}
                    {(openMenus["advertisements"] && path === "/advertisements") &&
                      (
                        <div className="menu-submenu">
                          <div className="menu-item">
                            <Link to="/advertisements?is_active=1" className="menu-item-link">
                              <i className="menu-item-bullet"></i>
                              <span className="menu-item-text">Active Advertisements</span>
                            </Link>
                          </div>
                          <div className="menu-item">
                            <Link to="/advertisements?is_active=0" className="menu-item-link">
                              <i className="menu-item-bullet"></i>
                              <span className="menu-item-text">Inactive Advertisements</span>
                            </Link>
                          </div>
                        </div>
                      )
                    }
                    {/* END Menu Submenu */}
                  </div>
                )}

                {(user_type === "admin" || permissions?.["View Countries"]) && (
                  <div className={`menu-item ${path === "/country-list" ? "active" : ""}`}>
                    <Link to="/country-list" className="menu-item-link" onClick={() => toggleSubMenu("country-list")}>
                      <div className="menu-item-icon">
                        <img src={worldIcon} alt="world_svg" className="default" />
                        <img src={worldIcon} alt="world_svg" className="active_img" />
                      </div>
                      <span className="menu-item-text">Country List</span>
                    </Link>
                  </div>
                )}
              </>
            )}


            {(user_type === "admin" || permissions?.["View Game"] || permissions?.["View Tournament"] || permissions?.["View Tournament Rooms"]) && (
              <>
                {/* BEGIN Menu Section */}
                <div className="menu-section">
                  <div className="menu-section-icon">
                    <i className="fa fa-ellipsis-h"></i>
                  </div>
                  <h2 className="menu-section-text">GT Section</h2>
                </div>
                {/* END Menu Section */}

                {(user_type === "admin" || permissions?.["View Game"]) && (
                  <div className={`menu-item ${path === "/games" ? "active" : ""}`}  id="games">
                    <button className={`menu-item-link menu-item-toggle custom_edit_menu ${openMenus["games"] && path === "/games" ? "active" : ""}`} onClick={() => toggleSubMenu("games")}>
                      <div className="menu-item-icon">
                        <img src={gamesIcon} alt="games" className="default" />
                        <img src={gamesIcon} alt="games" className="active_img" />
                      </div>
                      <div className="df_menu_block ">
                        <Link to="/games?is_active=1" className={`menu-item-text ${path === "/games" ? "text-white" : ""}`}>
                          {user_type === "admin" || user_type === "sub_admin" ? "Games" : "Available Games"}
                        </Link>
                      </div>
                      <div className="menu-item-addon">
                        <i className="menu-item-caret caret"></i>
                      </div>
                    </button>
                    {/* <div className="df_menu_block">
                      <Link to="/games" className="menu-item-text">
                        {user_type === "admin" || user_type === "sub_admin" ? "Games" : "Available Games"}
                      </Link>
                    </div> */}
                    {(openMenus["games"] && path === "/games") && 
                      (
                      <div className="menu-submenu">
                        <div className="menu-item">
                          <Link to="/games?is_active=1" className="menu-item-link">
                            <i className="menu-item-bullet"></i>
                            <span className="menu-item-text">Active Games</span>
                          </Link>
                        </div>
                        <div className="menu-item">
                          <Link to="/games?is_active=0" className="menu-item-link">
                            <i className="menu-item-bullet"></i>
                            <span className="menu-item-text">Inactive Games</span>
                          </Link>
                        </div>
                      </div>
                      )
                    }
                    {/* END Menu Submenu */}
                  </div>
                )}

                {(user_type === "admin" || permissions?.["View Tournament"]) && (
                  <div className={`menu-item ${path === "/tournaments" ? "active" : ""}`} id="tournaments">
                    <button className={`menu-item-link menu-item-toggle custom_edit_menu ${openMenus["tournaments"] && path === "/tournaments" ? "active" : ""}`} onClick={() => toggleSubMenu("tournaments")}>
                      <div className="menu-item-icon">
                        <img src={tournamentsIcon} alt="tournaments" className="default" />
                        <img src={tournamentsIcon} alt="tournaments" className="active_img" />
                      </div>
                      <div className="df_menu_block">
                        <Link to="/tournaments?is_active=1" className={`menu-item-text ${path === "/tournaments" ? "text-white" : ""}`}>
                          {user_type === "admin" || user_type === "sub_admin" ? "Tournament" : "My Tournament"}
                        </Link>
                      </div>
                      <div className="menu-item-addon">
                        <i className="menu-item-caret caret"></i>
                      </div>
                    </button>
                    {/* <div className="df_menu_block">
                      <Link to="/tournaments" className="menu-item-text">
                        {user_type === "admin" || user_type === "sub_admin" ? "Tournament" : "My Tournament"}
                      </Link>
                    </div> */}

                    {/* BEGIN Menu Submenu */}
                    {(openMenus["tournaments"] && path === "/tournaments") &&
                      (
                        <div className="menu-submenu">
                          <div className="menu-item">
                            <Link to="/tournaments?is_active=1" className="menu-item-link">
                              <i className="menu-item-bullet"></i>
                              <span className="menu-item-text">Active Tournaments</span>
                            </Link>
                          </div>
                          <div className="menu-item">
                            <Link to="/tournaments?tournament_type=Featured" className="menu-item-link">
                              <i className="menu-item-bullet"></i>
                              <span className="menu-item-text">Featured Tournaments</span>
                            </Link>
                          </div>
                          <div className="menu-item">
                            <Link to="/tournaments?tournament_type=Daily" className="menu-item-link">
                              <i className="menu-item-bullet"></i>
                              <span className="menu-item-text">Daily Tournaments</span>
                            </Link>
                          </div>
                          <div className="menu-item">
                            <Link to="/tournaments?is_active=0" className="menu-item-link">
                              <i className="menu-item-bullet"></i>
                              <span className="menu-item-text">Inactive Tournaments</span>
                            </Link>
                          </div>
                        </div>
                      )
                    }
                    {/* END Menu Submenu */}
                  </div>
                )}

                {(user_type === "admin" || permissions?.["View Tournament Rooms"]) && (
                  <div className={`menu-item ${path === "/tournament-rooms" ? "active" : ""}`} id="tournament-rooms">
                    <button className={`menu-item-link menu-item-toggle custom_edit_menu ${openMenus["tournament-rooms"] && path === "/tournament-rooms" ? "active" : ""}`} onClick={() => toggleSubMenu("tournament-rooms")}>
                      <div className="menu-item-icon">
                        <img src={dashboardMIcon} alt="dashboard_m_svg" className="default" />
                        <img src={dashboardSelectIcon} alt="dashboard_select_svg" className="active_img" />
                      </div>
                      {/* <span className="menu-item-text">Tournament Rooms</span> */}
                      <div className="df_menu_block">
                        <Link to="/tournament-rooms?tournament=pending" className={`menu-item-text ${path === "/tournament-rooms" ? "text-white" : ""}`}>
                          Tournament Rooms
                        </Link>
                      </div>
                      <div className="menu-item-addon">
                        <i className="menu-item-caret caret"></i>
                      </div>
                    </button>
                    {/* <div className="df_menu_block">
                      <Link to="/tournament-rooms" className="menu-item-text">
                        Tournament Rooms
                      </Link>
                    </div> */}
                    {/* BEGIN Menu Submenu */}
                    {(openMenus["tournament-rooms"] && path === "/tournament-rooms") &&
                      (
                        <div className="menu-submenu">
                          <div className="menu-item">
                            <Link to="/tournament-rooms?tournament=pending" className="menu-item-link">
                              <i className="menu-item-bullet"></i>
                              <span className="menu-item-text">Upcoming Match</span>
                            </Link>
                          </div>
                          <div className="menu-item">
                            <Link to="/tournament-rooms?tournament=ongoing" className="menu-item-link">
                              <i className="menu-item-bullet"></i>
                              <span className="menu-item-text">Ongoing Match</span>
                            </Link>
                          </div>
                          <div className="menu-item">
                            <Link to="/tournament-rooms?tournament=cancel" className="menu-item-link">
                              <i className="menu-item-bullet"></i>
                              <span className="menu-item-text">Cancelled Match</span>
                            </Link>
                          </div>
                          <div className="menu-item">
                            <Link to="/tournament-rooms?tournament=completed" className="menu-item-link">
                              <i className="menu-item-bullet"></i>
                              <span className="menu-item-text">Completed Match</span>
                            </Link>
                          </div>
                        </div>
                      )
                    }
                    {/* END Menu Submenu */}
                  </div>
                )}
              </>
            )}


            {(user_type === "admin" || permissions?.["View Product"] || permissions?.["View Orders"] || permissions?.["View Product Category"]) && (
              <>
                {/* BEGIN Menu Section */}
                <div className="menu-section">
                  <div className="menu-section-icon">
                    <i className="fa fa-ellipsis-h"></i>
                  </div>
                  <h2 className="menu-section-text">Bonus Control</h2>
                </div>
                {/* END Menu Section */}

                {(user_type === "admin" || permissions?.["View Product"]) && (
                  <div className={`menu-item ${path === "/products" ? "active" : ""}`} id="products">
                    <button className={`menu-item-link menu-item-toggle custom_edit_menu ${openMenus["products"] && path === "/products" ? "active" : ""}`} onClick={() => toggleSubMenu("products")}>
                      <div className="menu-item-icon">
                        <img src={bonusIcon} alt="bonus_svg" className="default" />
                        <img src={bonusIcon} alt="bonus_svg" className="active_img" />
                      </div>
                      <div className="df_menu_block">
                        <Link to="/products?is_active=1" className={`menu-item-text ${path === "/products" ? "text-white" : ""}`}>
                          Bonus Products
                        </Link>
                      </div>
                      <div className="menu-item-addon">
                        <i className="menu-item-caret caret"></i>
                      </div>
                    </button>
                    {/* <div className="df_menu_block">
                      <Link to="/products" className="menu-item-text">
                        Products
                      </Link>
                    </div> */}

                    {/* BEGIN Menu Submenu */}
                    {(openMenus["products"] && path === "/products") &&
                      (
                        <div className="menu-submenu">
                          <div className="menu-item">
                            <Link to="/products?is_active=1" className="menu-item-link">
                              <i className="menu-item-bullet"></i>
                              <span className="menu-item-text">Active Products</span>
                            </Link>
                          </div>
                          <div className="menu-item">
                            <Link to="/products?is_active=0" className="menu-item-link">
                              <i className="menu-item-bullet"></i>
                              <span className="menu-item-text">Inactive Products</span>
                            </Link>
                          </div>
                        </div>
                      )
                    }
                    {/* END Menu Submenu */}
                  </div>
                )}

                {(user_type === "admin" || permissions?.["View Orders"]) && (
                  <div className={`menu-item ${path === "/orders" ? "active" : ""}`} id="orders">
                    <button className={`menu-item-link menu-item-toggle custom_edit_menu ${openMenus["orders"] && path === "/orders" ? "active" : ""}`} onClick={() => toggleSubMenu("orders")}>
                      <div className="menu-item-icon">
                        <img src={cartIcon} alt="cart_svg" className="default" />
                        <img src={cartIcon} alt="cart_svg" className="active_img" />
                      </div>
                      <div className="df_menu_block">
                        <Link to="/orders?status=pending" className={`menu-item-text ${path === "/orders" ? "text-white" : ""}`}>
                          Redeem Orders
                        </Link>
                      </div>
                      <div className="menu-item-addon">
                        <i className="menu-item-caret caret"></i>
                      </div>
                    </button>
                    {/* <div className="df_menu_block">
                      <Link to="/orders" className="menu-item-text">
                        Orders
                      </Link>
                    </div> */}

                    {/* BEGIN Menu Submenu */}
                    {(openMenus["orders"] && path === "/orders") &&
                      (
                        <div className="menu-submenu">
                          <div className="menu-item">
                            <Link to="/orders?status=pending" className="menu-item-link">
                              <i className="menu-item-bullet"></i>
                              <span className="menu-item-text">Pending Orders</span>
                            </Link>
                          </div>
                          <div className="menu-item">
                            <Link to="/orders?status=accepted" className="menu-item-link">
                              <i className="menu-item-bullet"></i>
                              <span className="menu-item-text">Accepted Orders</span>
                            </Link>
                          </div>
                          <div className="menu-item">
                            <Link to="/orders?status=dispatch" className="menu-item-link">
                              <i className="menu-item-bullet"></i>
                              <span className="menu-item-text">Dispatched Orders</span>
                            </Link>
                          </div>
                          <div className="menu-item">
                            <Link to="/orders?status=delivered" className="menu-item-link">
                              <i className="menu-item-bullet"></i>
                              <span className="menu-item-text">Delivered Orders</span>
                            </Link>
                          </div>
                          <div className="menu-item">
                            <Link to="/orders?status=rejected" className="menu-item-link">
                              <i className="menu-item-bullet"></i>
                              <span className="menu-item-text">Rejected Orders</span>
                            </Link>
                          </div>
                        </div>
                      )
                    }
                    {/* END Menu Submenu */}
                  </div>
                )}

                {(user_type === "admin" || permissions?.["View Product Category"]) && (
                  <div className={`menu-item ${path === "/product-category" ? "active" : ""}`}>
                    <Link to="/product-category" className="menu-item-link" onClick={() => toggleSubMenu("product-category")}>
                      <div className="menu-item-icon">
                        {/* <i className="fa fa-chart-pie"></i> */}
                        <img src={noteIcon} alt="note_svg" className="default" />
                        <img src={noteIcon} alt="note_svg" className="active_img" />
                      </div>
                      <span className="menu-item-text">Product Category</span>
                    </Link>
                  </div>
                )}
              </>
            )}


            {(user_type === "admin" || permissions?.["View Sub Admin"]) && (
              <>
                {/* BEGIN Menu Section */}
                <div className="menu-section">
                  <div className="menu-section-icon">
                    <i className="fa fa-ellipsis-h"></i>
                  </div>
                  <h2 className="menu-section-text">Employee Control</h2>
                </div>
                {/* END Menu Section */}

                {(user_type === "admin" || permissions?.["View Sub Admin"]) && (
                  <div className={`menu-item ${path === "/sub-admin" ? "active" : ""}`} id="sub-admin">
                    <button className={`menu-item-link menu-item-toggle custom_edit_menu ${openMenus["sub-admin"] && path === "/sub-admin" ? "active" : ""}`} onClick={() => toggleSubMenu("sub-admin")}>
                      <div className="menu-item-icon">
                        <img src={subAdminIcon} alt="sub-admin" className="default" />
                        <img src={subAdminIcon} alt="sub-admin" className="active_img" />
                      </div>
                      <div className="df_menu_block">
                        <Link to="/sub-admin?is_active=1" className={`menu-item-text ${path === "/sub-admin" ? "text-white" : ""}`}>
                          Sub admin
                        </Link>
                      </div>
                      <div className="menu-item-addon">
                        <i className="menu-item-caret caret"></i>
                      </div>
                    </button>
                    {/* <div className="df_menu_block">
                      <Link to="/sub-admin" className="menu-item-text">
                        Sub admin
                      </Link>
                    </div> */}
                    {/* BEGIN Menu Submenu */}
                    {(openMenus["sub-admin"] && path === "/sub-admin") &&
                      (
                        <div className="menu-submenu">
                          <div className="menu-item">
                            <Link to="/sub-admin?is_active=1" className="menu-item-link">
                              <i className="menu-item-bullet"></i>
                              <span className="menu-item-text">Active Admins</span>
                            </Link>
                          </div>
                          <div className="menu-item">
                            <Link to="/sub-admin?is_active=0" className="menu-item-link">
                              <i className="menu-item-bullet"></i>
                              <span className="menu-item-text">Inactive Admins</span>
                            </Link>
                          </div>
                          {/* <div className="menu-item">
                        <Link to="/sub-admin?suspended" className="menu-item-link">
                          <i className="menu-item-bullet"></i>
                          <span className="menu-item-text">Suspended Admins</span>
                        </Link>
                      </div> */}
                        </div>
                      )
                    }
                    {/* END Menu Submenu */}
                  </div>
                )}

                {user_type === "admin" /* || permissions?.["View Staff Permission"] */ && (
                  <div className={`menu-item ${path === "/groups/permission-groups" ? "active" : ""}`}>
                    <Link to="/groups/permission-groups" className="menu-item-link" onClick={() => toggleSubMenu("permission-groups")}>
                      <div className="menu-item-icon">
                        {/* <i className="fa fa-chart-pie"></i> */}
                        <img src={permissionGroupIcon} alt="permission_g_svg" className="default" />
                        <img src={permissionGroupIcon} alt="permission_g_svg" className="active_img" />
                      </div>
                      <span className="menu-item-text">Permission Group</span>
                    </Link>
                  </div>
                )}
              </>
            )}


            {(user_type === "admin" || permissions?.["View Private Organizers"] || permissions?.["View Private Organizers Dispute"]) && (
              <>
                {/* BEGIN Menu Section */}
                <div className="menu-section">
                  <div className="menu-section-icon">
                    <i className="fa fa-ellipsis-h"></i>
                  </div>
                  <h2 className="menu-section-text">Private Organizers Section</h2>
                </div>
                {/* END Menu Section */}
    
                {(user_type === "admin" || permissions?.["View Private Organizers"]) && (
                  <div className={`menu-item ${path === "/private-organizers" ? "active" : ""}`}>
                    <Link to="/private-organizers" className="menu-item-link" onClick={() => toggleSubMenu("private-organizers")}>
                      <div className="menu-item-icon">
                        <img src={subAdminIcon} alt="sub-admin-svg" className="default" />
                        <img src={subAdminIcon} alt="sub-admin-svg" className="active_img" />
                      </div>
                      <span className="menu-item-text">Private Organizers</span>
                    </Link>
                  </div>
                )}
    
                {(user_type === "admin" || permissions?.["View Private Organizers Dispute"]) && (
                  <div className={`menu-item ${path === "/private-orgs-dispute" ? "active" : ""}`}>
                    <Link to="/private-orgs-dispute" className="menu-item-link" onClick={() => toggleSubMenu("private-orgs-dispute")}>
                      <div className="menu-item-icon">
                        <img src={privateOrgDIcon} alt="private_org_d_svg" className="default" />
                        <img src={privateOrgDIcon} alt="private_org_d_svg" className="active_img" />
                      </div>
                      <span className="menu-item-text">Private Org’s Dispute</span>
                    </Link>
                  </div>
                )}
              </>
            )}


            {(user_type === "admin" || user_type === "organizer_admin" || permissions?.["View Payments"] || permissions?.["View Withdraw Money"] /* || permissions?.["View Add Money"] || permissions?.["View Paypal Money"] */) && (
              <>
                {/* BEGIN Menu Section */}
                <div className="menu-section">
                  <div className="menu-section-icon">
                    <i className="fa fa-ellipsis-h"></i>
                  </div>
                  <h2 className="menu-section-text">Payment & Withdrawal Section</h2>
                </div>
                {/* END Menu Section */}

                {(user_type === "admin" || permissions?.["View Payments"]) && (
                  <div className={`menu-item ${path === "/payments" ? "active" : ""}`}>
                    <Link to="/payments" className={`menu-item-link ${path === "/payments" ? "active" : ""}`} onClick={() => toggleSubMenu("payments")}>
                      <div className="menu-item-icon">
                        {/* <i className="fa fa-chart-pie"></i> */}
                        <img src={paymentsIcon} alt="payments" className="default" />
                        <img src={paymentsIcon} alt="payments" className="active_img" />
                      </div>
                      <span className="menu-item-text">Payments</span>
                    </Link>
                  </div>
                )}

                {user_type === "organizer_admin" && (
                  <div className={`menu-item ${path === "/add-money" ? "active" : ""}`}>
                    <Link to="/add-money" className={`menu-item-link ${path === "/add-money" ? "active" : ""}`} onClick={() => toggleSubMenu("add-money")}>
                      <div className="menu-item-icon">
                        {/* <i className="fa fa-chart-pie"></i> */}
                        <img src={addMoneyIcon} alt="addmoney" className="default" />
                        <img src={addMoneyIcon} alt="addmoney" className="active_img" />
                      </div>
                      <span className="menu-item-text">Add Money</span>
                    </Link>
                  </div>
                )}

                {(user_type === "admin" || permissions?.["View Withdraw Money"]) && (
                  <div className={`menu-item ${path === "/withdraw-money" ? "active" : ""}`}>
                    <Link to="/withdraw-money?all" className="menu-item-link" onClick={() => toggleSubMenu("withdraw-money")}>
                      <div className="menu-item-icon">
                        {/* <i className="fa fa-chart-pie"></i> */}
                        <img src={withdrawIcon} alt="withdraw" className="default" />
                        <img src={withdrawIcon} alt="withdraw" className="active_img" />
                      </div>
                      <span className="menu-item-text">Withdraw Money</span>
                    </Link>
                  </div>
                )}

                {/* {(user_type === "admin" || permissions?.["View Add Money"]) && (
                  <div className={`menu-item ${path === "/add-money" ? "active" : ""}`} id="add-money">
                    <Link to="/add-money" className={`menu-item-link menu-item-toggle ${openMenus["add-money"] && path === "/add-money" ? "active" : ""}`} onClick={() => toggleSubMenu("add-money")}>
                      <div className="menu-item-icon">
                        <img src={addMoneyIcon} alt="addmoney" className="default" />
                        <img src={addMoneyIcon} alt="addmoney" className="active_img" />
                      </div>
                      <span className="menu-item-text">Add Money </span>
                      <div className="menu-item-addon">
                        <i className="menu-item-caret caret"></i>
                      </div>
                    </Link>

                    {(openMenus["add-money"] && path === "/add-money") &&
                      (
                        <div className="menu-submenu">
                          <div className="menu-item">
                            <a href="pages/register/register-1.html" data-menu-path="/pages/register/register-1.html" className="menu-item-link">
                              <i className="menu-item-bullet"></i>
                              <span className="menu-item-text">Register 1</span>
                            </a>
                          </div>
                          <div className="menu-item">
                            <a href="pages/register/register-2.html" data-menu-path="/pages/register/register-2.html" className="menu-item-link">
                              <i className="menu-item-bullet"></i>
                              <span className="menu-item-text">Register 2</span>
                            </a>
                          </div>
                        </div>
                      )
                    }
                  </div>
                )}

                {(user_type === "admin" || permissions?.["View Paypal Money"]) && (
                  <div className={`menu-item ${path === "/paypal" ? "active" : ""}`} id="paypal">
                    <button className={`menu-item-link menu-item-toggle custom_edit_menu ${openMenus["paypal"] && path === "/paypal" ? "active" : ""}`} onClick={() => toggleSubMenu("paypal")}>
                      <div className="menu-item-icon">
                        <img src={paypalMIcon} alt="paypal_m_svg" className="default" />
                        <img src={paypalMIcon} alt="paypal_m_svg" className="active_img" />
                      </div>
                      <div className="menu-item-addon">
                        <i className="menu-item-caret caret"></i>
                      </div>
                    </button>
                    <div className="df_menu_block">
                      <Link to="/paypal" className="menu-item-text">
                        Paypal Money
                      </Link>
                    </div>

                    {(openMenus["paypal"] && path === "/paypal") &&
                      (
                        <div className="menu-submenu">
                          <div className="menu-item">
                            <Link to="/" className="menu-item-link">
                              <i className="menu-item-bullet"></i>
                              <span className="menu-item-text">Error 1</span>
                            </Link>
                          </div>
                          <div className="menu-item">
                            <Link to="/" className="menu-item-link">
                              <i className="menu-item-bullet"></i>
                              <span className="menu-item-text">Error 2</span>
                            </Link>
                          </div>
                        </div>
                      )
                    }
                  </div>
                )} */}
              </>
            )}


            {(user_type === "admin" || permissions?.["View Gold Subscriptions Lists"] || permissions?.["View Pro Verification List"] || permissions?.["View Spotlight message"]) && (
              <>
                {/* BEGIN Menu Section */}
                <div className="menu-section">
                  <div className="menu-section-icon">
                    <i className="fa fa-ellipsis-h"></i>
                  </div>
                  <h2 className="menu-section-text">Membership Control</h2>
                </div>
                {/* END Menu Section */}
    
                {(user_type === "admin" || permissions?.["View Gold Subscriptions Lists"]) && (
                  <div className={`menu-item ${path === "/gold-subscriptions" ? "active" : ""}`}>
                    <Link to="/gold-subscriptions" className="menu-item-link" onClick={() => toggleSubMenu("gold-subscriptions")}>
                      <div className="menu-item-icon">
                        {/* <i className="fa fa-chart-pie"></i> */}
                        <img src={goldPremiumIcon} alt="gold_premium_svg" className="default" />
                        <img src={goldPremiumIcon} alt="gold_premium_svg" className="active_img" />
                      </div>
                      <span className="menu-item-text">Gold Premium</span>
                    </Link>
                  </div>
                )}
    
                {(user_type === "admin" || permissions?.["View Pro Verification List"]) && (
                  <div className={`menu-item ${path === "/pro-verification-requests" ? "active" : ""}`}>
                    <Link to="/pro-verification-requests" className="menu-item-link" onClick={() => toggleSubMenu("pro-verification-requests")}>
                      <div className="menu-item-icon">
                        {/* <i className="fa fa-chart-pie"></i> */}
                        <img src={proMembershipIcon} alt="pro-membership-svg" className="default" />
                        <img src={proMembershipIcon} alt="pro-membership-svg" className="active_img" />
                      </div>
                      <span className="menu-item-text">Pro Memberships</span>
                    </Link>
                  </div>
                )}
    
                {(user_type === "admin" || permissions?.["View Spotlight message"]) && (
                  <div className={`menu-item ${path === "/spotlight-message" ? "active" : ""}`} id="spotlight-message">
                    <button className={`menu-item-link menu-item-toggle custom_edit_menu ${openMenus["spotlight-message"] && path === "/spotlight-message" ? "active" : ""}`} onClick={() => toggleSubMenu("spotlight-message")}>
                      <div className="menu-item-icon">
                        <img src={spotlightMsgIcon} alt="spotlight_msg_svg" className="default" />
                        <img src={spotlightMsgIcon} alt="spotlight_msg_svg" className="active_img" />
                      </div>
                      {/* <span className="menu-item-text">Spotlight Message</span> */}
                      <div className="df_menu_block">
                        <Link to="/spotlight-message?status=pending" className={`menu-item-text ${path === "/spotlight-message" ? "text-white" : ""}`}>
                          SpotLight
                        </Link>
                      </div>
                      <div className="menu-item-addon">
                        <i className="menu-item-caret caret"></i>
                      </div>
                    </button>
                    {/* <div className="df_menu_block">
                      <Link to="/spotlight-message" className="menu-item-text">
                        Spotlight Message
                      </Link>
                    </div> */}
                    {/* BEGIN Menu Submenu */}
                    {(openMenus["spotlight-message"] && path === "/spotlight-message") &&
                      (
                        <div className="menu-submenu">
                          <div className="menu-item">
                            <Link to="/spotlight-message?status=pending" className="menu-item-link">
                              <i className="menu-item-bullet"></i>
                              <span className="menu-item-text">Pending</span>
                            </Link>
                          </div>
                          <div className="menu-item">
                            <Link to="/spotlight-message?status=approved" className="menu-item-link">
                              <i className="menu-item-bullet"></i>
                              <span className="menu-item-text">Approved</span>
                            </Link>
                          </div>
                          <div className="menu-item">
                            <Link to="/spotlight-message?status=ongoing" className="menu-item-link">
                              <i className="menu-item-bullet"></i>
                              <span className="menu-item-text">Ongoing</span>
                            </Link>
                          </div>
                          <div className="menu-item">
                            <Link to="/spotlight-message?status=stop" className="menu-item-link">
                              <i className="menu-item-bullet"></i>
                              <span className="menu-item-text">Stopped</span>
                            </Link>
                          </div>
                          <div className="menu-item">
                            <Link to="/spotlight-message?status=completed" className="menu-item-link">
                              <i className="menu-item-bullet"></i>
                              <span className="menu-item-text">Completed</span>
                            </Link>
                          </div>
                          <div className="menu-item">
                            <Link to="/spotlight-message?status=rejected" className="menu-item-link">
                              <i className="menu-item-bullet"></i>
                              <span className="menu-item-text">Rejected</span>
                            </Link>
                          </div>
                        </div>
                      )
                    }
                    {/* END Menu Submenu */}
                  </div>
                )}
              </>
            )}


            {(user_type === "admin" || permissions?.["View User"] || permissions?.["View Post"] || permissions?.["View Verification Badge"] || permissions?.["View Kyc"] || permissions?.["View Blocked Devides"] || permissions?.["View Blocked Users IP"]) && (
              <>
                {/* BEGIN Menu Section */}
                <div className="menu-section">
                  <div className="menu-section-icon">
                    <i className="fa fa-ellipsis-h"></i>
                  </div>
                  <h2 className="menu-section-text">User's Control</h2>
                </div>
                {/* END Menu Section */}
    
                {(user_type === "admin" || permissions?.["View User"]) && (
                  <div className={`menu-item ${path === "/users" ? "active" : ""}`} id="users">
                    <button className={`menu-item-link menu-item-toggle custom_edit_menu ${openMenus["users"] && path === "/users" ? "active" : ""}`} onClick={() => toggleSubMenu("users")}>
                      <div className="menu-item-icon">
                        <img src={userIcon} alt="user" className="default" />
                        <img src={userIcon} alt="user" className="active_img" />
                      </div>
                      <div className="df_menu_block">
                        <Link to="/users?is_active=1" className={`menu-item-text ${path === "/users" ? "text-white" : ""}`}>
                          {user_type === "admin" || user_type === "sub_admin" ? "Users" : "Your Played Users"}
                        </Link>
                      </div>
                      <div className="menu-item-addon">
                        <i className="menu-item-caret caret"></i>
                      </div>
                    </button>
                    {/* <div className="df_menu_block">
                      <Link to="/users" className="menu-item-text">
                        {user_type === "admin" || user_type === "sub_admin" ? "Users" : "Your Played Users"}
                      </Link>
                    </div> */}
                    {/* BEGIN Menu Submenu */}
                    {(openMenus["users"] && path === "/users") &&
                      (
                        <div className="menu-submenu">
                          <div className="menu-item">
                            <Link to="/users" className="menu-item-link">
                              <i className="menu-item-bullet"></i>
                              <span className="menu-item-text">Total Users</span>
                            </Link>
                          </div>
                          <div className="menu-item">
                            <Link to="/users?is_active=1" className="menu-item-link">
                              <i className="menu-item-bullet"></i>
                              <span className="menu-item-text">Active Users</span>
                            </Link>
                          </div>
                          <div className="menu-item">
                            <Link to="/users?badage_request=accept" className="menu-item-link">
                              <i className="menu-item-bullet"></i>
                              <span className="menu-item-text">Pro Users</span>
                            </Link>
                          </div>
                          <div className="menu-item">
                            <Link to="/users?is_gold_membership=1" className="menu-item-link">
                              <i className="menu-item-bullet"></i>
                              <span className="menu-item-text">Gold Users</span>
                            </Link>
                          </div>
                          {/* <div className="menu-item">
                            <Link to="/users?is_online=1" className="menu-item-link">
                              <i className="menu-item-bullet"></i>
                              <span className="menu-item-text">Online Users</span>
                            </Link>
                          </div> */}
                          <div className="menu-item">
                            <Link to="/users?block=1" className="menu-item-link">
                              <i className="menu-item-bullet"></i>
                              <span className="menu-item-text">Blocked Users</span>
                            </Link>
                          </div>
                          {/* <div className="menu-item">
                            <Link to="/users?auto-blocked" className="menu-item-link">
                              <i className="menu-item-bullet"></i>
                              <span className="menu-item-text">Auto Blocked Users</span>
                            </Link>
                          </div> */}
                          <div className="menu-item">
                            <Link to="/users?is_surveillance=1" className="menu-item-link">
                              <i className="menu-item-bullet"></i>
                              <span className="menu-item-text">In surveillance</span>
                            </Link>
                          </div>
                        </div>
                      )
                    }
                    {/* END Menu Submenu */}
                  </div>
                )}
    
                {(user_type === "admin" || permissions?.["View Post"]) && (
                  <div className={`menu-item ${path === "/post" ? "active" : ""}`}>
                    <Link to="/post?is_active=1" className="menu-item-link" onClick={() => toggleSubMenu("post")}>
                      <div className="menu-item-icon">
                        {/* <i className="fa fa-chart-pie"></i> */}
                        <img src={allPostIcon} alt="all_post_svg" className="default" />
                        <img src={allPostIcon} alt="all_post_svg" className="active_img" />
                      </div>
                      <span className="menu-item-text">All Post</span>
                    </Link>
                  </div>
                )}
    
                {(user_type === "admin" || permissions?.["View Verification Badge"]) && (
                  <div className={`menu-item ${path === "/verification-badge" ? "active" : ""}`}>
                    <Link to="/verification-badge" className="menu-item-link" onClick={() => toggleSubMenu("verification-badge")}>
                      <div className="menu-item-icon">
                        {/* <i className="fa fa-chart-pie"></i> */}
                        <img src={ProBadgeIcon} alt="pro_badge_svg" className="default" />
                        <img src={ProBadgeIcon} alt="pro_badge_svg" className="active_img" />
                      </div>
                      <span className="menu-item-text">Verification Badge</span>
                    </Link>
                  </div>
                )}
    
                {(user_type === "admin" || permissions?.["View Kyc"]) && (
                  <div className={`menu-item ${path === "/kyc-verified-users" ? "active" : ""}`}>
                    <Link to="/kyc-verified-users" className="menu-item-link" onClick={() => toggleSubMenu("kyc-verified-users")}>
                      <div className="menu-item-icon">
                        {/* <i className="fa fa-chart-pie"></i> */}
                        <img src={verificationIcon} alt="verification_svg" className="default" />
                        <img src={verificationIcon} alt="verification_svg" className="active_img" />
                      </div>
                      <span className="menu-item-text">KYC Verified Users</span>
                    </Link>
                  </div>
                )}
    
                {(user_type === "admin" || permissions?.["View Blocked Devides"]) && (
                  <div className={`menu-item ${path === "/blocked-devices" ? "active" : ""}`}>
                    <Link to="/blocked-devices" className="menu-item-link" onClick={() => toggleSubMenu("blocked-devices")}>
                      <div className="menu-item-icon">
                        {/* <i className="fa fa-chart-pie"></i> */}
                        <img src={deviceIcon} alt="device_svg" className="default" />
                        <img src={deviceIcon} alt="device_svg" className="active_img" />
                      </div>
                      <span className="menu-item-text">Blocked Device</span>
                    </Link>
                  </div>
                )}
    
                {(user_type === "admin" || permissions?.["View Blocked Users IP"]) && (
                  <div className={`menu-item ${path === "/blocked-ips" ? "active" : ""}`}>
                    <Link to="/blocked-ips" className="menu-item-link" onClick={() => toggleSubMenu("blocked-ips")}>
                      <div className="menu-item-icon">
                        {/* <i className="fa fa-chart-pie"></i> */}
                        <img src={blockIpIcon} alt="blocked-ip-svg" className="default" />
                        <img src={blockIpIcon} alt="blocked-ip-svg" className="active_img" />
                      </div>
                      <span className="menu-item-text">Blocked User's IP</span>
                    </Link>
                  </div>
                )}

                {user_type === "admin" && (
                  <div className={`menu-item ${path === "/cash-to-winning" ? "active" : ""}`}>
                    <Link to="/cash-to-winning" className="menu-item-link" onClick={() => toggleSubMenu("cash-to-winning")}>
                      <div className="menu-item-icon">
                        {/* <i className="fa fa-chart-pie"></i> */}
                        <img src={winningCupIcon} alt="cash-to-winning-svg" className="default" />
                        <img src={winningCupIcon} alt="cash-to-winning-svg" className="active_img" />
                      </div>
                      <span className="menu-item-text">Cash Into Winning</span>
                    </Link>
                  </div>
                )}
              </>
            )}


            {(user_type === "admin" || permissions?.["View Live Chat Support"] || permissions?.["View Ticket Support"] || permissions?.["View Customers Dispute"]) && (
              <>
                {/* BEGIN Menu Section */}
                <div className="menu-section">
                  <div className="menu-section-icon">
                    <i className="fa fa-ellipsis-h"></i>
                  </div>
                  <h2 className="menu-section-text">Support Sections</h2>
                </div>
                {/* END Menu Section */}
    
                {(user_type === "admin" || permissions?.["View Live Chat Support"]) && (
                  <div className={`menu-item ${path === "/live-chat-support" ? "active" : ""}`}>
                    <Link to="/live-chat-support" className="menu-item-link" onClick={() => toggleSubMenu("live-chat-support")}>
                      <div className="menu-item-icon">
                        {/* <i className="fa fa-chart-pie"></i> */}
                        <img src={supportIcon} alt="support" className="default" />
                        <img src={supportIcon} alt="support" className="active_img" />
                      </div>
                      <span className="menu-item-text">Live Chat Supoort</span>
                    </Link>
                  </div>
                )}
    
                {(user_type === "admin" || permissions?.["View Ticket Support"]) && (
                  <div className={`menu-item ${path === "/ticket-support" ? "active" : ""}`}>
                    <Link to="/ticket-support?all" className="menu-item-link" onClick={() => toggleSubMenu("ticket-support")}>
                      <div className="menu-item-icon">
                        {/* <i className="fa fa-chart-pie"></i> */}
                        <img src={contactUsIcon} alt="contactus_svg" className="default" />
                        <img src={contactUsIcon} alt="contactus_svg" className="active_img" />
                      </div>
                      <span className="menu-item-text">Ticket Support</span>
                    </Link>
                  </div>
                )}
    
                {(user_type === "admin" || permissions?.["View Customers Dispute"]) && (
                  <div className={`menu-item ${path === "/customers-dispute" ? "active" : ""}`}>
                    <Link to="/customers-dispute" className="menu-item-link" onClick={() => toggleSubMenu("customers-dispute")}>
                      <div className="menu-item-icon">
                        <img src={privateOrgDIcon} alt="private_org_svg" className="default" />
                        <img src={privateOrgDIcon} alt="private_org_svg" className="active_img" />
                      </div>
                      <span className="menu-item-text">Customers Dispute</span>
                    </Link>
                  </div>
                )}
              </>
            )}


            {(user_type === "admin" || permissions?.["View Report Post"] || permissions?.["View Report Chat"] || permissions?.["View Report Users"]) && (
              <>
                {/* BEGIN Menu Section */}
                <div className="menu-section">
                  <div className="menu-section-icon">
                    <i className="fa fa-ellipsis-h"></i>
                  </div>
                  <h2 className="menu-section-text">Flag Control</h2>
                </div>
                {/* END Menu Section */}

                {(user_type === "admin" || permissions?.["View Report Post"]) && (
                  <div className={`menu-item ${path === "/report-post" ? "active" : ""}`}>
                    <Link to="/report-post?report_status=pending" className="menu-item-link" onClick={() => toggleSubMenu("report-post")}>
                      <div className="menu-item-icon">
                        {/* <i className="fa fa-chart-pie"></i> */}
                        <img src={reportPostIcon} alt="report_post_svg" className="default" />
                        <img src={reportPostIcon} alt="report_post_svg" className="active_img" />
                      </div>
                      <span className="menu-item-text">Report Against Post</span>
                    </Link>
                  </div>
                )}

                {(user_type === "admin" || permissions?.["View Report Chat"]) && (
                  <div className={`menu-item ${path === "/report-chat" ? "active" : ""}`}>
                    <Link to="/report-chat" className="menu-item-link" onClick={() => toggleSubMenu("report-chat")}>
                      <div className="menu-item-icon">
                        {/* <i className="fa fa-chart-pie"></i> */}
                        <img src={reportChatIcon} alt="report_chat_svg" className="default" />
                        <img src={reportChatIcon} alt="report_chat_svg" className="active_img" />
                      </div>
                      <span className="menu-item-text">Report Against Messages</span>
                    </Link>
                  </div>
                )}

                {(user_type === "admin" || permissions?.["View Report Users"]) && (
                  <div className={`menu-item ${path === "/report-users" ? "active" : ""}`}>
                    <Link to="/report-users?report_status=pending" className="menu-item-link" onClick={() => toggleSubMenu("report-users")}>
                      <div className="menu-item-icon">
                        {/* <i className="fa fa-chart-pie"></i> */}
                        <img src={subAdminIcon} alt="subadmin_svg" className="default" />
                        <img src={subAdminIcon} alt="subadmin_svg" className="active_img" />
                      </div>
                      <span className="menu-item-text">Tournament User Report</span>
                    </Link>
                  </div>
                )}
              </>
            )}


            {(user_type === "admin" || permissions?.["View Push Notifications"] || permissions?.["View Email Marketing"]) && (
              <>
                {/* BEGIN Menu Section */}
                <div className="menu-section">
                  <div className="menu-section-icon">
                    <i className="fa fa-ellipsis-h"></i>
                  </div>
                  <h2 className="menu-section-text">Notification & Marketing Section</h2>
                </div>
                {/* END Menu Section */}

                {(user_type === "admin" || permissions?.["View Push Notifications"]) && (
                  <div className={`menu-item ${path === "/push-notifications" ? "active" : ""}`}>
                    <Link to="/push-notifications" className="menu-item-link" onClick={() => toggleSubMenu("push-notifications")}>
                      <div className="menu-item-icon">
                        {/* <i className="fa fa-chart-pie"></i> */}
                        <img src={pushNotificationIcon} alt="push_notification_svg" className="default" />
                        <img src={pushNotificationIcon} alt="push_notification_svg" className="active_img" />
                      </div>
                      <span className="menu-item-text">Push Notifications</span>
                    </Link>
                  </div>
                )}

                {(user_type === "admin" || permissions?.["View Email Marketing"]) && (
                  <div className={`menu-item ${path === "/email-marketing" ? "active" : ""}`}>
                    <Link to="/email-marketing" className="menu-item-link" onClick={() => toggleSubMenu("email-marketing")}>
                      <div className="menu-item-icon">
                        {/* <i className="fa fa-chart-pie"></i> */}
                        <img src={emailIcon} alt="email_svg" className="default" />
                        <img src={emailIcon} alt="email_svg" className="active_img" />
                      </div>
                      <span className="menu-item-text">Email Marketing</span>
                    </Link>
                  </div>
                )}
              </>
            )}


            {/* {(user_type === "admin" || permissions?.["View Chat"]) && (
              <div className={`menu-item ${((path === "/groups" && (path2 === '/undefined' || path2 === "/chat-groups" || path2 === '/world-groups')) || path === "/personal-chat") ? "active" : ""}`} id="groups">
                <button className={`menu-item-link menu-item-toggle custom_edit_menu ${openMenus["groups"] && ((path === "/groups" && (path2 === '/undefined' || path2 === "/chat-groups" || path2 === '/world-groups')) || path === "/personal-chat") ? "active" : ""}`} onClick={() => toggleSubMenu("groups")}>
                  <div className="menu-item-icon">
                    <img src={groupMIcon} alt="groups_m_svg" className="default" />
                    <img src={groupMIcon} alt="groups_m_svg" className="active_img" />
                  </div>
                  <Link to="/groups/chat-groups" className={`menu-item-text ${((path === "/groups" && (path2 === '/undefined' || path2 === "/chat-groups" || path2 === '/world-groups')) || path === "/personal-chat") ? "text-white": ""}`}>
                    <span className="menu-item-text">Groups</span>
                  </Link>
                  
                  <div className="menu-item-addon">
                    <i className="menu-item-caret caret"></i>
                  </div>
                </button>

                {(openMenus["groups"] && ((path === "/groups" && (path2 === '/undefined' || path2 === "/chat-groups" || path2 === '/world-groups')) || path === "/personal-chat")) &&
                  (
                    <div className="menu-submenu">
                      <div className="menu-item">
                        <Link to="/personal-chat" className="menu-item-link">
                          <i className="menu-item-bullet"></i>
                          <span className="menu-item-text">Personal Chat</span>
                        </Link>
                      </div>
                      <div className="menu-item">
                        <Link to="/groups/chat-groups" className="menu-item-link">
                          <i className="menu-item-bullet"></i>
                          <span className="menu-item-text">Group Chat</span>
                        </Link>
                      </div>
                      <div className="menu-item">
                        <Link to="/groups/world-groups" className="menu-item-link">
                          <i className="menu-item-bullet"></i>
                          <span className="menu-item-text">World Chat</span>
                        </Link>
                      </div>
                    </div>
                  )
                }
              </div>
            )} */}


            {(user_type === "admin" || permissions?.["View Chat"]) && (
              <>
                {/* BEGIN Menu Section */}
                <div className="menu-section">
                  <div className="menu-section-icon">
                    <i className="fa fa-ellipsis-h"></i>
                  </div>
                  <h2 className="menu-section-text">Messaging Section</h2>
                </div>
                {/* END Menu Section */}

                <div className={`menu-item ${path === "/personal-chat" ? "active" : ""}`}>
                  <Link to="/personal-chat" className="menu-item-link" onClick={() => toggleSubMenu("personal-chat")}>
                    <div className="menu-item-icon">
                      <img src={supportIcon} alt="personal_chat_svg" className="default" />
                      <img src={supportIcon} alt="personal_chat_svg" className="active_img" />
                    </div>
                    <span className="menu-item-text">Chat With Customers</span>
                  </Link>
                </div>

                <div className={`menu-item ${(path === "/groups" && path2 === '/chat-groups') ? "active" : ""}`}>
                  <Link to="/groups/chat-groups" className="menu-item-link" onClick={() => toggleSubMenu("groups-chat-groups")}>
                    <div className="menu-item-icon">
                      <img src={groupMIcon} alt="groups_m_svg" className="default" />
                      <img src={groupMIcon} alt="groups_m_svg" className="active_img" />
                    </div>
                    <span className="menu-item-text">Group Chat</span>
                  </Link>
                </div>

                <div className={`menu-item ${(path === "/groups" && path2 === '/world-groups') ? "active" : "groups-world-groups"}`}>
                  <Link to="/groups/world-groups" className="menu-item-link" onClick={() => toggleSubMenu("groups-chat-groups")}>
                    <div className="menu-item-icon">
                      <img src={worldChatIcon} alt="world_chat_svg" className="default" />
                      <img src={worldChatIcon} alt="world_chat_svg" className="active_img" />
                    </div>
                    <span className="menu-item-text">World Chat</span>
                  </Link>
                </div>
              </>
            )}


            {(user_type === "admin" || permissions?.["View Settings"] || permissions?.["View Reason Lists"] || permissions?.["View Business Reports"] || permissions?.["View Contact Us"] || permissions?.["View Countries"]) && (
                <>
                  {/* BEGIN Menu Section */}
                  <div className="menu-section">
                    <div className="menu-section-icon">
                      <i className="fa fa-ellipsis-h"></i>
                    </div>
                    <h2 className="menu-section-text">Static Section</h2>
                  </div>
                  {/* END Menu Section */}

                  {(user_type === "admin" || permissions?.["View Settings"]) && (
                    <div className={`menu-item ${path === "/app-settings" ? "active" : ""}`}>
                      <Link to="/app-settings" className="menu-item-link" onClick={() => toggleSubMenu("app-settings")}>
                        <div className="menu-item-icon">
                          <img src={supportIcon} alt="app-settings" className="default" />
                          <img src={supportIcon} alt="app-settings" className="active_img" />
                        </div>
                        <span className="menu-item-text">App Settings</span>
                      </Link>
                    </div>
                    // <div className={`menu-item ${path === "/app-settings" ? "active" : ""}`}>
                    //   <button className="menu-item-link menu-item-toggle">
                    //     <div className="menu-item-icon">
                    //       <img src={settingsIcon} alt="settings" className="default" />
                    //       <img src={dashboardSelectIcon} alt="dashboard_select_svg" className="active_img" />
                    //     </div>
                    //     <span className="menu-item-text">Settings</span>
                    //     <div className="menu-item-addon">
                    //       <i className="menu-item-caret caret"></i>
                    //     </div>
                    //   </button>
                    //   <div className="menu-submenu">
                    //     <div className="menu-item">
                    //       <Link to="/app-settings" className="menu-item-link">
                    //         <i className="menu-item-bullet"></i>
                    //         <span className="menu-item-text">App Settings</span>
                    //       </Link>
                    //     </div>
                    //     <div className="menu-item">
                    //       <Link to="/blocked-devices" className="menu-item-link">
                    //         <i className="menu-item-bullet"></i>
                    //         <span className="menu-item-text">Blocked Devices</span>
                    //       </Link>
                    //     </div>
                    //     <div className="menu-item">
                    //       <Link to="/blocked-ips" className="menu-item-link">
                    //         <i className="menu-item-bullet"></i>
                    //         <span className="menu-item-text">Blocked Users IP</span>
                    //       </Link>
                    //     </div>
                    //   </div>
                    // </div>
                  )}

                  {(user_type === "admin" || permissions?.["View Reason Lists"]) && (
                    <div className={`menu-item ${path === "/reason-lists" ? "active" : ""}`}>
                      <Link to="/reason-lists" className="menu-item-link" onClick={() => toggleSubMenu("reason-lists")}>
                        <div className="menu-item-icon">
                          {/* <i className="fa fa-chart-pie"></i> */}
                          <img src={groupMIcon} alt="group_m_svg" className="default" />
                          <img src={groupMIcon} alt="group_m_svg" className="active_img" />
                        </div>
                        <span className="menu-item-text">Reason Lists</span>
                      </Link>
                    </div>
                  )}

                  {/* <div className={`menu-item ${path === "/tournament-report" ? "active" : ""}`}>
                    <button className="menu-item-link" onClick={() => toggleSubMenu("tournament-report")}>
                      <div className="menu-item-icon">
                        <img src={tournamentsIcon} alt="tournaments" className="default" />
                        <img src={tournamentsIcon} alt="tournaments" className="active_img" />
                      </div>
                      <Link to="/tournament-report" className="menu-item-text">
                        Tournament Report
                      </Link>
                    </button>
                  </div> */}
                  
                  {(user_type === "admin" || permissions?.["View Business Reports"]) && (
                    <div className={`menu-item ${path === "/business-reports" ? "active" : ""}`}>
                      <Link to="/business-reports?report_type=user-report" className="menu-item-link" onClick={() => toggleSubMenu("business-reports")}>
                        <div className="menu-item-icon">
                          {/* <i className="fa fa-chart-pie"></i> */}
                          <img src={worldChatIcon} alt="world_chat_svg" className="default" />
                          <img src={worldChatIcon} alt="world_chat_svg" className="active_img" />
                        </div>
                        <span className="menu-item-text">Business Reports</span>
                      </Link>
                    </div>
                  )}

                  {/* {(user_type === "admin" || permissions?.["View Support"]) && (
                  <div className={`menu-item ${path === "/support" ? "active" : ""}`} onClick={() => toggleSubMenu("support")}>
                    <a href="/" className="menu-item-link">
                      <div className="menu-item-icon">
                        <img src={supportIcon} alt="support" className="default" />
                        <img src={supportIcon} alt="support" className="active_img" />
                      </div>
                      <span className="menu-item-text">Support</span>
                    </a>
                  </div>
                  )} */}

                  {/* {(user_type === "admin" || permissions?.["View Coupons"]) && (
                  <div className={`menu-item ${path === "/coupons" ? "active" : ""}`} id="coupons">
                    <button className={`menu-item-link menu-item-toggle custom_edit_menu ${openMenus["coupons"] && path === "/coupons" ? "active" : ""}`} onClick={() => toggleSubMenu("coupons")}>
                      <div className="menu-item-icon">
                        <img src={couponIcon} alt="coupon" className="default" />
                        <img src={couponIcon} alt="coupon" className="active_img" />
                      </div>
                      <span className="menu-item-text">Coupons</span>
                      <div className="menu-item-addon">
                        <i className="menu-item-caret caret"></i>
                      </div>
                    </button>

                    {(openMenus["coupons"] && path === "/coupons") &&
                      (
                        <div className="menu-submenu">
                          <div className="menu-item">
                            <a href="/" className="menu-item-link">
                              <i className="menu-item-bullet"></i>
                              <span className="menu-item-text">Error 1</span>
                            </a>
                          </div>
                          <div className="menu-item">
                            <a href="/" className="menu-item-link">
                              <i className="menu-item-bullet"></i>
                              <span className="menu-item-text">Error 2</span>
                            </a>
                          </div>
                        </div>
                      )
                    }
                  </div>
                  )} */}

                  {(user_type === "admin" || permissions?.["View Contact Us"]) && (
                    <div className={`menu-item ${path === "/contact-us" ? "active" : ""}`} id="contact-us">
                      <button className={`menu-item-link menu-item-toggle custom_edit_menu ${openMenus["contact-us"] && (path === "/contact-us" || path === "/non-indian-withdraw") ? "active" : ""}`} onClick={() => toggleSubMenu("contact-us")}>
                        <div className="menu-item-icon">
                          <img src={contactUsIcon} alt="contactus" className="default" />
                          <img src={contactUsIcon} alt="contactus" className="active_img" />
                        </div>
                        <div className="df_menu_block">
                          <Link to="/contact-us" className={`menu-item-text ${(path === '/contact-us' || path === "/non-indian-withdraw") ? "text-white": ""}`}>
                            <span className="menu-item-text">Contact Us</span>
                          </Link>
                        </div>
                        <div className="menu-item-addon">
                          <i className="menu-item-caret caret"></i>
                        </div>
                      </button>

                      {(openMenus["contact-us"] && (path === "/contact-us" || path === "/non-indian-withdraw")) &&
                        (
                          <div className="menu-submenu">
                            <div className="menu-item">
                              <Link to="/contact-us" className="menu-item-link">
                                <i className="menu-item-bullet"></i>
                                <span className="menu-item-text">Contact</span>
                              </Link>
                            </div>
                            <div className="menu-item">
                              <Link to="/non-indian-withdraw" className="menu-item-link">
                                <i className="menu-item-bullet"></i>
                                <span className="menu-item-text">Non Indian Withdraw List</span>
                              </Link>
                            </div>
                          </div>
                        )
                      }
                    </div>
                  )}

                  {/* {(user_type === "admin" || permissions?.["View Personal Chat "]) && (
                    <div className={`menu-item ${path === "/personal-chat" ? "active" : ""}`}>
                      <Link to="/personal-chat" className="menu-item-link" onClick={() => toggleSubMenu("personal-chat")}>
                        <div className="menu-item-icon">
                          <img src={reasonMIcon} alt="reason_m_svg" className="default" />
                          <img src={reasonMIcon} alt="reason_m_svg" className="active_img" />
                        </div>
                        <span className="menu-item-text">Personal Chat</span>
                      </Link>
                    </div>
                  )} */}

                  {/* {(user_type === "admin" || permissions?.["View Static List"]) && (
                  <div className={`menu-item ${path === "/static-contents" ? "active" : ""}`}>
                    <Link to="/static-contents" className="menu-item-link" onClick={() => toggleSubMenu("static-contents")}>
                      <div className="menu-item-icon">
                        <img src={dashboardMIcon} alt="dashboard_m_svg" className="default" />
                        <img src={dashboardSelectIcon} alt="dashboard_select_svg" className="active_img" />
                      </div>
                      <span className="menu-item-text">Static List</span>
                    </Link>
                  </div>
                  )} */}
                </>
              )}


          </div>
        </div>
      )}
    </>
  );
};

export default Sidebar;
