import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

export default function AppSettingsAddWithdraw({
  onSubmit,
  appSettings,
  isView,
}) {
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (appSettings) {
      const {
        add_min_limit,
        withdraw_min_limit,
        standard_user_per_day_withdraw_limit,
        pro_user_per_day_withdraw_limit,
        gold_user_per_day_withdraw_limit,
      } = appSettings;
      setValue("add_min_limit", add_min_limit);
      setValue("withdraw_min_limit", withdraw_min_limit);
      setValue(
        "standard_user_per_day_withdraw_limit",
        standard_user_per_day_withdraw_limit
      );
      setValue(
        "pro_user_per_day_withdraw_limit",
        pro_user_per_day_withdraw_limit
      );
      setValue(
        "gold_user_per_day_withdraw_limit",
        gold_user_per_day_withdraw_limit
      );
    }
  }, [appSettings]);

  return (
    <div className="row">
      <div className="col-12 col-md-6">
        <div className="portlet">
          <div className="horizontal_filter_card">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row mb-4">
                <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                  Minimum Add Balance
                </label>
                <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <input
                    {...register("add_min_limit", {
                      required: "Min add balance is required.",
                      pattern: {
                        value: /^[0-9]+$/,
                        message: "Must be a number.",
                      },
                    })}
                    aria-invalid={errors.add_min_limit ? "true" : "false"}
                    className="form-control form-control-lg"
                    type="text"
                    placeholder="Enter min add balance"
                    disabled={isView}
                  />
                  {errors.add_min_limit && (
                    <p role="alert" className="text-danger">
                      {errors.add_min_limit?.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                  Minimum Withdraw Balance
                </label>
                <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <input
                    {...register("withdraw_min_limit", {
                      required: "Min withdraw balance is required.",
                      pattern: {
                        value: /^[0-9]+$/,
                        message: "Must be a number.",
                      },
                    })}
                    aria-invalid={errors.withdraw_min_limit ? "true" : "false"}
                    className="form-control form-control-lg"
                    type="text"
                    placeholder="Enter withdraw balance"
                    disabled={isView}
                  />
                  {errors.withdraw_min_limit && (
                    <p role="alert" className="text-danger">
                      {errors.withdraw_min_limit?.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                  Per Day Withdraw Limit( Standard Users)
                </label>
                <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <input
                    {...register("standard_user_per_day_withdraw_limit", {
                      required: "Withdraw limit is required.",
                      pattern: {
                        value: /^[0-9]+$/,
                        message: "Must be a number.",
                      },
                    })}
                    aria-invalid={
                      errors.standard_user_per_day_withdraw_limit
                        ? "true"
                        : "false"
                    }
                    className="form-control form-control-lg"
                    type="text"
                    placeholder="Enter withdraw limit"
                    disabled={isView}
                  />
                  {errors.standard_user_per_day_withdraw_limit && (
                    <p role="alert" className="text-danger">
                      {errors.standard_user_per_day_withdraw_limit?.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                  Per Day Withdraw Limit( Gold Users)
                </label>
                <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <input
                    {...register("gold_user_per_day_withdraw_limit", {
                      required: "Withdraw limit is required.",
                      pattern: {
                        value: /^[0-9]+$/,
                        message: "Must be a number.",
                      },
                    })}
                    aria-invalid={
                      errors.gold_user_per_day_withdraw_limit ? "true" : "false"
                    }
                    className="form-control form-control-lg"
                    type="text"
                    placeholder="Enter withdraw limit"
                    disabled={isView}
                  />
                  {errors.gold_user_per_day_withdraw_limit && (
                    <p role="alert" className="text-danger">
                      {errors.gold_user_per_day_withdraw_limit?.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                  Per Day Withdraw Limit( Pro Users)
                </label>
                <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <input
                    {...register("pro_user_per_day_withdraw_limit", {
                      required: "Withdraw limit is required.",
                      pattern: {
                        value: /^[0-9]+$/,
                        message: "Must be a number.",
                      },
                    })}
                    aria-invalid={
                      errors.pro_user_per_day_withdraw_limit ? "true" : "false"
                    }
                    className="form-control form-control-lg"
                    type="text"
                    placeholder="Enter withdraw limit"
                    disabled={isView}
                  />
                  {errors.pro_user_per_day_withdraw_limit && (
                    <p role="alert" className="text-danger">
                      {errors.pro_user_per_day_withdraw_limit?.message}
                    </p>
                  )}
                </div>
              </div>

              {!isView && (
                <div className="row justify-content-end">
                  <div className="col-12 col-md-12">
                    <div className="text-start">
                      <button className="btn btn-primary btn-lg common_hover">
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
