import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

export default function AppSettingsGroupChat({
  onSubmit,
  appSettings,
  isView,
}) {
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (appSettings) {
      const { group_min_member, group_max_member } = appSettings;
      setValue("group_min_member", group_min_member);
      setValue("group_max_member", group_max_member);
    }
  }, [appSettings]);

  return (
    <>
      <div className="row">
        <div className="col-12 col-md-6">
          <div className="portlet">
            <div className="horizontal_filter_card">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row mb-4">
                  <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                    Minimum Member
                  </label>
                  <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <input
                      {...register("group_min_member", {
                        required: "Min member is required.",
                        pattern: {
                          value: /^[0-9]+$/,
                          message: "Must be a number.",
                        },
                        validate: {
                          lessThanMax: (value) =>
                            value >= 4 || "Must be at least 4 members",
                        },
                      })}
                      aria-invalid={errors.group_min_member ? "true" : "false"}
                      className="form-control form-control-lg"
                      type="text"
                      placeholder="Enter min member"
                      disabled={isView}
                    />
                    {errors.group_min_member && (
                      <p role="alert" className="text-danger">
                        {errors.group_min_member?.message}
                      </p>
                    )}
                  </div>
                </div>
                <div className="row mb-4">
                  <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                    Maximum Member
                  </label>
                  <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <input
                      {...register("group_max_member", {
                        required: "Max member is required.",
                        pattern: {
                          value: /^[0-9]+$/,
                          message: "Must be a number.",
                        },
                        validate: {
                          lessThanMax: (value) =>
                            value <= 200 || "Only 200 members allowed",
                        },
                      })}
                      aria-invalid={errors.group_max_member ? "true" : "false"}
                      className="form-control form-control-lg"
                      type="text"
                      placeholder="Enter max member"
                      disabled={isView}
                    />
                    {errors.group_max_member && (
                      <p role="alert" className="text-danger">
                        {errors.group_max_member?.message}
                      </p>
                    )}
                  </div>
                </div>

                {!isView && (
                  <div className="row justify-content-end">
                    <div className="col-12 col-md-12">
                      <div className="text-start">
                        <button className="btn btn-primary btn-lg common_hover">
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
