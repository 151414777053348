import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "../../utils/api.service";
import { setLoader } from "./masterSlice";

export const getBlockIpList = createAsyncThunk("blockIpList", async (userId, { dispatch }) => {
  try {
    dispatch(setLoader(true));
    const { data: blockIpList } = await API.getBlockIpList(userId);
    dispatch(setLoader(false));
    return blockIpList;
  } catch (error) {
    dispatch(setLoader(false));
    throw error;
  }
});

export const getBlockDeviceList = createAsyncThunk("blockDeviceList", async (userId, { dispatch }) => {
  try {
    dispatch(setLoader(true));
    const { data: blockDeviceList } = await API.getBlockDeviceList(userId);
    dispatch(setLoader(false));
    return blockDeviceList;
  } catch (error) {
    dispatch(setLoader(false));
    throw error;
  }
});

const initialState = {
  blockIpList: {
    data: {},
    error: null,
  },
  blockDeviceList: {
    data: {},
    error: null,
  },
};

const blockSlice = createSlice({
  name: "BLOCK",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBlockIpList.fulfilled, (state, action) => {
        state.blockIpList.data = action.payload;
      })
      .addCase(getBlockIpList.rejected, (state, action) => {
        state.blockIpList.error = action.error.message;
      })
      .addCase(getBlockDeviceList.fulfilled, (state, action) => {
        state.blockDeviceList.data = action.payload;
      })
      .addCase(getBlockDeviceList.rejected, (state, action) => {
        state.blockDeviceList.error = action.error.message;
      });
  },
});

export default blockSlice.reducer;
