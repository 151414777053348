import { Link } from "react-router-dom";
import { AWS_BASE_URL } from "../../../app.config";

const CustomCard = ({
  id,
  name,
  image,
  isActive,
  imageDimensions,
  isDeletable,
  isEditable,
  viewPath,
  editPath,
  handleActiveInactive,
  handleDelete,
  children,
}) => {
  const isImageUrlValid = image?.split("/").length === 6;
  return (
    <div className="col-12 col-xl-3 col-lg-4 col-md-4">
      <div className="portlet">
        <div className="portlet-body">
          <div
            className={`position-relative ${
              imageDimensions?.width <= imageDimensions?.height ? "image_storage_3" : "image_storage_1"
            } `}
          >
            <img src={isImageUrlValid ? image : `${AWS_BASE_URL}/${image}`} alt="advertisement_image" />
            {isEditable && (
              <div className="floating_switch">
                <div className="form-check form-switch">
                  <input
                    className="form-check-input pointer"
                    name="is_active"
                    type="checkbox"
                    role="switch"
                    id={`flexSwitchCheckDefault_${id}`}
                    defaultChecked={isActive}
                    onChange={handleActiveInactive}
                  />
                  <label className="form-check-label" htmlFor={`flexSwitchCheckDefault_${id}`}></label>
                </div>
              </div>
            )}
          </div>
          <div className="title_blocks mt-lg-4 mt-3">
            <Link to={viewPath}>
              <span>{name}</span>
            </Link>
            <div className="button_blocks">
              {isEditable && (
                <Link className="btn btn-label-primary btn-icon mx-2" to={editPath}>
                  <i className="fa fa-edit"></i>
                </Link>
              )}
              {isDeletable && (
                <button className="btn btn-label-danger btn-icon" onClick={handleDelete}>
                  <i className="fa fa-trash"></i>
                </button>
              )}
            </div>
          </div>
          {children && children}
        </div>
      </div>
    </div>
  );
};

export default CustomCard;
