import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

export default function AppSettingsGeneral({ onSubmit, appSettings, isView }) {
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (appSettings) {
      const {
        edit_username_days,
        edit_game_details_days,
        invitation_count,
        dispute_minute,
        estimated_delivery_day,
        referral_url,
        bonus_url
      } = appSettings;
      setValue("edit_username_days", edit_username_days);
      setValue("edit_game_details_days", edit_game_details_days);
      setValue("invitation_count", invitation_count);
      setValue("dispute_minute", dispute_minute);
      setValue("estimated_delivery_day", estimated_delivery_day);
      setValue("referral_url", referral_url);
      setValue("bonus_url", bonus_url);
    }
  }, [appSettings, setValue]);

  return (
    <div className="row">
      <div className="col-md-12 col-sm-6">
        <div className="portlet">
          <div className="horizontal_filter_card">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <div className="row mb-4">
                    <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                      Edit Username After (in days)
                    </label>
                    <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                      <input
                        {...register("edit_username_days", {
                          required: "Edit username after is required.",
                          pattern: {
                            value: /^[0-9]+$/,
                            message: "Must be a number.",
                          },
                        })}
                        aria-invalid={errors.edit_username_days ? "true" : "false"}
                        className="form-control form-control-lg"
                        type="text"
                        placeholder="Enter edit username after days"
                        disabled={isView}
                      />
                      {errors.edit_username_days && (
                        <p role="alert" className="text-danger">
                          {errors.edit_username_days?.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="row mb-4">
                    <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                      Edit Game Details After (in days)
                    </label>
                    <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                      <input
                        {...register("edit_game_details_days", {
                          required: "Edit game details after is required.",
                          pattern: {
                            value: /^[0-9]+$/,
                            message: "Must be a number.",
                          },
                        })}
                        aria-invalid={
                          errors.edit_game_details_days ? "true" : "false"
                        }
                        className="form-control form-control-lg"
                        type="text"
                        placeholder="Enter edit game details days"
                        disabled={isView}
                      />
                      {errors.edit_game_details_days && (
                        <p role="alert" className="text-danger">
                          {errors.edit_game_details_days?.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="row mb-4">
                    <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                      Invitation limit
                    </label>
                    <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                      <input
                        {...register("invitation_count", {
                          required: "invitation limit is required.",
                          pattern: {
                            value: /^[0-9]+$/,
                            message: "Must be a number.",
                          },
                        })}
                        aria-invalid={errors.invitation_count ? "true" : "false"}
                        className="form-control form-control-lg"
                        type="text"
                        placeholder="Enter invitation limit"
                        disabled={isView}
                      />
                      {errors.invitation_count && (
                        <p role="alert" className="text-danger">
                          {errors.invitation_count?.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="row mb-4">
                    <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                      Dispute Time Limit (in mins)
                    </label>
                    <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                      <input
                        {...register("dispute_minute", {
                          required: "Dispute limit is required.",
                          pattern: {
                            value: /^[0-9]+$/,
                            message: "Must be a number.",
                          },
                        })}
                        aria-invalid={errors.dispute_minute ? "true" : "false"}
                        className="form-control form-control-lg"
                        type="text"
                        placeholder="Enter dispute limit"
                        disabled={isView}
                      />
                      {errors.dispute_minute && (
                        <p role="alert" className="text-danger">
                          {errors.dispute_minute?.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="row mb-4">
                    <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                      Estimated Delivery (in days)
                    </label>
                    <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                      <input
                        {...register("estimated_delivery_day", {
                          required: "Estimated delivery is required.",
                          pattern: {
                            value: /^[0-9]+$/,
                            message: "Must be a number.",
                          },
                        })}
                        aria-invalid={
                          errors.estimated_delivery_day ? "true" : "false"
                        }
                        className="form-control form-control-lg"
                        type="text"
                        placeholder="Enter estimated delivery"
                        disabled={isView}
                      />
                      {errors.estimated_delivery_day && (
                        <p role="alert" className="text-danger">
                          {errors.estimated_delivery_day?.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="row mb-4">
                    <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                      Referral URL
                    </label>
                    <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                      <input
                        {...register("referral_url", {
                          required: "Referral url is required",
                          pattern: {
                            value: /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
                            message: "Must be a valid url",
                          },
                        })}
                        aria-invalid={
                          errors.referral_url ? "true" : "false"
                        }
                        className="form-control form-control-lg"
                        type="text"
                        placeholder="Enter referral url"
                        disabled={isView}
                      />
                      {errors.referral_url && (
                        <p role="alert" className="text-danger">
                          {errors.referral_url?.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="row mb-4">
                    <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                      Bonus URL
                    </label>
                    <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                      <input
                        {...register("bonus_url", {
                          required: "Bonus url is required",
                          pattern: {
                            value: /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
                            message: "Must be a valid url",
                          },
                        })}
                        aria-invalid={
                          errors.bonus_url ? "true" : "false"
                        }
                        className="form-control form-control-lg"
                        type="text"
                        placeholder="Enter bonus url"
                        disabled={isView}
                      />
                      {errors.bonus_url && (
                        <p role="alert" className="text-danger">
                          {errors.bonus_url?.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {!isView && (
                <div className="row justify-content-end">
                  <div className="col-12 col-md-12">
                    <div className="text-start">
                      <button className="btn btn-primary btn-lg common_hover">
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
