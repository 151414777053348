import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

export default function AppSettingsSMSGateway({
  onSubmit,
  appSettings,
  isView,
}) {
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (appSettings) {
      const {
        is_exotel_disable,
        exotel_account_sid,
        exotel_auth,
        exotel_api_token,
        is_twilio_disable,
        twilio_account_sid,
        twilio_auth,
        twilio_number,
      } = appSettings;
      setValue("is_exotel_disable", is_exotel_disable);
      setValue("exotel_account_sid", exotel_account_sid);
      setValue("exotel_auth", exotel_auth);
      setValue("exotel_api_token", exotel_api_token);
      setValue("is_twilio_disable", is_twilio_disable);
      setValue("twilio_account_sid", twilio_account_sid);
      setValue("twilio_auth", twilio_auth);
      setValue("twilio_number", twilio_number);
    }
  }, [appSettings]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="portlet">
        <div className="horizontal_filter_card">
          <div className="row mb-5">
            <div className="col-xl-6 col-lg-6 col-md-6">
              <div className="payment_info_container">
                <h3>Exotel</h3>
              </div>
              <div className="payment_info_container">
                <div className="payment_blue_section">
                  <div className="payment_info-header mb-4">
                    <div className="payment_company_tag">
                      {/* <img src={publicUrl + "/assets/images/site_icons/payment_img.svg"} alt="image" /> */}
                      <span>Exotel SMS Gateway</span>
                    </div>
                    <div className="form-check form-switch">
                      <input
                        {...register("is_exotel_disable")}
                        className="form-check-input pointer"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckDefault"
                        defaultChecked
                        disabled={isView}
                      />
                    </div>
                  </div>
                  <div className="row mb-4">
                    <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                      Exotel Account
                    </label>
                    <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                      <input
                        {...register("exotel_account_sid", {
                          required: "Exotel account is required.",
                        })}
                        aria-invalid={
                          errors.exotel_account_sid ? "true" : "false"
                        }
                        className="form-control form-control-lg"
                        type="text"
                        placeholder="Enter exotel account"
                        disabled={isView}
                      />
                      {errors.exotel_account_sid && (
                        <p role="alert" className="text-danger">
                          {errors.exotel_account_sid?.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="row mb-4">
                    <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                      Exotel auth
                    </label>
                    <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                      <input
                        {...register("exotel_auth", {
                          required: "Exotel auth required.",
                        })}
                        aria-invalid={errors.exotel_auth ? "true" : "false"}
                        className="form-control form-control-lg"
                        type="text"
                        placeholder="Enter exotel auth"
                        disabled={isView}
                      />
                      {errors.exotel_auth && (
                        <p role="alert" className="text-danger">
                          {errors.exotel_auth?.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="row mb-4">
                    <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                      Exotel api token
                    </label>
                    <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                      <input
                        {...register("exotel_api_token", {
                          required: "Exotel api token is required.",
                        })}
                        aria-invalid={
                          errors.exotel_api_token ? "true" : "false"
                        }
                        className="form-control form-control-lg"
                        type="text"
                        placeholder="Enter exotel api token"
                        disabled={isView}
                      />
                      {errors.exotel_api_token && (
                        <p role="alert" className="text-danger">
                          {errors.exotel_api_token?.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6">
              <div className="payment_info_container">
                <h3>Twilio</h3>
              </div>
              <div className="payment_info_container">
                <div className="payment_blue_section">
                  <div className="payment_info-header mb-4">
                    <div className="payment_company_tag">
                      {/* <img src={publicUrl + "/assets/images/site_icons/payment_img.svg"} alt="image" /> */}
                      <span>Twilio SMS Gateway</span>
                    </div>
                    <div className="form-check form-switch">
                      <input
                        {...register("is_twilio_disable")}
                        className="form-check-input pointer"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckDefault"
                        defaultChecked
                        disabled={isView}
                      />
                    </div>
                  </div>
                  <div className="row mb-4">
                    <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                      Twilio Account
                    </label>
                    <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                      <input
                        {...register("twilio_account_sid", {
                          required: "Twilio account is required.",
                        })}
                        aria-invalid={
                          errors.twilio_account_sid ? "true" : "false"
                        }
                        className="form-control form-control-lg"
                        type="text"
                        placeholder="Enter exotel account"
                        disabled={isView}
                      />
                      {errors.twilio_account_sid && (
                        <p role="alert" className="text-danger">
                          {errors.twilio_account_sid?.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="row mb-4">
                    <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                      Twilio auth
                    </label>
                    <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                      <input
                        {...register("twilio_auth", {
                          required: "Twilio auth required.",
                        })}
                        aria-invalid={errors.twilio_auth ? "true" : "false"}
                        className="form-control form-control-lg"
                        type="text"
                        placeholder="Enter twilio auth"
                        disabled={isView}
                      />
                      {errors.twilio_auth && (
                        <p role="alert" className="text-danger">
                          {errors.twilio_auth?.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="row mb-4">
                    <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                      Twilio number
                    </label>
                    <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                      <input
                        {...register("twilio_number", {
                          required: "Twilio api token is required.",
                        })}
                        aria-invalid={errors.twilio_number ? "true" : "false"}
                        className="form-control form-control-lg"
                        type="text"
                        placeholder="Enter twilio api token"
                        disabled={isView}
                      />
                      {errors.twilio_number && (
                        <p role="alert" className="text-danger">
                          {errors.twilio_number?.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {!isView && (
            <div className="row justify-content-end">
              <div className="col-12 col-md-12">
                <div className="text-end">
                  <button className="btn btn-primary btn-lg common_hover">
                    Update
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </form>
  );
}
