import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
// import AppSettingsWelcome from "./CountrySetting";
import { getCountryList, getCountrySearchListNew, setLoader } from "../../../store/slices/masterSlice";
import { TOAST_ERROR, TOAST_SUCCESS } from "../../../utils/common.service";
import * as API from "../../../utils/api.service";
import { uploadImageOnAWS } from "../../../utils/aws.service";
import CountrySetting from "./CountrySetting";


const AddCountry = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [appSettings, setAppSettings] = useState({});
  const {
    userDetails: { data: { user_type = "", permissions = {} } = {} } = {},
  } = useSelector((state) => state.subAdmin);
  const location = useLocation();

  const {
    countryList: { data: countryList = [] }
  } = useSelector((state) => state.master);

  const dispatch = useDispatch();
  const { id: settingId } = useParams();
  
  useEffect(() => {
    settingId && getCountryDetails();
  }, [settingId]);

  const getCountryDetails = async () => {
    try {
      dispatch(setLoader(false));
      const { code, message, data } = await API.getCountryDetails(
        settingId
      );
      console.log(data,"data");
      if (code === 1) {
        setAppSettings(data);
      } else TOAST_ERROR(message);
      dispatch(setLoader(false));
    } catch (err) {
      dispatch(setLoader(false));
      TOAST_ERROR(err.message);
    }
  };

  const onSubmit = async (data) => {
    try {
      dispatch(setLoader(true));
      if (data?.is_active === false) {
        data.is_active = 0;
      } else {
        data.is_active = 1;
      }
      if (data?.flag instanceof Blob){
        data.flag_name = await uploadImageOnAWS(data.flag, "flag");
        data.flag = "https://war94-live.s3.amazonaws.com/esports/flag/"+data.flag_name;
      }

      const { code, message } = settingId
        ? await API.updateCountryDetails(settingId, data)
        : await API.addCountry(data);
      if (code === 1) {
        TOAST_SUCCESS(message);
        settingId && getCountryDetails();
        dispatch(getCountrySearchListNew());
        navigate('/country-list');
      } else TOAST_ERROR(message);
      dispatch(setLoader(false));
    } catch (err) {
      dispatch(setLoader(false));
      TOAST_ERROR(err.message);
    }
  };


  const isView = location.pathname.split("/")[2] === "view-country";

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <CountrySetting
            onSubmit={onSubmit}
            appSettings={appSettings}
            isView={isView}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="content">
      <div className="container-fluid g-5">
        {renderStep()}
      </div>
    </div>
  );
};

export default AddCountry;
