import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "../../utils/api.service";
import { setLoader } from "./masterSlice";

export const getSpotlightPriceList = createAsyncThunk("spotlightPriceList", async ({ page, filters }, { dispatch }) => {
  try {
    dispatch(setLoader(true));
    const { data: spotlightPriceList } = await API.getSpotlightPriceList(page, filters);
    dispatch(setLoader(false));
    return spotlightPriceList;
  } catch (error) {
    dispatch(setLoader(false));
    throw error;
  }
});

export const getSpotlightList = createAsyncThunk("spotlightList", async ({ page, filters }, { dispatch }) => {
  try {
    dispatch(setLoader(true));
    const { data: spotlightList } = await API.getSpotlightList(page, filters);
    dispatch(setLoader(false));
    return spotlightList;
  } catch (error) {
    dispatch(setLoader(false));
    throw error;
  }
});

const initialState = {
  spotlightPriceList: {
    data: {},
    error: null,
  },
  spotlightList: {
    data: {},
    error: null,
  },
};

const spotlightSlice = createSlice({
  name: "SPOTLIGHT",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSpotlightPriceList.fulfilled, (state, action) => {
        state.spotlightPriceList.data = action.payload;
      })
      .addCase(getSpotlightPriceList.rejected, (state, action) => {
        state.spotlightPriceList.error = action.error.message;
      })
      .addCase(getSpotlightList.fulfilled, (state, action) => {
        state.spotlightList.data = action.payload;
      })
      .addCase(getSpotlightList.rejected, (state, action) => {
        state.spotlightList.error = action.error.message;
      });
  },
});

export default spotlightSlice.reducer;
