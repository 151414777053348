import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "../../utils/api.service";
import { setLoader } from "./masterSlice";

export const getProductList = createAsyncThunk("productList", async ({ page, filters }, { dispatch }) => {
  try {
    dispatch(setLoader(true));
    const { data: productList } = await API.getProductList(page, filters);
    dispatch(setLoader(false));
    return productList;
  } catch (error) {
    dispatch(setLoader(false));
    throw error;
  }
});

export const getCategoryList = createAsyncThunk("categoryList", async ({ page, filters }, { dispatch }) => {
  try {
    dispatch(setLoader(true));
    const { data: categoryList } = await API.getCategoryList(page, filters);
    dispatch(setLoader(false));
    return categoryList;
  } catch (error) {
    dispatch(setLoader(false));
    throw error;
  }
});

export const getOrderList = createAsyncThunk("orderList", async ({ page, filters }, { dispatch }) => {
  try {
    dispatch(setLoader(true));
    const { data: orderList } = await API.getOrderList(page, filters);
    dispatch(setLoader(false));
    return orderList;
  } catch (error) {
    dispatch(setLoader(false));
    throw error;
  }
});

const initialState = {
  productList: {
    data: {},
    error: null,
  },
  categoryList: {
    data: {},
    error: null,
  },
  orderList: {
    data: {},
    error: null,
  },
};

const productSlice = createSlice({
  name: "PRODUCT",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProductList.fulfilled, (state, action) => {
        state.productList.data = action.payload;
      })
      .addCase(getProductList.rejected, (state, action) => {
        state.productList.error = action.error.message;
      })
      .addCase(getCategoryList.fulfilled, (state, action) => {
        state.categoryList.data = action.payload;
      })
      .addCase(getCategoryList.rejected, (state, action) => {
        state.categoryList.error = action.error.message;
      })
      .addCase(getOrderList.fulfilled, (state, action) => {
        state.orderList.data = action.payload;
      })
      .addCase(getOrderList.rejected, (state, action) => {
        state.orderList.error = action.error.message;
      });
  },
});

export default productSlice.reducer;
