import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "../../utils/api.service";
import { setLoader } from "./masterSlice";

export const getEventList = createAsyncThunk("eventList", async ({ page, filters }, { dispatch }) => {
  try {
    dispatch(setLoader(true));
    const { data: eventList } = await API.getEventList(page, filters);
    dispatch(setLoader(false));
    return eventList;
  } catch (error) {
    dispatch(setLoader(false));
    throw error;
  }
});

const initialState = {
  eventList: {
    data: [],
    error: null,
  },
};

const eventSlice = createSlice({
  name: "EVENT",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getEventList.fulfilled, (state, action) => {
        state.eventList.data = action.payload;
      })
      .addCase(getEventList.rejected, (state, action) => {
        state.eventList.error = action.error.message;
      });
  },
});

export default eventSlice.reducer;
