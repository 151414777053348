import React, { useEffect, useState, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { setLoader } from "../../../store/slices/masterSlice";
import { DATE_LOCAL_DASH_TIME_FORMAT, DATE_YEAR_WISE_DASH_TIME_FORMAT } from "../../../app.config";
import * as API from "./../../../utils/api.service";
import { TOAST_ERROR, TOAST_SUCCESS, formatDate, formatDateLocalToUtc } from "../../../utils/common.service";

const AddSubscription = () => {
  const [membershipNameList, setMembershipNameList] = useState({});
  console.log("membershipNameList: ", membershipNameList);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { id: subscriptionId } = useParams();

  const {
    countryList: { data: countryList = [] },
  } = useSelector((state) => state.master);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const getSubscriptionPlanDetails = async () => {
    try {
      dispatch(setLoader(true));
      const { data } = await API.getGoldSubscriptionPlanDetails(subscriptionId);
      setValue("membership_name", data?.membership_name);
      setValue("duration", +data?.duration);
      setValue("free_tournament_count", +data?.free_tournament_count);
      setValue("price", +data?.price);
      setValue("country", data?.country);
      setValue("withdrawals_limit", +data?.withdrawals_limit);
      setValue("time_restrictions", +data?.time_restrictions);
      setValue("is_active", data?.is_active);
      dispatch(setLoader(false));
    } catch (err) {
      dispatch(setLoader(false));
      TOAST_ERROR(err.message);
    }
  };

  useEffect(() => {
    getMembershipNameList();
    subscriptionId && getSubscriptionPlanDetails();
  }, [subscriptionId]);

  const onSubmit = async (data) => {
    try {
      dispatch(setLoader(true));
      data = { ...data, is_active: data.is_active ? 1 : 0 };
      const { message, code } = subscriptionId
        ? await API.updateGoldSubscriptionPlan(subscriptionId, data)
        : await API.addGoldSubscriptionPlan(data);
      dispatch(setLoader(false));
      if (code === 1) {
        TOAST_SUCCESS(message);
        navigate("/gold-subscriptions");
      } else TOAST_ERROR(message);
    } catch (err) {
      dispatch(setLoader(false));
      TOAST_ERROR(err.message);
    }
  };

  const getMembershipNameList = async () => {
    try {
      dispatch(setLoader(true));
      const { message, code, data } = await API.getMembershipNameList(1, "&is_active=1");
      if (code === 1) setMembershipNameList(data);
      else TOAST_ERROR(message);
      dispatch(setLoader(false));
    } catch (err) {
      dispatch(setLoader(false));
      TOAST_ERROR(err.message);
    }
  };

  const showCountryList = useMemo(() => {
    return countryList.map((item) => {
      return (
        <option key={item.id} value={item.value}>
          {item.label}
        </option>
      );
    });
  }, [countryList]);

  const showMembershipNameList = useMemo(() => {
    return membershipNameList?.results?.map((item) => {
      return (
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      );
    });
  }, [membershipNameList]);

  return (
    <div className="content">
      <div className="container-fluid g-5">
        <div className="row">
          <div className="col-12 col-md-12">
            <h4 className="sub_heading_p">Gold Subscription Plan Details</h4>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-12">
            <div className="portlet">
              <div className="horizontal_filter_card">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row mb-4">
                    <label className="col-12 col-xl-4 col-sm-4 col-lg-4 col-md-4 col-form-label text-sm-start">
                      Membership Name
                    </label>
                    <div className="col-12 col-xl-8 col-lg-8 col-md-8 col-sm-8">
                      <select
                        {...register("membership_name", {
                          required: "Membership name is required.",
                        })}
                        aria-invalid={errors.membership_name ? "true" : "false"}
                        className="form-select form-select-lg"
                        defaultValue={""}
                      >
                        <option value={""}>Select membership name</option>
                        {showMembershipNameList}
                      </select>
                      {errors.membership_name && (
                        <p role="alert" className="text-danger">
                          {errors.membership_name?.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="row mb-4">
                    <label className="col-12 col-xl-4 col-sm-4 col-lg-4 col-md-4 col-form-label text-sm-start">
                      Duration (in months)
                    </label>
                    <div className="col-12 col-xl-8 col-lg-8 col-md-8 col-sm-8">
                      <input
                        {...register("duration", {
                          required: "Duration is required.",
                          pattern: {
                            value: /^[0-9]+$/,
                            message: "Must be a number.",
                          },
                        })}
                        aria-invalid={errors.duration ? "true" : "false"}
                        className="form-control form-control-lg"
                        type="text"
                        placeholder="Enter duration"
                      />
                      {errors.duration && (
                        <p role="alert" className="text-danger">
                          {errors.duration?.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="row mb-4">
                    <label className="col-12 col-xl-4 col-sm-4 col-lg-4 col-md-4 col-form-label text-sm-start">
                      Price
                    </label>
                    <div className="col-12 col-xl-8 col-lg-8 col-md-8 col-sm-8">
                      <input
                        {...register("price", {
                          required: "Price is required.",
                          pattern: {
                            value: /^[0-9]+$/,
                            message: "Must be a number.",
                          },
                        })}
                        aria-invalid={errors.price ? "true" : "false"}
                        className="form-control form-control-lg"
                        type="text"
                        placeholder="Enter price"
                      />
                      {errors.price && (
                        <p role="alert" className="text-danger">
                          {errors.price?.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="row mb-4">
                    <label className="col-12 col-xl-4 col-sm-4 col-lg-4 col-md-4 col-form-label text-sm-start">
                      Country
                    </label>
                    <div className="col-12 col-xl-8 col-lg-8 col-md-8 col-sm-8">
                      <select
                        {...register("country", {
                          required: "Country is required.",
                        })}
                        aria-invalid={errors.country ? "true" : "false"}
                        className="form-select form-select-lg"
                        defaultValue={""}
                      >
                        <option value={""}>Select country</option>
                        {showCountryList}
                      </select>
                      {errors.country && (
                        <p role="alert" className="text-danger">
                          {errors.country?.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="row mb-4">
                    <label className="col-12 col-xl-4 col-sm-4 col-lg-4 col-md-4 col-form-label text-sm-start">
                      Free Tournament Count
                    </label>
                    <div className="col-12 col-xl-8 col-lg-8 col-md-8 col-sm-8">
                      <input
                        {...register("free_tournament_count", {
                          required: "Tournament count is required.",
                          pattern: {
                            value: /^[0-9]+$/,
                            message: "Must be a number.",
                          },
                        })}
                        aria-invalid={errors.free_tournament_count ? "true" : "false"}
                        className="form-control"
                        placeholder="Enter tournament count"
                      />
                      {errors.free_tournament_count && (
                        <p role="alert" className="text-danger">
                          {errors.free_tournament_count?.message}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="row mb-4">
                    <label className="col-12 col-xl-4 col-sm-4 col-lg-4 col-md-4 col-form-label text-sm-start">
                      Withdrawal Limit
                    </label>
                    <div className="col-12 col-xl-8 col-lg-8 col-md-8 col-sm-8">
                      <input
                        {...register("withdrawals_limit", {
                          required: "Withdrawal limit is required.",
                          pattern: {
                            value: /^[0-9]+$/,
                            message: "Must be a number.",
                          },
                        })}
                        aria-invalid={errors.withdrawals_limit ? "true" : "false"}
                        className="form-control form-control-lg"
                        type="text"
                        placeholder="Enter withdrawal limit"
                      />
                      {errors.withdrawals_limit && (
                        <p role="alert" className="text-danger">
                          {errors.withdrawals_limit?.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="row mb-4">
                    <label className="col-12 col-xl-4 col-sm-4 col-lg-4 col-md-4 col-form-label text-sm-start">
                      Time Restriction (in mins)
                    </label>
                    <div className="col-12 col-xl-8 col-lg-8 col-md-8 col-sm-8">
                      <input
                        {...register("time_restrictions", {
                          required: "Time restriction is required.",
                          pattern: {
                            value: /^[0-9]+$/,
                            message: "Must be a number.",
                          },
                        })}
                        aria-invalid={errors.time_restrictions ? "true" : "false"}
                        className="form-control form-control-lg"
                        type="text"
                        placeholder="Enter time restriction"
                      />
                      {errors.time_restrictions && (
                        <p role="alert" className="text-danger">
                          {errors.time_restrictions?.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="row mb-4">
                    <label className="col-12 col-xl-4 col-sm-4 col-lg-4 col-md-4 col-form-label text-sm-start">
                      Active
                    </label>
                    <div className="col-12 col-xl-2 col-lg-2 col-md-2 col-sm-2">
                      <div className="form-check form-switch">
                        <input
                          {...register("is_active")}
                          className="form-check-input pointer"
                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckDefault"
                        />
                        <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                          {watch("is_active") ? "Active" : "Inactive"}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-12 col-md-4">
                      <button className="btn btn-primary btn-lg">{subscriptionId ? "Update" : "Save"}</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSubscription;
