import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "../../utils/api.service";
import { setLoader } from "./masterSlice";

export const getCustomerDisputeList = createAsyncThunk("disputeList", async ({ page, filters }, { dispatch }) => {
  try {
    dispatch(setLoader(true));
    const { data: disputeList } = await API.getCustomerDisputeList(page, filters);
    dispatch(setLoader(false));
    return disputeList;
  } catch (error) {
    dispatch(setLoader(false));
    throw error;
  }
});

const initialState = {
  customerDisputeList: {
    data: [],
    error: null,
  },
};

const disputeSlice = createSlice({
  name: "DISPUTE",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCustomerDisputeList.fulfilled, (state, action) => {
        state.customerDisputeList.data = action.payload;
      })
      .addCase(getCustomerDisputeList.rejected, (state, action) => {
        state.customerDisputeList.error = action.error.message;
      });
  },
});

export default disputeSlice.reducer;
