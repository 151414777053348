import React, { useState } from "react";
import { Carousel } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { DELETE_MODAL } from "../../../app.config";
import like from "../../../assets/images/site_icons/Heart_liked.svg";
import unlike from "../../../assets/images/site_icons/Heart-unliked.svg";
import commentSvg from "../../../assets/images/site_icons/comment_post.svg";
import sharePostSvg from "../../../assets/images/site_icons/share_post.svg";
import verifiedBadge from "../../../assets/images/site_icons/verified_badge.svg";
import pinPostSvg from "../../../assets/images/site_icons/pin_post.svg";
import { setLoader, setModalStatus } from "../../../store/slices/masterSlice";
import {
  TOAST_ERROR,
  TOAST_SUCCESS,
  getTimeValues,
} from "../../../utils/common.service";
import * as API from "../../../utils/api.service";

const PostCard = ({
  path,
  post,
  post_user_tag_list,
  showComments,
  handleShowComments,
  showCommentInput,
  handleShowCommentInput,
  handleFollow,
  handleUnfollow,
  allowEdit,
  allowDelete,
  isReportPage,
  callback,
}) => {
  // console.log("🚀 ~ file: index.js:33 ~ post:", post);
  const [newComment, setNewComment] = useState("");

  const userData = useSelector((state) => state.subAdmin);
  let admindata = userData?.userDetails?.data;
  
  const dispatch = useDispatch();

  const handleComment = async (e, commentId) => {
    try {
      if (newComment && e.key === "Enter") {
        e.preventDefault();
        dispatch(setLoader(true));
        let body = { comment: newComment };
        if (!commentId) body.post = post?.id;
        const { code, message } = commentId
          ? await API.replyComment(commentId, body)
          : await API.addComment(body);
        dispatch(setLoader(false));
        if (code === 1) {
          setNewComment("");
          callback();
          TOAST_SUCCESS(message);
        } else TOAST_ERROR(message);
      }
    } catch (err) {
      dispatch(setLoader(false));
      TOAST_ERROR(err.message);
    }
  };

  const handleLike = async (postId) => {
    try {
      dispatch(setLoader(true));
      const { code, message } = await API.addPostLike(postId);
      dispatch(setLoader(false));
      if (code === 1) {
        callback();
        TOAST_SUCCESS(message);
      } else TOAST_ERROR(message);
    } catch (err) {
      dispatch(setLoader(false));
      TOAST_ERROR(err.message);
    }
  }

  return (
    <>
      <div
        className={`col-12 ${
          isReportPage
            ? "col-xl-12 col-lg-12 col-md-12"
            : "col-xl-6 col-lg-6 col-md-6"
        } `}
      >
        <div className="main-wraper portlet">
          <div className="horizontal_filter_card">
            <div className="user-post">
              <div className="friend-info">
                <div className="d-flex friend-info">
                  <figure>
                    <Link to={`/users/view-user-details/${post?.user_id}`}>
                      <img alt="" src={post?.profile_image} />
                    </Link>
                  </figure>
                  <div className="friend-name">
                    <div className="more">
                      <div className="more-post-optns">
                        <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                        <ul>
                          {post?.user_id !== admindata?.id && (
                            (
                              post?.is_following ? 
                              <li onClick={() => handleUnfollow(post?.user_id)} className='link-dark'>
                                <i className="fa fa-user" aria-hidden="true"></i>{" "}
                                Unfollow
                              </li> :
                              <li onClick={() => handleFollow(post?.user_id)} className='link-dark'>
                                <i className="fa fa-user" aria-hidden="true"></i>{" "}
                                Follow
                              </li>
                            )
                          )}
                          {path && allowEdit && (
                            <li className='link-dark'>
                              <Link to={path}>
                                <i
                                  className="fa fa-pencil"
                                  aria-hidden="true"
                                ></i>{" "}
                                Edit
                              </Link>
                            </li>
                          )}
                          {allowDelete && (
                            <li
                              onClick={() =>
                                dispatch(
                                  setModalStatus({
                                    modalType: DELETE_MODAL,
                                    isOpen: true,
                                    data: post?.id,
                                  })
                                )
                              }
                              className='link-dark'
                            >
                              <i className="fa fa-trash" aria-hidden="true"></i>{" "}
                              Delete Post
                            </li>
                          )}
                          {/* <li>
                            <Link to={"/reason-lists/add-reason"}>
                              <i className="fa fa-flag" aria-hidden="true"></i> Report
                            </Link>
                          </li> */}
                        </ul>
                      </div>
                    </div>
                    {post?.pin ? (
                      <div className="more fav-star">
                        <img
                          src={pinPostSvg}
                          className="view-more-hover me-4"
                          alt="pin_svg"
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                    <ins>
                      <Link title="verified" className='link-dark' to={`/users/view-user-details/${post?.user_id}`}>{post?.username}</Link>
                      {post?.is_verified === "verified" ? (
                        <img src={verifiedBadge} className="verified_img" alt="verified_img" />
                      ) : (
                        <></>
                      )}
                    </ins>
                    <span> {post?.since_post?.split(",")?.[0]}</span>
                  </div>
                </div>
                <div className="my-2 d-flex flex-wrap">
                  {post?.tag.length > 0 &&
                    post?.tag?.map((user) => {
                      return (
                        <Link
                          to={`/users/view-user-details/${user?.id}`}
                          key={user?.id}
                        >
                          {user?.username}&nbsp;
                        </Link>
                      );
                    })}
                </div>
                <div className="post-meta">
                  <div className="image-posting-div bg-transparent">
                    <Carousel
                      indicators={false}
                      controls={post?.post_images?.length > 1 ? true : false}
                      slide={true}
                      pause={"hover"}
                    >
                      {post?.post_images.map((post, i) => {
                        return (
                          <Carousel.Item key={i}>
                            <div className="img-post">
                              {post?.file_type === "image" ? (
                                // eslint-disable-next-line jsx-a11y/img-redundant-alt
                                <img src={post?.file} alt="post image" />
                              ) : (
                                <video
                                  src={post?.file}
                                  width="100%"
                                  height="100%"
                                  loop
                                  muted
                                  autoPlay
                                  playsInline
                                  controls
                                ></video>
                              )}
                            </div>
                          </Carousel.Item>
                        );
                      })}
                    </Carousel>
                  </div>
                  <div className="stat-tools">
                    {/* <p className='text-break'>{post?.description}</p> */}
                    <p className='text-break'>
                    {
                      post?.description && (post?.description?.split(' ').map((word, index) => {
                        if (word.startsWith('@')) {
                          let taggeduser = post_user_tag_list.length > 0 && post_user_tag_list.find(usertag => usertag.username === word);
                          if (taggeduser) {
                            return (
                              <Link className='p-1 link-primary' key={index} to={`/users/view-user-details/${taggeduser.id}`}>{word}</Link>
                            )
                          }
                        }
                        return <span key={index} className="p-1">{word}</span>
                      }))
                    }
                    </p>
                    <div className="sharing_container">
                      <div className="box">
                        <div className="click-to-like">
                          <div id="infoToggler" className="like_ppl-post">
                            {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                            <img
                              src={(post?.likes === 0 || post?.is_like === 0) ? unlike : like}
                              alt="like/unlike image"
                              className={
                                (post?.likes === 0 || post?.is_like === 0) ? "unliked" : "liked"
                              }
                              onClick={() => handleLike(post?.id)}
                            />
                          </div>
                          <div className="like">{post?.likes}</div>
                        </div>
                      </div>

                      <span
                        className="comment-to pointer"
                        onClick={() =>
                          handleShowComments({
                            [post?.id]: !showComments[post?.id],
                          })
                        }
                      >
                        <img src={commentSvg} alt="comment" />
                        {post?.comments?.length}
                      </span>
                      <span className="share-to">
                        <img src={sharePostSvg} alt="share" />
                        {post?.share}
                      </span>
                    </div>
                    <div className="new-comment">
                      {/* <form onSubmit={handleComment}> */}
                      <input
                        type="text"
                        className="form-control form-control-lg bg-transparent"
                        placeholder="Comment.."
                        value={newComment}
                        onChange={(e) => setNewComment(e.target.value)}
                        onKeyDown={handleComment}
                      />
                      {/* <button onKeyDown={handleComment} type="button">
                      <i onClick={handleComment} className="icofont-paper-plane"></i>
                      </button> */}
                      {/* </form> */}
                      {showComments[post?.id] && (
                        <div className="comments-area">
                          <ul>
                            {post?.comments?.map((comment) => {
                              const { days, hours, minutes, seconds } =
                                getTimeValues(
                                  new Date().getTime() -
                                    new Date(comment?.insert_date).getTime()
                                );
                              return (
                                <li key={comment?.id}>
                                  <figure>
                                    <img
                                      alt=""
                                      src={comment?.user?.profile_image}
                                    />
                                  </figure>
                                  <div className="commenter">
                                    <h5>
                                      {/* <a title="" href="#"> */}
                                        {comment?.user?.name}
                                      {/* </a> */}
                                    </h5>
                                    <span>
                                      {days
                                        ? `${days} days`
                                        : hours
                                        ? `${hours} hrs`
                                        : minutes
                                        ? `${minutes} mins`
                                        : `${seconds} sec`}{" "}
                                      ago
                                    </span>
                                    <span
                                      className="floating_rply pointer"
                                      onClick={() =>
                                        handleShowCommentInput({
                                          [comment?.id]:
                                            !showCommentInput[comment?.id],
                                        })
                                      }
                                    >
                                      Reply
                                    </span>
                                    <p>{comment?.comment}</p>
                                  </div>
                                  {comment?.parent_comment && (
                                    <>
                                      {comment?.parent_comment?.map(
                                        (parentComment) => {
                                          const {
                                            days,
                                            hours,
                                            minutes,
                                            seconds,
                                          } = getTimeValues(
                                            new Date().getTime() -
                                              new Date(
                                                parentComment?.insert_date
                                              ).getTime()
                                          );
                                          return (
                                            <>
                                              <div
                                                className="reply-block"
                                                key={parentComment?.id}
                                              >
                                                <ul>
                                                  <li>
                                                    <figure>
                                                      <img
                                                        alt=""
                                                        src={
                                                          parentComment?.user_image
                                                        }
                                                      />
                                                    </figure>
                                                    <div className="commenter">
                                                      <h5>
                                                        <span>
                                                          {parentComment?.name}
                                                        </span>
                                                      </h5>
                                                      <span>
                                                        {days
                                                          ? `${days} days`
                                                          : hours
                                                          ? `${hours} hrs`
                                                          : minutes
                                                          ? `${minutes} mins`
                                                          : `${seconds} sec`}{" "}
                                                        ago
                                                      </span>
                                                      <p>
                                                        {parentComment?.comment}
                                                      </p>
                                                    </div>
                                                  </li>
                                                </ul>
                                              </div>
                                            </>
                                          );
                                        }
                                      )}
                                    </>
                                  )}
                                  {showCommentInput[comment?.id] && (
                                    <div className="rply-editor-block">
                                      {/* <form onSubmit={(e) => handleComment(e, comment?.id)}> */}
                                      <input
                                        type="text"
                                        className="form-control form-control-lg bg-transparent"
                                        placeholder="write comment"
                                        onChange={(e) =>
                                          setNewComment(e.target.value)
                                        }
                                        value={
                                          newComment ||
                                          `${comment?.user?.username} `
                                        }
                                        onKeyDown={(e) =>
                                          handleComment(e, comment?.id)
                                        }
                                      />
                                      {/* <button type="submit">
                                          <i className="icofont-paper-plane"></i>
                                        </button> */}
                                      {/* </form> */}
                                    </div>
                                  )}
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PostCard;
