import { BrowserRouter as Router } from "react-router-dom";
import { Helmet } from "react-helmet";
import Routes from "./routes";
import { useSelector } from "react-redux";
import { Spinner } from "./components/common";
import { ADMIN } from "./app.config";

function App() {
  const { isLoading } = useSelector((state) => state.master);
  const user_type = JSON.parse(localStorage.getItem(ADMIN))?.user_type;
  return (
    <>
      <Helmet>
        <script
          src={`${process.env.PUBLIC_URL}/assets/build/scripts/core.js`}
        ></script>
        {/* <script type="text/javascript" src={`${process.env.PUBLIC_URL}/assets/build/scripts/mandatory.js`}></script>
        <script type="text/javascript" src={`${process.env.PUBLIC_URL}/assets/build/scripts/vendor.js`}></script>
        <script type="text/javascript" src={`${process.env.PUBLIC_URL}/assets/app/utilities/sticky-header.js`}></script>
        <script type="text/javascript" src={`${process.env.PUBLIC_URL}/assets/app/utilities/copyright-year.js`}></script>
        <script type="text/javascript" src={`${process.env.PUBLIC_URL}/assets/app/utilities/tooltip-popover.js`}></script>
        <script type="text/javascript" src={`${process.env.PUBLIC_URL}/assets/app/utilities/dropdown-scrollbar.js`}></script>
        <script type="text/javascript" src={`${process.env.PUBLIC_URL}/assets/app/utilities/fullscreen-trigger.js`}></script>
        <script type="text/javascript" src={`${process.env.PUBLIC_URL}/assets/app/pages/pages/login.js`}></script>
        <script type="text/javascript" src={`${process.env.PUBLIC_URL}/assets/build/scripts/select2.js`}></script> */}
      </Helmet>
      {isLoading && <Spinner />}
      <Router basename={user_type === "organizer_sub_admin" || user_type === "organizer_admin" ? "/organizer" : "/"}>
        <Routes />
      </Router>
    </>
  );
}

export default App;
