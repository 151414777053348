const DropZone = ({ onDrop, multiple, children }) => {
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const files = e.dataTransfer.files;
    if (multiple) onDrop(files);
    else onDrop(files[0]);
  };

  const handleDropOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.dataTransfer.dropEffect = "copy";
  };

  return (
    <div onDrop={handleDrop} onDragOver={handleDropOver}>
      {children}
    </div>
  );
};

export default DropZone;
