import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

export default function AppSettingsTDS({ onSubmit, appSettings, isView }) {
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (appSettings) {
      const { TDS, GST } = appSettings;
      setValue("TDS", TDS);
      setValue("GST", GST);
    }
  }, [appSettings]);

  return (
    <div className="row">
      <div className="col-12 col-md-6">
        <div className="portlet">
          <div className="horizontal_filter_card">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row mb-4">
                <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                  TDS in %
                </label>
                <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <input
                    {...register("TDS", {
                      required: "TDS is required.",
                      pattern: {
                        value: /^[0-9]+$/,
                        message: "Must be a number.",
                      },
                    })}
                    aria-invalid={errors.TDS ? "true" : "false"}
                    className="form-control form-control-lg"
                    type="text"
                    placeholder="Enter TDS"
                    disabled={isView}
                  />
                  {errors.TDS && (
                    <p role="alert" className="text-danger">
                      {errors.TDS?.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                  GST in %
                </label>
                <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <input
                    {...register("GST", {
                      required: "GST is required.",
                      pattern: {
                        value: /^[0-9]+$/,
                        message: "Must be a number.",
                      },
                    })}
                    aria-invalid={errors.GST ? "true" : "false"}
                    className="form-control form-control-lg"
                    type="text"
                    placeholder="Enter GST"
                    disabled={isView}
                  />
                  {errors.GST && (
                    <p role="alert" className="text-danger">
                      {errors.GST?.message}
                    </p>
                  )}
                </div>
              </div>

              {!isView && (
                <div className="row justify-content-end">
                  <div className="col-12 col-md-12">
                    <div className="text-start">
                      <button className="btn btn-primary btn-lg common_hover">
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
