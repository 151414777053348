import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomModal, DropZone } from "../../common";
import * as API from "../../../utils/api.service";
import { setLoader, setModalStatus } from "../../../store/slices/masterSlice";
import { TOAST_ERROR, TOAST_SUCCESS, convertToBase64 } from "../../../utils/common.service";
import { getCategoryList } from "../../../store/slices/productSlice";
import uploadImageIcon from "../../../assets/images/site_icons/Duotone.svg";
import { uploadImageOnAWS } from "../../../utils/aws.service";

const AddMembershipFeature = ({handleFeaturesList}) => {
  const [fields, setFields] = useState({ description: "", feature_image: "" });
  const [errors, setErrors] = useState({ description: false, feature_image: false });
  const [previewImage, setPreviewImage] = useState("");
  const { isModalOpen } = useSelector((state) => state.master);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (errors[name]) setErrors({ ...errors, [name]: false });
    setFields({ ...fields, [name]: value });
  };

  const handleImageChange = async (file) => {
    if (file) {
      if (errors.feature_image) setErrors({ ...errors, feature_image: false });
      setFields({ ...fields, feature_image: file });
      setPreviewImage(await convertToBase64(file));
    }
  };

  const handleAddMembershipFeature = async () => {
    if (!fields.description || !fields.feature_image) {
      if (!fields.description) setErrors((prevErrors) => ({ ...prevErrors, description: true }));
      if (!fields.feature_image) setErrors((prevErrors) => ({ ...prevErrors, feature_image: true }));
      return;
    }
    try {
      dispatch(setLoader(true));
      if (fields.feature_image instanceof Blob)
        fields.feature_image = await uploadImageOnAWS(fields.feature_image, "membership_feature");
      const { code, message } = isModalOpen?.data
        ? await API.editMembershipFeature(isModalOpen?.data, fields)
        : await API.addMembershipFeature(fields);
      dispatch(setLoader(false));
      if (code === 1) {
        dispatch(getCategoryList({ page: 1 }));
        dispatch(
          setModalStatus({
            modalType: "",
            isOpen: false,
            data: null,
          })
        );
        handleFeaturesList()
        TOAST_SUCCESS(message);
      } else TOAST_ERROR(message);
    } catch (err) {
      dispatch(setLoader(false));
      TOAST_ERROR(err.message);
    }
  };

  useEffect(() => {
    isModalOpen?.data && handleMembershipFeatureDetails();
  }, [isModalOpen?.data]);

  const handleMembershipFeatureDetails = async () => {
    try {
      const { data } = await API.getMembershipFeatureDetails(isModalOpen?.data);
      const { description, feature_image_ser } = data;
      setPreviewImage(feature_image_ser);
      setFields({ description, feature_image: feature_image_ser?.split("/")[5] });
    } catch (err) {
      TOAST_ERROR(err.message);
    }
  };

  return (
    <CustomModal
      title={isModalOpen?.data?.type}
      buttonName={isModalOpen?.data ? "Update" : "Save"}
      onSave={handleAddMembershipFeature}
    >
      <div className="row mb-4">
        <label className="col-12 col-xl-4 col-sm-4 col-lg-4 col-md-4 col-form-label text-sm-start">Description</label>
        <div className="col-12 col-xl-8 col-lg-8 col-md-12 col-sm-12">
          <textarea
            name="description"
            className="form-control form-control-lg"
            type="text"
            placeholder="Enter description"
            value={fields.description}
            onChange={handleChange}
          //   disabled={isView}
          />
          {errors.description && (
            <p role="alert" className="text-danger">
              Description is required
            </p>
          )}
        </div>
      </div>
      <div className="row mb-4">
        <label className="col-12 col-xl-4 col-sm-4 col-lg-4 col-md-4 col-form-label text-sm-start">Feature Image Upload</label>
        <div className={`col-12 col-xl-8 col-lg-8 col-md-12 col-sm-12`}>
          <DropZone onDrop={(file) => handleImageChange(file)}>
            <div className="container">
              <label htmlFor="et_pb_contact_brand_file_request_0" className="et_pb_contact_form_label">
                {!fields.feature_image ? (
                  <>
                    <img src={uploadImageIcon} alt="Duotone" />
                    Enter
                    <span>Quick file uploader</span>
                    <span>Drag & Drop or choose files from computer</span>
                  </>
                ) : (
                  <>
                    <img src={previewImage} alt="Duotone" height={150} width={150} className="rounded" />
                    <span>{fields?.feature_image?.name || fields?.feature_image}</span>
                  </>
                )}
              </label>
              <input
                type="file"
                id="et_pb_contact_brand_file_request_0"
                className="file-upload"
                accept="image/*"
                onChange={(e) => handleImageChange(e.target.files[0])}
              />
            </div>
          </DropZone>
          {errors.feature_image && (
            <p role="alert" className="text-danger">
              Image is required
            </p>
          )}
        </div>
      </div>
    </CustomModal>
  );
};

export default AddMembershipFeature;
