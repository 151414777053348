import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

export default function AppSettingsReferral({ onSubmit, appSettings, isView }) {
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (appSettings) {
      const {
        bonus_for_referred_user,
        refferal,
        commission_percentage,
        cash_for_referred_user,
        cash_refferal,
      } = appSettings;
      setValue("bonus_for_referred_user", bonus_for_referred_user);
      setValue("refferal", refferal);
      setValue("commission_percentage", commission_percentage);
      setValue("cash_for_referred_user", cash_for_referred_user);
      setValue("cash_refferal", cash_refferal);
    }
  }, [appSettings, setValue]);

  return (
    <div className="row">
      <div className="col-12 col-md-6">
        <div className="portlet">
          <div className="horizontal_filter_card">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row mb-4">
                <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                  Bonus for referred user
                </label>
                <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <input
                    {...register("bonus_for_referred_user", {
                      required: "Bonus for referred user is required.",
                    })}
                    aria-invalid={
                      errors.bonus_for_referred_user ? "true" : "false"
                    }
                    className="form-control form-control-lg"
                    type="text"
                    placeholder="Enter bonus for referred user"
                    disabled={isView}
                  />
                  {errors.bonus_for_referred_user && (
                    <p role="alert" className="text-danger">
                      {errors.bonus_for_referred_user?.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                  Refferal Bonus
                </label>
                <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <input
                    {...register("refferal", {
                      required: "Referral bonus is required.",
                    })}
                    aria-invalid={errors.refferal ? "true" : "false"}
                    className="form-control form-control-lg"
                    type="text"
                    placeholder="Enter referral bonus"
                    disabled={isView}
                  />
                  {errors.refferal && (
                    <p role="alert" className="text-danger">
                      {errors.refferal?.message}
                    </p>
                  )}
                </div>
              </div>

              <div className="row mb-4">
                <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                  Commission Percentage
                </label>
                <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <input
                    {...register("commission_percentage", {
                      required: "Commission percentage is required.",
                    })}
                    aria-invalid={
                      errors.commission_percentage ? "true" : "false"
                    }
                    className="form-control form-control-lg"
                    type="text"
                    placeholder="Enter commission percentage"
                    disabled={isView}
                  />
                  {errors.commission_percentage && (
                    <p role="alert" className="text-danger">
                      {errors.commission_percentage?.message}
                    </p>
                  )}
                </div>
              </div>

              <div className="row mb-4">
                <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                  Cash for referred user
                </label>
                <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <input
                    {...register("cash_for_referred_user", {
                      required: "Cash for referred user is required.",
                    })}
                    aria-invalid={
                      errors.cash_for_referred_user ? "true" : "false"
                    }
                    className="form-control form-control-lg"
                    type="text"
                    placeholder="Enter cash for referred user"
                    disabled={isView}
                  />
                  {errors.cash_for_referred_user && (
                    <p role="alert" className="text-danger">
                      {errors.cash_for_referred_user?.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                  Cash referral
                </label>
                <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <input
                    {...register("cash_refferal", {
                      required: "Referral bonus is required.",
                    })}
                    aria-invalid={errors.cash_refferal ? "true" : "false"}
                    className="form-control form-control-lg"
                    type="text"
                    placeholder="Enter cash referral"
                    disabled={isView}
                  />
                  {errors.cash_refferal && (
                    <p role="alert" className="text-danger">
                      {errors.cash_refferal?.message}
                    </p>
                  )}
                </div>
              </div>
              {!isView && (
                <div className="row justify-content-end">
                  <div className="col-12 col-md-12">
                    <div className="text-start">
                      <button className="btn btn-primary btn-lg common_hover">
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
