import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { getGoldSubscriptionsList, setLoader, setModalStatus } from "../../store/slices/masterSlice";
import { TOAST_ERROR, TOAST_SUCCESS, formatDate } from "../../utils/common.service";
import * as API from "../../utils/api.service";
import useDebounce from "../../hooks/useDebounce";
import { ADD_MEMBERSHIP_FEATURE, DATE_DASH_TIME_FORMAT, DELETE_MODAL, SEARCH_DELAY } from "../../app.config";
import { Pagination } from "../common";
import DeleteModal from "../Modals/DeleteModal";
import AddMembershipFeature from "../Modals/AddMembershipFeature";

const GoldSubscriptions = () => {
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({ search: "", country: "" });
  const [step, setStep] = useState(1);
  const [featuresList, setFeatureList] = useState(null);
  console.log("🚀 ~ file: index.js:18 ~ GoldSubscriptions ~ featuresList:", featuresList);
  const debounce = useDebounce(filters.search, SEARCH_DELAY);
  const {
    goldSubscriptionsList: { data: goldSubscriptionsList },
    countryList: { data: countryList = [] },
    isModalOpen,
  } = useSelector((state) => state.master);
  console.log(goldSubscriptionsList,"gold");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    fetchGoldSubscriptionsList();
  }, [page, debounce, filters.country]);

  const fetchGoldSubscriptionsList = () => {
    let tempFilters = "&is_active=1";
    if (debounce) tempFilters = `${tempFilters}&search=${debounce}`;
    if (filters.country) tempFilters = `${tempFilters}&country=${filters.country}`;
    dispatch(getGoldSubscriptionsList({ page, filters: tempFilters }));
  };

  useEffect(() => {
    getMembershipFeatureList();
  }, []);

  const getMembershipFeatureList = async () => {
    try {
      dispatch(setLoader(true));
      const { code, message, data } = await API.getMembershipFeatureList(1);
      if (code === 1) {
        setFeatureList(data);
      } else TOAST_ERROR(message);
      dispatch(setLoader(false));
    } catch (err) {
      dispatch(setLoader(false));
      TOAST_ERROR(err.message);
    }
  };

  const handleDeleteSubscriptionPlan = async (id) => {
    try {
      dispatch(setLoader(true));
      const { code, message } = await API.deleteGoldSubscriptionPlan(id);
      if (code === 1) {
        TOAST_SUCCESS(message);
        fetchGoldSubscriptionsList();
      } else TOAST_ERROR(message);
      dispatch(setLoader(false));
    } catch (err) {
      dispatch(setLoader(false));
      TOAST_ERROR(err.message);
    }
  };

  const handleDeleteSubscriptionFeature = async (id) => {
    try {
      dispatch(setLoader(true));
      const { code, message } = await API.deleteMembershipFeature(id);
      if (code === 1) {
        TOAST_SUCCESS(message);
        getMembershipFeatureList();
      } else TOAST_ERROR(message);
      dispatch(setLoader(false));
    } catch (err) {
      dispatch(setLoader(false));
      TOAST_ERROR(err.message);
    }
  };

  const showCountryList = useMemo(() => {
    return countryList.map((item) => {
      return (
        <option key={item.id} value={item.value}>
          {item.label}
        </option>
      );
    });
  }, [countryList]);

  const showGoldSubscriptionsList = useMemo(() => {
    if (goldSubscriptionsList?.results?.length === 0)
      return (
        <tr>
          <td colSpan={7}>No data found</td>
        </tr>
      );
    else
      return goldSubscriptionsList?.results?.map((item) => {
        return (
          <tr key={item.id}>
            <th scope="row">
              <i
                onClick={() => navigate(`/gold-subscriptions/edit-subscription/${item.id}`)}
                className="fa fa-pencil text-primary pointer"
                aria-hidden="true"
              ></i>
              <i
                onClick={() => dispatch(setModalStatus({ modalType: DELETE_MODAL, isOpen: true, data: item.id }))}
                className="fa fa-trash text-danger mx-3 pointer"
                aria-hidden="true"
              ></i>
            </th>
            <td>{item?.price}</td>
            <td>{item?.duration}</td>
            <td>{item?.country_details?.full_name}</td>
            <td>{formatDate(item.start_time, DATE_DASH_TIME_FORMAT)}</td>
            <td>
              <Link className="btn btn-label-primary" to={`/gold-subscriptions/${item.id}`}>
                View Members
              </Link>
            </td>
          </tr>
        );
      });
  }, [goldSubscriptionsList?.results]);

  const showFeatureList = useMemo(() => {
    if (featuresList?.results?.length === 0)
      return (
        <tr>
          <td colSpan={7}>No data found</td>
        </tr>
      );
    else
      return featuresList?.results?.map((item) => {
        return (
          <tr key={item.id}>
            <td>
              <img src={item.feature_image_ser} height={30} width={30} alt="img" />
            </td>
            <td>{item?.description}</td>
            <td>{formatDate(item.insert_date, DATE_DASH_TIME_FORMAT)}</td>
            <td>
              <i
                onClick={() => dispatch(setModalStatus({ modalType: ADD_MEMBERSHIP_FEATURE, isOpen: true, data: item.id }))}
                className="fa fa-pencil text-primary pointer"
                aria-hidden="true"
              ></i>
              <i
                onClick={() => dispatch(setModalStatus({ modalType: DELETE_MODAL, isOpen: true, data: item.id }))}
                className="fa fa-trash text-danger mx-3 pointer"
                aria-hidden="true"
              ></i>
            </td>
          </tr>
        );
      });
  }, [featuresList]);

  return (
    <div className="content">
      <div className="container-fluid g-5">
        <div className="row">
          <div className="col-12">
            <div className="portlet">
              <div className="horizontal_filter_card">
                <div className="d-flex justify-content-between">
                  <div className="search_group w-25">
                    <select
                      className="form-select form-select-lg"
                      defaultValue=""
                      onChange={(e) => setFilters({ ...filters, country: e.target.value })}
                    >
                      <option value="">Select country</option>
                      {showCountryList}
                    </select>
                  </div>
                  <div className="search_button_30 text-end">
                    {step === 1 ? (
                      <Link to={"/gold-subscriptions/add-subscription"} className="btn btn-success btn-lg common_hover">
                        <div className="icon_bg_square">
                          <i className="fa fa-plus" aria-hidden="true"></i>
                        </div>
                        Add Subscription Plan
                      </Link>
                    ) : (
                      <button
                        className="btn btn-primary btn-lg common_hover"
                        onClick={() => dispatch(setModalStatus({ modalType: ADD_MEMBERSHIP_FEATURE, isOpen: true }))}
                      >
                        <div className="icon_bg_square">
                          <i className="fa fa-plus" aria-hidden="true"></i>
                        </div>
                        Add Subscription Features
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-12">
            <div className="portlet">
              <div className="portlet-header portlet-header-bordered">
                <h3 className="portlet-title">Gold Subscription {step === 1 ? " Plans" : " Features"}</h3>
                <div className="portlet-addon">
                  <div className="nav nav-lines portlet-nav" id="portlet1-tab">
                    <a className={`nav-item nav-link ${step === 1 ? "active" : ""}`} onClick={() => setStep(1)} role="button">
                      Membership Plans
                    </a>
                    <a className={`nav-item nav-link ${step === 2 ? "active" : ""}`} onClick={() => setStep(2)} role="button">
                      Membership Features
                    </a>
                  </div>
                </div>
              </div>
              {step === 1 ? (
                <>
                  <div className="horizontal_filter_card">
                    <div className="table-responsive table-responsive-lg">
                      <table className="table mb-0">
                        <thead>
                          <tr>
                            <th>Action</th>
                            <th>Price</th>
                            <th>Duration (in months) </th>
                            <th>Country</th>
                            <th>Date & Time</th>
                            <th>View gold members</th>
                          </tr>
                        </thead>
                        <tbody className="table-group-divider">{showGoldSubscriptionsList}</tbody>
                      </table>
                    </div>
                  </div>
                  <Pagination handlePageChange={(count) => setPage(count)} page={page} totalCount={goldSubscriptionsList?.count} />
                </>
              ) : (
                <>
                  <div className="horizontal_filter_card">
                    <div className="table-responsive table-responsive-lg">
                      <table className="table mb-0">
                        <thead>
                          <tr>
                            <th>Image</th>
                            <th>Description</th>
                            <th>Date & Time</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody className="table-group-divider">{showFeatureList}</tbody>
                      </table>
                    </div>
                  </div>
                  <Pagination handlePageChange={(count) => setPage(count)} page={page} totalCount={showFeatureList?.count} />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {isModalOpen.modalType === DELETE_MODAL && (
        <DeleteModal handleDelete={step === 1 ? handleDeleteSubscriptionPlan : handleDeleteSubscriptionFeature} />
      )}
      {isModalOpen.modalType === ADD_MEMBERSHIP_FEATURE && <AddMembershipFeature handleFeaturesList={getMembershipFeatureList} />}
    </div>
  );
};

export default GoldSubscriptions;
