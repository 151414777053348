import React from "react";
import { Link, useLocation, useParams } from "react-router-dom";

export default function RecentActivityHeader({ handleQueryChange }) {
  const location = useLocation();
  const path = location.search.split("=")[1];
  const { id: userId } = useParams();

  return (
    <div className="portlet-header portlet-header-bordered">
      {/* <h3 className="portlet-title">Sep 21, 2022</h3> */}
      <div className="portlet-addon">
        <div className="nav nav-lines portlet-nav" id="portlet1-tab">
          <Link
            className={`nav-item nav-link ${path === "today" ? "active" : ""}`}
            to={`${location.pathname}?recent_activity_filter=today`}
            onClick={handleQueryChange}
          >
            Today
          </Link>
          <Link
            className={`nav-item nav-link ${
              path === "this_week" ? "active" : ""
            }`}
            to={`${location.pathname}?recent_activity_filter=this_week`}
            onClick={handleQueryChange}
          >
            This Week
          </Link>
          <Link
            className={`nav-item nav-link ${
              path === "this_month" ? "active" : ""
            }`}
            to={`${location.pathname}?recent_activity_filter=this_month`}
            onClick={handleQueryChange}
          >
            This Month
          </Link>
          <Link
            className={`nav-item nav-link ${
              path === "this_year" ? "active" : ""
            }`}
            to={`${location.pathname}?recent_activity_filter=this_year`}
            onClick={handleQueryChange}
          >
            This Year
          </Link>
          <Link
            className={`nav-item nav-link ${
              path === "last_year" ? "active" : ""
            }`}
            to={`${location.pathname}?recent_activity_filter=last_year`}
            onClick={handleQueryChange}
          >
            Last Year
          </Link>
        </div>
      </div>
    </div>
  );
}
