import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

export default function AppSettingsReportLimit({
  onSubmit,
  appSettings,
  isView,
}) {
    const {
      handleSubmit,
      register,
      setValue,
      formState: { errors },
    } = useForm();

    useEffect(() => {
      if (appSettings) {
        const {
            post_block_count,
            video_post_block_count,
            image_post_block_count,
            comment_in_post_block_count,
            world_chat_block_count,
            group_chat_block_count,
            personal_chat_block_count,
            tournament_report_block_count,
            total_report_count
        } = appSettings;
        setValue("post_block_count", post_block_count);
        setValue("video_post_block_count", video_post_block_count);
        setValue("image_post_block_count", image_post_block_count);
        setValue("comment_in_post_block_count", comment_in_post_block_count);
        setValue("world_chat_block_count", world_chat_block_count);
        setValue("group_chat_block_count", group_chat_block_count);
        setValue("personal_chat_block_count", personal_chat_block_count);
        setValue("tournament_report_block_count", tournament_report_block_count);
        setValue("total_report_count", total_report_count);
      }
    }, [appSettings, setValue]);
  
    return (
      <div className="row">
        <div className="col-md-12 col-sm-6">
          <div className="portlet">
            <div className="horizontal_filter_card">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row mb-4">
                    <div className="col-md-6 col-sm-12">
                        <div className="row mb-4">
                            <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                                Post Report Max Limit (in Num.)
                            </label>
                            <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <input
                                {...register("post_block_count", {
                                    required: "Field is required.",
                                    pattern: {
                                        value: /^[0-9]+$/,
                                        message: "Must be a number.",
                                    },
                                })}
                                aria-invalid={
                                    errors.post_block_count ? "true" : "false"
                                }
                                className="form-control form-control-lg"
                                type="text"
                                // placeholder="5"
                                disabled={isView}
                                />
                                {errors.post_block_count && (
                                <p role="alert" className="text-danger">
                                    {errors.post_block_count?.message}
                                </p>
                                )}
                            </div>
                        </div>
                        <div className="row mb-4">
                            <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                                Video Post Report Max Limit (in Num.)
                            </label>
                            <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <input
                                {...register("video_post_block_count", {
                                    required: "Field is required.",
                                    pattern: {
                                        value: /^[0-9]+$/,
                                        message: "Must be a number.",
                                    },
                                })}
                                aria-invalid={
                                    errors.video_post_block_count
                                    ? "true"
                                    : "false"
                                }
                                className="form-control form-control-lg"
                                type="text"
                                // placeholder="5"
                                disabled={isView}
                                />
                                {errors.video_post_block_count && (
                                <p role="alert" className="text-danger">
                                    {errors.video_post_block_count?.message}
                                </p>
                                )}
                            </div>
                        </div>
                        <div className="row mb-4">
                            <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                                Image Post Report Max Limit (in Num.)
                            </label>
                            <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <input
                                {...register("image_post_block_count", {
                                    required: "Field is required.",
                                    pattern: {
                                        value: /^[0-9]+$/,
                                        message: "Must be a number.",
                                    },
                                })}
                                aria-invalid={
                                    errors.image_post_block_count ? "true" : "false"
                                }
                                className="form-control form-control-lg"
                                type="text"
                                // placeholder="4"
                                disabled={isView}
                                />
                                {errors.image_post_block_count && (
                                <p role="alert" className="text-danger">
                                    {errors.image_post_block_count?.message}
                                </p>
                                )}
                            </div>
                        </div>
                        <div className="row mb-4">
                            <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                                Comment In Post Report Max Limit (in Num.)
                            </label>
                            <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <input
                                {...register("comment_in_post_block_count", {
                                    required: "Field is required.",
                                    pattern: {
                                        value: /^[0-9]+$/,
                                        message: "Must be a number.",
                                    },
                                })}
                                aria-invalid={
                                    errors.comment_in_post_block_count ? "true" : "false"
                                }
                                className="form-control form-control-lg"
                                type="text"
                                // placeholder="7"
                                disabled={isView}
                                />
                                {errors.comment_in_post_block_count && (
                                <p role="alert" className="text-danger">
                                    {errors.comment_in_post_block_count?.message}
                                </p>
                                )}
                            </div>
                        </div>
                        <div className="row mb-4">
                            <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                                World Chat Report Max Limit (in Num.)
                            </label>
                            <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <input
                                {...register("world_chat_block_count", {
                                    required: "Field is required.",
                                    pattern: {
                                        value: /^[0-9]+$/,
                                        message: "Must be a number.",
                                    },
                                })}
                                aria-invalid={
                                    errors.world_chat_block_count ? "true" : "false"
                                }
                                className="form-control form-control-lg"
                                type="text"
                                // placeholder="6"
                                disabled={isView}
                                />
                                {errors.world_chat_block_count && (
                                <p role="alert" className="text-danger">
                                    {errors.world_chat_block_count?.message}
                                </p>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <div className="row mb-4">
                            <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                                Group Chat Report Max Limit (in Num.)
                            </label>
                            <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <input
                                {...register("group_chat_block_count", {
                                    required: "Field is required.",
                                    pattern: {
                                        value: /^[0-9]+$/,
                                        message: "Must be a number.",
                                    },
                                })}
                                aria-invalid={
                                    errors.group_chat_block_count ? "true" : "false"
                                }
                                className="form-control form-control-lg"
                                type="text"
                                // placeholder="8"
                                disabled={isView}
                                />
                                {errors.group_chat_block_count && (
                                <p role="alert" className="text-danger">
                                    {errors.group_chat_block_count?.message}
                                </p>
                                )}
                            </div>
                        </div>
                        <div className="row mb-4">
                            <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                                Personal Chat Report Max Limit (in Num.)
                            </label>
                            <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <input
                                {...register("personal_chat_block_count", {
                                    required: "Field is required.",
                                    pattern: {
                                        value: /^[0-9]+$/,
                                        message: "Must be a number.",
                                    },
                                })}
                                aria-invalid={
                                    errors.personal_chat_block_count ? "true" : "false"
                                }
                                className="form-control form-control-lg"
                                type="text"
                                // placeholder="8"
                                disabled={isView}
                                />
                                {errors.personal_chat_block_count && (
                                <p role="alert" className="text-danger">
                                    {errors.personal_chat_block_count?.message}
                                </p>
                                )}
                            </div>
                        </div>
                        <div className="row mb-4">
                            <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                                Tournament Report Max Limit (in Num.)
                            </label>
                            <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <input
                                {...register("tournament_report_block_count", {
                                    required: "Field is required.",
                                    pattern: {
                                        value: /^[0-9]+$/,
                                        message: "Must be a number.",
                                    },
                                })}
                                aria-invalid={
                                    errors.tournament_report_block_count ? "true" : "false"
                                }
                                className="form-control form-control-lg"
                                type="text"
                                // placeholder="5"
                                disabled={isView}
                                />
                                {errors.tournament_report_block_count && (
                                <p role="alert" className="text-danger">
                                    {errors.tournament_report_block_count?.message}
                                </p>
                                )}
                            </div>
                        </div>
                        <div className="row mb-4">
                            <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                                Total Report Max Limit (in Num.)
                            </label>
                            <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <input
                                {...register("total_report_count", {
                                    required: "Field is required.",
                                    pattern: {
                                        value: /^[0-9]+$/,
                                        message: "Must be a number.",
                                    },
                                })}
                                aria-invalid={
                                    errors.total_report_count ? "true" : "false"
                                }
                                className="form-control form-control-lg"
                                type="text"
                                // placeholder="12"
                                disabled={isView}
                                />
                                {errors.total_report_count && (
                                <p role="alert" className="text-danger">
                                    {errors.total_report_count?.message}
                                </p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
  
                {!isView && (
                  <div className="row justify-content-end">
                    <div className="col-12 col-md-12">
                      <div className="text-start">
                        <button className="btn btn-primary btn-lg common_hover">
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    );
}