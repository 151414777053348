import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "../../utils/api.service";
import { setLoader } from "./masterSlice";

export const getReasonList = createAsyncThunk(
  "reasonList",
  async ({ page, filters }, { dispatch }) => {
    try {
      dispatch(setLoader(true));
      const { data: reasonList } = await API.getReasonList(page, filters);
      dispatch(setLoader(false));
      return reasonList;
    } catch (error) {
      dispatch(setLoader(false));
      throw error;
    }
  }
);

export const getReasonTypeList = createAsyncThunk(
  "reasonTypeList",
  async (_, { dispatch }) => {
    try {
      dispatch(setLoader(true));
      let {
        data: {
          actions: {
            POST: {
              type: { choices: reasonTypeList },
            },
          },
        },
      } = await API.getReasonTypeList();
      reasonTypeList = reasonTypeList?.map((item) => ({
        ...item,
        label: item.display_name,
      }));
      dispatch(setLoader(false));
      return reasonTypeList;
    } catch (error) {
      dispatch(setLoader(false));
      throw error;
    }
  }
);

const initialState = {
  reasonList: {
    data: [],
    error: null,
  },
  reasonTypeList: {
    data: [],
    error: null,
  },
};

const reasonSlice = createSlice({
  name: "REASON",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getReasonList.fulfilled, (state, action) => {
        state.reasonList.data = action.payload;
      })
      .addCase(getReasonList.rejected, (state, action) => {
        state.reasonList.error = action.error.message;
      })
      .addCase(getReasonTypeList.fulfilled, (state, action) => {
        state.reasonTypeList.data = action.payload;
      })
      .addCase(getReasonTypeList.rejected, (state, action) => {
        state.reasonTypeList.error = action.error.message;
      });
  },
});

export default reasonSlice.reducer;
