import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "../../utils/api.service";
import { setLoader } from "./masterSlice";

export const getUsersList = createAsyncThunk("userList", async ({ page, filters }, { dispatch, getState }) => {
  try {
    // dispatch(setLoader(true));
    // const {
    //   user: {
    //     userList: { data: { results: currentUserList = [] } = {} },
    //   },
    // } = getState();
    // userList = {
    //   ...userList,
    //   results: [
    //     ...currentUserList,
    //     ...userList?.results.map((item) => ({
    //       ...item,
    //       value: item.id,
    //       label: item.name,
    //     })),
    //   ],
    // };
    let { data: userList } = await API.getUsersList(page, filters);
    userList = {
      ...userList,
      results: [
        ...userList?.results.map((item) => ({
          ...item,
          value: item.id,
          label: item.name,
        })),
      ],
    };
    // dispatch(setLoader(false));
    return userList;
  } catch (error) {
    // dispatch(setLoader(false));
    throw error;
  }
});

export const getUserPostList = createAsyncThunk("userPostList", async ({ id, page }, { dispatch }) => {
  try {
    dispatch(setLoader(true));
    const { data: userPostList } = await API.getUserPostList(id, page);
    dispatch(setLoader(false));
    return userPostList;
  } catch (error) {
    dispatch(setLoader(false));
    throw error;
  }
});

export const getUserReportList = createAsyncThunk("userReportList", async ({ page, filters }, { dispatch }) => {
  try {
    dispatch(setLoader(true));
    const { data: userReportList } = await API.getUserReportList(page, filters);
    dispatch(setLoader(false));
    return userReportList;
  } catch (error) {
    dispatch(setLoader(false));
    throw error;
  }
});

export const getUserChatReportList = createAsyncThunk("userChatReportList", async ({ page, filters }, { dispatch }) => {
  try {
    dispatch(setLoader(true));
    const { data: userChatReportList } = await API.getUserChatReportList(page, filters);
    dispatch(setLoader(false));
    return userChatReportList;
  } catch (error) {
    dispatch(setLoader(false));
    throw error;
  }
});

export const getUserCardList = createAsyncThunk("userCardList", async (userId, { dispatch }) => {
  try {
    dispatch(setLoader(true));
    const { data: userCardList } = await API.getUserCardList(userId);
    dispatch(setLoader(false));
    return userCardList;
  } catch (error) {
    dispatch(setLoader(false));
    throw error;
  }
});

export const getVerificationBadgeList = createAsyncThunk(
  "verificationBadgeList",
  async ({ page, filters }, { dispatch }) => {
    try {
      dispatch(setLoader(true));
      const { data: verificationBadgeList } = await API.getVerificationBadgeList(page, filters);
      dispatch(setLoader(false));
      return verificationBadgeList;
    } catch (error) {
      dispatch(setLoader(false));
      throw error;
    }
  }
);

export const getUserKycList = createAsyncThunk("userKycList", async ({ page, filters }, { dispatch }) => {
  try {
    dispatch(setLoader(true));
    const { data: userKycList } = await API.getUserKycList(page, filters);
    dispatch(setLoader(false));
    return userKycList;
  } catch (error) {
    dispatch(setLoader(false));
    throw error;
  }
});

const initialState = {
  userList: {
    data: {},
    error: null,
  },
  userPostList: {
    data: [],
    error: null,
  },
  userReportList: {
    data: {},
    error: null,
  },
  userChatReportList: {
    data: {},
    error: null,
  },
  userCardList: {
    data: [],
    error: null,
  },
  verificationBadgeList: {
    data: {},
    error: null,
  },
  userKycList: {
    data: {},
    error: null,
  },
};

const userSlice = createSlice({
  name: "USER",
  initialState,
  reducers: {
    setUserReportList: (state) => {
      state.userReportList.data = [];
    },
    setUserList: (state) => {
      state.userList.data = {};
    },
    setUserPostList: (state) => {
      state.userPostList.data = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsersList.fulfilled, (state, action) => {
        state.userList.data = action.payload;
      })
      .addCase(getUsersList.rejected, (state, action) => {
        state.userList.error = action.error.message;
      })
      .addCase(getUserPostList.fulfilled, (state, action) => {
        state.userPostList.data = action.payload;
      })
      .addCase(getUserPostList.rejected, (state, action) => {
        state.userPostList.error = action.error.message;
      })
      .addCase(getUserReportList.fulfilled, (state, action) => {
        state.userReportList.data = action.payload;
      })
      .addCase(getUserReportList.rejected, (state, action) => {
        state.userReportList.error = action.error.message;
      })
      .addCase(getUserChatReportList.fulfilled, (state, action) => {
        state.userChatReportList.data = action.payload;
      })
      .addCase(getUserChatReportList.rejected, (state, action) => {
        state.userChatReportList.error = action.error.message;
      })
      .addCase(getUserCardList.fulfilled, (state, action) => {
        state.userCardList.data = action.payload;
      })
      .addCase(getUserCardList.rejected, (state, action) => {
        state.userCardList.error = action.error.message;
      })
      .addCase(getVerificationBadgeList.fulfilled, (state, action) => {
        state.verificationBadgeList.data = action.payload;
      })
      .addCase(getVerificationBadgeList.rejected, (state, action) => {
        state.verificationBadgeList.error = action.error.message;
      })
      .addCase(getUserKycList.fulfilled, (state, action) => {
        state.userKycList.data = action.payload;
      })
      .addCase(getUserKycList.rejected, (state, action) => {
        state.userKycList.error = action.error.message;
      });
  },
});

export const { setUserList, setUserPostList } = userSlice.actions;
export default userSlice.reducer;
