export const AWS_BASE_URL = process.env.REACT_APP_AWS_BASE_URL;
export const AWS_S3_CUSTOM_DOMAIN = process.env.REACT_APP_AWS_S3_CUSTOM_DOMAIN;
export const AWS_STORAGE_BUCKET_NAME =
  process.env.REACT_APP_AWS_STORAGE_BUCKET_NAME;
export const AWS_ACCESS_KEY_ID = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
export const AWS_SECRET_ACCESS_KEY =
  process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
export const AWS_S3_REGION_NAME = process.env.REACT_APP_AWS_S3_REGION_NAME;

export const API_KEY = process.env.REACT_APP_API_KEY;
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const SOCKET_URL = "http://3.108.177.9:8005/";
// 3.108.177.9:8005
// 13.126.37.132:8005

export const COUNTRY_LIST = [
  {
    label: "Alaskan/Hawaiian Time Zone",
    options: [
      { value: "AK", label: "Alaska" },
      { value: "HI", label: "Hawaii" },
    ],
  },
  {
    label: "Pacific Time Zone",
    options: [
      { value: "CA", label: "California" },
      { value: "NV", label: "Nevada" },
      { value: "OR", label: "Oregon" },
      { value: "WA", label: "Washington" },
    ],
  },
  {
    label: "Mountain Time Zone",
    options: [
      { value: "AZ", label: "Arizona" },
      { value: "CO", label: "Colorado" },
      { value: "ID", label: "Idaho" },
      { value: "MT", label: "Montana" },
      { value: "NE", label: "Nebraska" },
      { value: "NM", label: "New Mexico" },
      { value: "ND", label: "North Dakota" },
      { value: "UT", label: "Utah" },
      { value: "WY", label: "Wyoming" },
    ],
  },
  {
    label: "Central Time Zone",
    options: [
      { value: "AL", label: "Alabama" },
      { value: "AR", label: "Arkansas" },
      { value: "IL", label: "Illinois" },
      { value: "IA", label: "Iowa" },
      { value: "KS", label: "Kansas" },
      { value: "KY", label: "Kentucky" },
      { value: "LA", label: "Louisiana" },
      { value: "MN", label: "Minnesota" },
      { value: "MS", label: "Mississippi" },
      { value: "MO", label: "Missouri" },
      { value: "OK", label: "Oklahoma" },
      { value: "SD", label: "South Dakota" },
      { value: "TX", label: "Texas" },
      { value: "TN", label: "Tennessee" },
      { value: "WI", label: "Wisconsin" },
    ],
  },
  {
    label: "Eastern Time Zone",
    options: [
      { value: "CT", label: "Connecticut" },
      { value: "DE", label: "Delaware" },
      { value: "FL", label: "Florida" },
      { value: "GA", label: "Georgia" },
      { value: "IN", label: "Indiana" },
      { value: "ME", label: "Maine" },
      { value: "MD", label: "Maryland" },
      { value: "MA", label: "Massachusetts" },
      { value: "MI", label: "Michigan" },
      { value: "NH", label: "New Hampshire" },
      { value: "NJ", label: "New Jersey" },
      { value: "NY", label: "New York" },
      { value: "NC", label: "North Carolina" },
      { value: "OH", label: "Ohio" },
      { value: "PA", label: "Pennsylvania" },
      { value: "RI", label: "Rhode Island" },
      { value: "SC", label: "South Carolina" },
      { value: "VT", label: "Vermont" },
      { value: "VA", label: "Virginia" },
      { value: "WV", label: "West Virginia" },
    ],
  },
];

export const INDIAN_STATE_LIST = [
  { id: 1, name: "Andaman and Nicobar Islands" },
  { id: 2, name: "Andhra Pradesh" },
  { id: 3, name: "Arunachal Pradesh" },
  { id: 4, name: "Assam" },
  { id: 5, name: "Bihar" },
  { id: 6, name: "Chandigarh" },
  { id: 7, name: "Chhattisgarh" },
  { id: 8, name: "Dadra and Nagar Haveli and Daman and Diu" },
  { id: 9, name: "Delhi" },
  { id: 10, name: "Goa" },
  { id: 11, name: "Gujarat" },
  { id: 12, name: "Haryana" },
  { id: 13, name: "Himachal Pradesh" },
  { id: 14, name: "Jharkhand" },
  { id: 15, name: "Karnataka" },
  { id: 16, name: "Kerala" },
  { id: 17, name: "Ladakh" },
  { id: 18, name: "Lakshadweep" },
  { id: 19, name: "Madhya Pradesh" },
  { id: 20, name: "Maharashtra" },
  { id: 21, name: "Manipur" },
  { id: 22, name: "Meghalaya" },
  { id: 23, name: "Mizoram" },
  { id: 24, name: "Nagaland" },
  { id: 25, name: "Odisha" },
  { id: 26, name: "Puducherry" },
  { id: 27, name: "Punjab" },
  { id: 28, name: "Rajasthan" },
  { id: 29, name: "Sikkim" },
  { id: 30, name: "Tamil Nadu" },
  { id: 31, name: "Telangana" },
  { id: 32, name: "Tripura" },
  { id: 33, name: "Uttar Pradesh" },
  { id: 34, name: "Uttarakhand" },
  { id: 35, name: "West Bengal" },
];

export const ALPHABETS = Array.from({ length: 26 }, (_, i) =>
  String.fromCharCode(65 + i)
);

export const STATUS_CLASS = {
  pending: "warning",
  ongoing: "primary",
  room_started: "primary",
  winnings_calculating: "info",
  auto_cancel: "danger",
  auto_rejected: "danger",
  cancel: "danger",
  completed: "success",
  approved: "success",
  rejected: "danger",
  stop: "danger",
  Escalate: "danger",
  escalate: "danger",
  delivered: "success",
  dispatch: "info",
  resolved: "success",
  success: "success",
  failed: "failed",
  verified: "success",
  verify: "primary",
  request: "warning",
  accept: "success",
  blocked: "danger",
  accepted: "success",
};

// MODAL TYPES
export const COUNTRY_MODAL = "COUNTRY_MODAL";
export const GAME_TYPE_MODAL = "GAME_TYPE_MODAL";
export const TOURNAMENT_TYPE_MODAL = "TOURNAMENT_TYPE_MODAL";
export const DEVICE_BANNED = "DEVICE_BANNED";
export const IP_BANNED = "IP_BANNED";
export const REASON_FOR_DEACTIVE = "REASON_FOR_DEACTIVE";
export const REASON_FOR_ACTIVE = "REASON_FOR_ACTIVE";
export const EDIT_CASH_BALANCE = "EDIT_CASH_BALANCE";
export const EDIT_WINNING_BALANCE = "EDIT_WINNING_BALANCE";
export const EDIT_BONUS_BALANCE = "EDIT_BONUS_BALANCE";
export const EDIT_TOTAL_WIN_BALANCE = "EDIT_TOTAL_WIN_BALANCE";
export const EDIT_TOPUP_BALANCE = "EDIT_TOPUP_BALANCE";
export const ADD_CARD = "ADD_CARD";
export const EDIT_CARD = "EDIT_CARD";
export const DELETE_MODAL = "DELETE_MODAL";
export const EDIT_POST = "EDIT_POST";
export const EDIT_SPOTLIGHT_MESSAGE = "EDIT_SPOTLIGHT_MESSAGE";
export const ADD_GROUP = "ADD_GROUP";
export const SCHEDULE_EMAIL_TO_USER = "SCHEDULE_EMAIL_TO_USER";
export const SCHEDULE_NOTIFICATION_TO_USER = "SCHEDULE_NOTIFICATION_TO_USER";
export const ADD_SPOTLIGHT = "ADD_SPOTLIGHT";
export const ADD_CATEGORY = "ADD_CATEGORY";
export const UPDATE_ORDER = "UPDATE_ORDER";
export const UPDATE_PRO_VERIFICATION_REQUEST =
  "UPDATE_PRO_VERIFICATION_REQUEST";
export const UPDATE_TICKET = "UPDATE_TICKET";
export const ACCEPT_CHAT_SUPPORT = "ACCEPT_CHAT_SUPPORT";
export const CHAT_GROUP_MODAL = "CHAT_GROUP_MODAL";
export const ADD_MEMBERSHIP_FEATURE = "ADD_MEMBERSHIP_FEATURE";

// HTTPS requests
export const GET = "get";
export const POST = "post";
export const PUT = "put";
export const PATCH = "patch";
export const DELETE = "delete";
export const OPTION = "options";

//constants
export const ADMIN = "admin";
export const GAMES = "games";
export const DATE_FORMAT = "YYYY-MM-DD";
export const ABBREVIATED_DATE_FORMAT = "D MMM";
export const ABBREVIATED_FULL_DATE_FORMAT = "D MMM YYYY";
export const DATE_TIME_FORMAT = "DD MMM YYYY, hh:mm A";
export const DATE_TIME_MONTHE_WISE_FORMAT = "MMM DD YYYY, hh:mm A";
export const DATE_DASH_TIME_FORMAT = "DD-MM-YYYY h:mm A";
export const DATE_YEAR_WISE_DASH_TIME_FORMAT = "YYYY-MM-DD h:mm A";
export const DATE_LOCAL_DASH_TIME_FORMAT = "YYYY-MM-DDTHH:mm";
export const DATE_DOT_TIME_FORMAT = "DD.MM.YYYY H:mm";
export const DATE_SLASH_FORMAT = "DD/MM/YYYY";
export const TIME_FORMAT = "hh:mm A";
export const TIME_FORMAT_24 = "HH:mm";
export const SEARCH_DELAY = 1000;
export const ITEM_PER_PAGE = 11;
export const COUNT_PER_PAGE = 12;
