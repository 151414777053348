import verifiedBadge from "../../../assets/images/site_icons/verified_badge.svg";
import userSvg from "../../../assets/images/site_icons/user_1.svg";
import callSvg from "../../../assets/images/site_icons/call_1.svg";
import emailSvg from "../../../assets/images/site_icons/email_1.svg";

const UserDetailsCard = ({ userData, isView }) => {
  return (
    <div className="row">
      <div className="col-12 col-xl-2 col-lg-2 col-md-2">
        <div className="user_active_container">
          <div className="image_storage_2 position-relative">
            <img src={userData?.profile_image} alt="profile_image" />
          </div>
          <div className="user_status_block">
            <i className="fa fa-circle active" aria-hidden="true"></i>
          </div>
        </div>
      </div>
      <div className="col-12 col-xl-10 col-lg-10 col-md-10">
        <div className="row">
          <div className="col-12 col-xl-8 col-lg-8 col-md-8">
            <div className="car_detail_box">
              <div className="header_name_block">
                <h5>
                  {userData?.name || "N/A" + " "}
                  {userData?.verified === "verified" && <img src={verifiedBadge} alt="images" />}
                </h5>
              </div>
              <div className="user_info_container">
                {userData?.username && (
                  <span className="text_secondary">
                    <img src={userSvg} alt="images" /> {userData?.username || "N/A"}
                  </span>
                )}
                <span className="text_secondary">
                  <img src={callSvg} alt="images" /> {userData?.phone}
                </span>
                <span className="text_secondary">
                  <img src={emailSvg} alt="images" /> {userData?.email}
                </span>
                <span className="text_secondary">
                  <button className="btn btn-label-success common_hover ms-lg-2">
                    {userData?.is_active ? "Active" : "Inactive"}
                  </button>
                </span>
              </div>
              <div className="tr_felx_block">
                <div className="tr_border_block fixed_width_18">
                  <h4>₹{userData?.total_balance}</h4>
                  <span>Total Earnings</span>
                </div>
                <div className="tr_border_block fixed_width_18">
                  <h4>{userData?.followers}</h4>
                  <span>Followers</span>
                </div>
                <div className="tr_border_block fixed_width_18">
                  <h4>{userData?.followings}</h4>
                  <span>Following</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-xl-4 col-lg-4 col-md-4">
            <div className="custom_flex_flow">
              <div className="text-end">
                {!isView && (
                  <>
                    <button className="btn btn-label-danger btn-lg common_hover" type="button">
                      Delete
                    </button>
                    <button className="btn btn-primary btn-lg common_hover">Save Change</button>
                  </>
                )}
              </div>
              <div className="text-end">
                <div className="d-flex justify-content-lg-between progress-bar-text">
                  <span>Profile Status</span>
                  <span className="text-dark fw-bold">{userData?.profile_complete}%</span>
                </div>
                <div className="progress progress-sm">
                  <div className="progress-bar" style={{ width: `${userData?.profile_complete}%` }}></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDetailsCard;
