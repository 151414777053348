import { Link, useLocation } from "react-router-dom";
import { capitalizeWords } from "../../../utils/common.service";
// import { logDOM } from "@testing-library/react";

const Breadcrumb = () => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  const searchParams = new URLSearchParams(location.search);
  const is_active = searchParams.get("is_active");
  const tournament_type = searchParams.get("tournament_type");
  const tournament = searchParams.get("tournament");
  const status = searchParams.get("status");
  const badage_request = searchParams.get("badage_request");
  const is_gold_membership = searchParams.get("is_gold_membership");
  const block = searchParams.get("block");
  const is_surveillance = searchParams.get("is_surveillance");
  const all_matches = pathnames[1];
  const edit_event = pathnames[1];
  const edit_advertisement = pathnames[1];
  const edit_banner = pathnames[1];
  const view_game = pathnames[1];
  const view_tournament = pathnames[1];
  const edit_product = pathnames[1];
  const edit_user_details = pathnames[1];
  const edit_sub_admin = pathnames[1];
  const edit_post = pathnames[1];
  const edit_group = pathnames[2];
  const chat_group = pathnames[1];
  const group_details = pathnames[2];
  const payment_details = pathnames[1];
  const edit_email_marketing = pathnames[1];
  const edit_story = pathnames[1];
  const report_details = pathnames[1];
  const view_settings = pathnames[1];
  const edit_push_notifications = pathnames[1];
  const view_contactus = pathnames[1];
  const view_nonwithdrawn = pathnames[1];

  const showBreadcrumbs = pathnames.map((path, index) => {
    let url = ``;
    let breadcrumbText = "";
  
    switch (true) {
      case is_active === "1" || is_active === "0":
        breadcrumbText = is_active === "1" ? ` Active ${capitalizeWords(path)}` : ` InActive ${capitalizeWords(path)}`;
        url = `/${pathnames.slice(0, index + 1).join("/").concat(`?${is_active === "1" ? 'is_active=1' : 'is_active=0'}`)}`
        path = path.concat(`/${breadcrumbText}`);
        break;
      case tournament_type === "Featured" || tournament_type === "Daily":
        breadcrumbText = tournament_type === "Featured" ? ` Featured ${capitalizeWords(path)}` : ` Daily ${capitalizeWords(path)}`;
        url = `/${pathnames.slice(0, index + 1).join("/").concat(`?${tournament_type === "Featured" ? 'tournament_type=Featured' : 'tournament_type=Daily'}`)}`
        path = path.concat(`/${breadcrumbText}`);
        break;
      case tournament === "pending":
        breadcrumbText = ` Upcoming ${capitalizeWords(path)}`;
        // url = `/${pathnames.slice(0, index + 1).join("/").concat(`?tournament=pending`)}`
        url = `/${pathnames[0].concat(`?tournament=pending`)}`
        path = path.concat(`/${breadcrumbText}`);
        break;
      case tournament === "ongoing":
        breadcrumbText = ` Ongoing ${capitalizeWords(path)}`;
        // url = `/${pathnames.slice(0, index + 1).join("/").concat(`?tournament=ongoing`)}`
        url = `/${pathnames[0].concat(`?tournament=ongoing`)}`
        path = path.concat(`/${breadcrumbText}`);
        break;
      case tournament === "cancel":
        breadcrumbText = ` Cancel ${capitalizeWords(path)}`;
        // url = `/${pathnames.slice(0, index + 1).join("/").concat(`?tournament=cancel`)}`
        url = `/${pathnames[0].concat(`?tournament=cancel`)}`
        path = path.concat(`/${breadcrumbText}`);
        break;
      case tournament === "completed":
        breadcrumbText = ` Complete ${capitalizeWords(path)}`;
        // url = `/${pathnames.slice(0, index + 1).join("/").concat(`?tournament=completed`)}`
        url = `/${pathnames[0].concat(`?tournament=completed`)}`
        path = path.concat(`/${breadcrumbText}`);
        break;
      case status === "pending":
        breadcrumbText = ` Pending ${capitalizeWords(path)}`;
        url = `/${pathnames.slice(0, index + 1).join("/").concat(`?status=pending`)}`
        path = path.concat(`/${breadcrumbText}`);
        break;
      case status === "accepted":
        breadcrumbText = ` Accepted ${capitalizeWords(path)}`;
        url = `/${pathnames.slice(0, index + 1).join("/").concat(`?status=accepted`)}`
        path = path.concat(`/${breadcrumbText}`);
        break;
      case status === "dispatch":
        breadcrumbText = ` Dispatch ${capitalizeWords(path)}`;
        url = `/${pathnames.slice(0, index + 1).join("/").concat(`?status=dispatch`)}`
        path = path.concat(`/${breadcrumbText}`);
        break;
      case status === "delivered":
        breadcrumbText = ` Delivered ${capitalizeWords(path)}`;
        url = `/${pathnames.slice(0, index + 1).join("/").concat(`?status=delivered`)}`
        path = path.concat(`/${breadcrumbText}`);
        break;
      case status === "rejected":
        breadcrumbText = ` Rejected ${capitalizeWords(path)}`;
        url = `/${pathnames.slice(0, index + 1).join("/").concat(`?status=rejected`)}`
        path = path.concat(`/${breadcrumbText}`);
        break;
      case status === "approved":
        breadcrumbText = ` Approved ${capitalizeWords(path)}`;
        url = `/${pathnames.slice(0, index + 1).join("/").concat(`?status=approved`)}`
        path = path.concat(`/${breadcrumbText}`);
        break;
      case status === "ongoing":
        breadcrumbText = ` Ongoing ${capitalizeWords(path)}`;
        url = `/${pathnames.slice(0, index + 1).join("/").concat(`?status=ongoing`)}`
        path = path.concat(`/${breadcrumbText}`);
        break;
      case status === "stop":
        breadcrumbText = ` Stop ${capitalizeWords(path)}`;
        url = `/${pathnames.slice(0, index + 1).join("/").concat(`?status=stop`)}`
        path = path.concat(`/${breadcrumbText}`);
        break;
      case status === "completed":
        breadcrumbText = ` Completed ${capitalizeWords(path)}`;
        url = `/${pathnames.slice(0, index + 1).join("/").concat(`?status=completed`)}`
        path = path.concat(`/${breadcrumbText}`);
        break;
      case badage_request === "accept":
        breadcrumbText = ` Pro ${capitalizeWords(path)}`;
        url = `/${pathnames.slice(0, index + 1).join("/").concat(`?badage_request=accept`)}`
        path = path.concat(`/${breadcrumbText}`);
        break;
      case is_gold_membership === "1":
        breadcrumbText = ` Gold ${capitalizeWords(path)}`;
        url = `/${pathnames.slice(0, index + 1).join("/").concat(`?is_gold_membership=1`)}`
        path = path.concat(`/${breadcrumbText}`);
        break;
      case block === "1":
        breadcrumbText = ` Block ${capitalizeWords(path)}`;
        url = `/${pathnames.slice(0, index + 1).join("/").concat(`?block=1`)}`
        path = path.concat(`/${breadcrumbText}`);
        break;
      case is_surveillance === "1":
        breadcrumbText = ` Surveillance ${capitalizeWords(path)}`;
        url = `/${pathnames.slice(0, index + 1).join("/").concat(`?is_surveillance=1`)}`
        path = path.concat(`/${breadcrumbText}`);
        break;
      case all_matches === "all-matches":
        // breadcrumbText = `${capitalizeWords(path)}`;
        url = `/${pathnames[0]}`
        path = path;
        break;
      case edit_event === "edit-event":
        // breadcrumbText = `${capitalizeWords(path)}`;
        url = `/${pathnames[0]}`
        path = path;
        break;
      case edit_advertisement === "edit-advertisement":
        url = `/${pathnames[0]}`
        path = path;
        break;
      case edit_banner === "edit-banner":
        url = `/${pathnames[0]}`
        path = path;
        break;
      case view_game === "view-game" || view_game === "edit-game":
        url = `/${pathnames[0]}`
        path = path;
        break;
      case view_tournament === "view-tournament" || view_tournament === "edit-tournament":
        url = `/${pathnames[0]}`
        path = path;
        break;
      case edit_product === "edit-product" || edit_product === "view-product":
        url = `/${pathnames[0]}`
        path = path;
        break;
      case edit_user_details === "edit-user-details" || edit_user_details === "view-user-details":
        url = `/${pathnames[0]}`
        path = path;
        break;
      case edit_sub_admin === "edit-sub-admin":
        url = `/${pathnames[0]}`
        path = path;
        break;
      case edit_post === "edit-post":
        url = `/${pathnames[0]}`
        path = path;
        break;
      case edit_group === "edit-group" || chat_group === "chat-groups" || group_details === "group-details":
        url = `/${pathnames[0]}/${pathnames[1]}`
        path = path;
        break;
      case payment_details === "payment-details":
        url = `/${pathnames[0]}`
        path = path;
        break;
      case edit_email_marketing === "edit-email-marketing":
        url = `/${pathnames[0]}`
        path = path;
        break;
      case edit_story === "edit-story" || edit_story === "view-story":
        url = `/${pathnames[0]}`
        path = path;
        break;
      case report_details === "report-details":
        url = `/${pathnames[0]}`
        path = path;
        break;
      case view_settings === "view-settings" || view_settings === "edit-settings":
        url = `/${pathnames[0]}`
        path = path;
        break;
      case view_settings === "view-country" || view_settings === "edit-country":
        url = `/${pathnames[0]}`
        path = path;
        break;
      case view_settings === "view-reason" || view_settings === "edit-reason":
        url = `/${pathnames[0]}`
        path = path;
        break;
      case view_settings === "view-subscription" || view_settings === "edit-subscription":
        url = `/${pathnames[0]}`
        path = path;
        break;
      case view_settings === "dispute-details":
        url = `/${pathnames[0]}`
        path = path;
        break;
      case view_settings === "private-organizers-details" || view_settings === "edit-organizer":
        url = `/${pathnames[0]}`
        path = path;
        break;
      case edit_push_notifications === "edit-push-notifications":
        url = `/${pathnames[0]}`
        path = path;
        break;
      case view_contactus === "details" || view_contactus === "reply":
        url = `/${pathnames[0]}`
        path = path;
        break;
      case view_nonwithdrawn === "details":
        url = `/${pathnames[0]}`
        path = path;
        break;
      default:
        url = `/${pathnames.slice(0, index + 1).join("/")}`;
    }
    // console.log(url,"url");
    // console.log(pathnames,"pathnames");
    return (
      <Link key={index} to={url} className={"breadcrumb-item"}>
        <span className="breadcrumb-text">{capitalizeWords(path)}</span>
      </Link>
    );
  });
  
  // const showBreadcrumbs = pathnames.map((path, index) => {
  //   let url = "";
  //   if (!isNaN(pathnames[index + 1])) {
  //     url = `/${pathnames.join("/")}`;
  //   } else {
  //     url = `/${pathnames.slice(0, index + 1).join("/")}`;
  //   }
  //   return (
  //     <Link key={index} to={url} className={`${isNaN(path) ? "breadcrumb-item" : ""} disable_text`}>
  //       <span className="breadcrumb-text">{capitalizeWords(!isNaN(path) ? "" : path)}</span>
  //     </Link>
  //   );
  // });

  return (
    <div className="header-holder header-holder-desktop custom_holder_bg">
      <div className="header-container container-fluid g-5">
        <div className="header-wrap header-wrap-block justify-content-start">
          <div className="breadcrumb breadcrumb-transparent mb-0">
            {showBreadcrumbs}
            {/* <Link to={"/"} className="breadcrumb-item disable_text">
              <span className="breadcrumb-text">Dashboard</span>
            </Link>
            <Link to={path} className="breadcrumb-item">
              <span className="breadcrumb-text">{pathName}</span>
            </Link> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Breadcrumb;
