import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link, useParams } from "react-router-dom";
import { setLoader } from "../../../store/slices/masterSlice";
import { TOAST_ERROR, formatDate } from "../../../utils/common.service";
import * as API from "../../../utils/api.service";
import useDebounce from "../../../hooks/useDebounce";
import { DATE_DASH_TIME_FORMAT, SEARCH_DELAY } from "../../../app.config";
import { Pagination } from "../../common";

const GoldUsers = () => {
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({ search: "", country: "" });
  const [membersList, setMembersList] = useState(null);
  console.log("membersList: ", membersList);
  const debounce = useDebounce(filters.search, SEARCH_DELAY);
  const {
    countryList: { data: countryList = [] },
  } = useSelector((state) => state.master);
  const dispatch = useDispatch();
  const { id: subscriptionId } = useParams();

  useEffect(() => {
    subscriptionId && fetchGoldUsersList();
  }, [page, debounce, filters.country, subscriptionId]);

  const fetchGoldUsersList = () => {
    let tempFilters = `&membership=${subscriptionId}`;
    if (debounce) tempFilters = `${tempFilters}&search=${debounce}`;
    if (filters.country) tempFilters = `${tempFilters}&country=${filters.country}`;
    getGoldUsers(page, tempFilters);
  };

  const getGoldUsers = async (page, filters) => {
    try {
      dispatch(setLoader(true));
      const { code, message, data } = await API.getGoldMembersList(page, filters);
      if (code === 1) setMembersList(data);
      else TOAST_ERROR(message);
      dispatch(setLoader(false));
    } catch (err) {
      dispatch(setLoader(false));
      TOAST_ERROR(err.message);
    }
  };

  const showCountryList = useMemo(() => {
    return countryList.map((item) => {
      return (
        <option key={item.id} value={item.value}>
          {item.label}
        </option>
      );
    });
  }, [countryList]);

  const showGoldUsersList = useMemo(() => {
    if (membersList && membersList?.results?.length === 0)
      return (
        <tr>
          <td colSpan={7}>No data found</td>
        </tr>
      );
    else
      return membersList?.results?.map((item) => {
        return (
          <tr key={item?.id}>
            <td>
              {item?.name}
              <br />
              <Link to={`/users/view-user-details/${item.id}`}>{item?.username}</Link>
            </td>
            <td>{item?.user_country?.full_name}</td>
            <td>{formatDate(item?.membership_details[0]?.start_date, DATE_DASH_TIME_FORMAT)}</td>
            <td>{formatDate(item?.membership_details[0]?.end_date, DATE_DASH_TIME_FORMAT)}</td>
          </tr>
        );
      });
  }, [membersList?.results]);

  return (
    <div className="content">
      <div className="container-fluid g-5">
        <div className="row">
          <div className="col-12">
            <div className="portlet">
              <div className="horizontal_filter_card">
                <div className="d-flex justify-content-between">
                  <div className="search_group w-25">
                    <select
                      className="form-select form-select-lg"
                      defaultValue=""
                      onChange={(e) => setFilters({ ...filters, country: e.target.value })}
                    >
                      <option value="">Select country</option>
                      {showCountryList}
                    </select>
                  </div>
                  <div className="search_group w-25">
                    <input
                      onChange={(e) => setFilters({ ...filters, search: e.target.value })}
                      className="form-control form-control-lg"
                      type="text"
                      placeholder="Search"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-12">
            <div className="portlet">
              <div className="portlet-header portlet-header-bordered">
                <h3 className="portlet-title">Gold Users List</h3>
              </div>
              <div className="horizontal_filter_card">
                <div className="table-responsive table-responsive-lg">
                  <table className="table mb-0">
                    <thead>
                      <tr>
                        <th>Username</th>
                        <th>Country</th>
                        <th>Start Date & Time</th>
                        <th>End Date & Time</th>
                      </tr>
                    </thead>
                    <tbody className="table-group-divider">{showGoldUsersList}</tbody>
                  </table>
                </div>
              </div>
              <Pagination handlePageChange={(count) => setPage(count)} page={page} totalCount={membersList?.count} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoldUsers;
