import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import { DropZone } from "../../common";
import uploadImageIcon from "../../../assets/images/site_icons/Duotone.svg";
import { TOAST_ERROR, convertToBase64 } from "../../../utils/common.service";
import * as API from "../../../utils/api.service";

export default function AppSettingsWelcome({
  showCountryList,
  onSubmit,
  appSettings,
  isView,
}) {
  const [previewImage, setPreviewImage] = useState("");
  const {
    handleSubmit,
    register,
    setValue,
    clearErrors,
    watch,
    control,
    formState: { errors },
  } = useForm();

  const handleImageChange = async (file) => {
    if (file) {
      setValue("welcome_illustration_images", file);
      setPreviewImage(await convertToBase64(file));
      clearErrors("welcome_illustration_images");
    }
  };

  useEffect(() => {
    if (appSettings) {
      const {
        message,
        bonus,
        welcome_illustration_img,
        wel_come_cash,
        country,
      } = appSettings;
      setValue("country", country);
      setValue("message", message);
      setValue("bonus", bonus);
      setValue("wel_come_cash", wel_come_cash);
      setValue(
        "welcome_illustration_images",
        welcome_illustration_img?.split("/")[5]
      );
      setPreviewImage(welcome_illustration_img);
    }
  }, [appSettings]);

  return (
    <div className="row">
      <div className="col-12 col-md-6">
        <div className="portlet">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="horizontal_filter_card">
              <div className="row mb-4">
                <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                  Country
                </label>
                <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <select
                    {...register("country", {
                      required: "Country is required.",
                    })}
                    aria-invalid={errors.country ? "true" : "false"}
                    className="form-control form-control-lg"
                    type="text"
                    defaultValue=""
                    disabled={isView}
                  >
                    <option value="">Select country</option>
                    {showCountryList}
                  </select>
                  {errors.country && (
                    <p role="alert" className="text-danger">
                      {errors.country?.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                  Welcome Message
                </label>
                <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <input
                    {...register("message", {
                      required: "Message is required.",
                      maxLength: {
                        value: 250,
                        message: "Message should not exceed 250 characters.",
                      },
                    })}
                    aria-invalid={errors.message ? "true" : "false"}
                    className="form-control form-control-lg"
                    type="text"
                    placeholder="Enter message..."
                    disabled={isView}
                  />
                  {errors.message && (
                    <p role="alert" className="text-danger">
                      {errors.message?.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                  Welcome Bonus
                </label>
                <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <input
                    {...register("bonus", {
                      required: "Bonus is required.",
                      pattern: {
                        value: /^[0-9]+$/,
                        message: "Must be a number.",
                      },
                    })}
                    aria-invalid={errors.bonus ? "true" : "false"}
                    className="form-control form-control-lg"
                    type="text"
                    placeholder="Enter welcome bonus"
                    disabled={isView}
                  />
                  {errors.bonus && (
                    <p role="alert" className="text-danger">
                      {errors.bonus?.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                  Welcome Cash Balance
                </label>
                <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <input
                    {...register("wel_come_cash", {
                      required: "Welcome cash is required.",
                      pattern: {
                        value: /^[0-9]+$/,
                        message: "Must be a number.",
                      },
                    })}
                    aria-invalid={errors.wel_come_cash ? "true" : "false"}
                    className="form-control form-control-lg"
                    type="text"
                    placeholder="Enter welcome cash"
                    disabled={isView}
                  />
                  {errors.wel_come_cash && (
                    <p role="alert" className="text-danger">
                      {errors?.wel_come_cash?.message}
                    </p>
                  )}
                </div>
              </div>

              <div className="row mb-4">
                <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                  Welcome Illustration
                </label>
                <div
                  className={`${
                    isView ? "disabled" : ""
                  } col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12`}
                >
                  <Controller
                    name="welcome_illustration_images"
                    control={control}
                    defaultValue={null}
                    rules={{ required: "Image is required." }}
                    render={({ field }) => (
                      <DropZone onDrop={(file) => handleImageChange(file)}>
                        <div className="container">
                          <label
                            htmlFor="et_pb_contact_brand_file_request_0"
                            className="et_pb_contact_form_label"
                          >
                            {!watch("welcome_illustration_images") ? (
                              <>
                                <img src={uploadImageIcon} alt="Duotone" />
                                Enter
                                <span>Quick file uploader</span>
                                <span>
                                  Drag & Drop or choose files from computer
                                </span>
                              </>
                            ) : (
                              <>
                                <img
                                  src={
                                    previewImage ||
                                    watch("welcome_illustration_images")
                                  }
                                  alt="Duotone"
                                  height={150}
                                  width={150}
                                  className="rounded"
                                />
                                <span>
                                  {watch("welcome_illustration_images")?.name ||
                                    watch("welcome_illustration_images")}
                                </span>
                              </>
                            )}
                          </label>
                          <input
                            type="file"
                            id="et_pb_contact_brand_file_request_0"
                            className="file-upload"
                            accept="image/*"
                            onChange={(e) =>
                              handleImageChange(e.target.files[0])
                            }
                          />
                        </div>
                      </DropZone>
                    )}
                  />
                  {errors.welcome_illustration_images && (
                    <p role="alert" className="text-danger">
                      {errors.welcome_illustration_images.message}
                    </p>
                  )}
                </div>
              </div>

              {!isView && (
                <div className="row justify-content-end">
                  <div className="col-12 col-md-12">
                    <div className="text-start">
                      <button className="btn btn-primary btn-lg common_hover">
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
