import React, { useState } from "react";
import { useSelector } from "react-redux";
import { CustomModal } from "../../common";

const DeleteModal = ({ handleDelete }) => {
  const { isModalOpen } = useSelector((state) => state.master);
  return (
    <CustomModal title={"Delete"} buttonName={"Delete"} onSave={() => handleDelete(isModalOpen?.data)}>
      <p className="h5">Are you sure?</p>
    </CustomModal>
  );
};

export default DeleteModal;
