import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "../../utils/api.service";
import { setLoader } from "./masterSlice";

export const getTournamentList = createAsyncThunk("tournamentList", async ({ page, filtersQuery }, { dispatch }) => {
  try {
    dispatch(setLoader(true));
    const { data: tournamentList } = await API.getTournamentList(page, filtersQuery);
    dispatch(setLoader(false));
    return tournamentList;
  } catch (error) {
    dispatch(setLoader(false));
    throw error;
  }
});

export const getTournamentListByGameId = createAsyncThunk(
  "tournamentListByGameId",
  async ({ id, page, filters }, { dispatch }) => {
    try {
      dispatch(setLoader(true));
      const { data: tournamentListByGameId } = await API.getTournamentListByGameId(id, page, filters);
      dispatch(setLoader(false));
      return tournamentListByGameId;
    } catch (error) {
      dispatch(setLoader(false));
      throw error;
    }
  }
);

export const getRoomListByTournamentId = createAsyncThunk(
  "roomListByTournamentId",
  async ({ id, page, filters }, { dispatch }) => {
    try {
      dispatch(setLoader(true));
      const { data: roomListByTournamentId } = await API.getRoomListByTournamentId(id, page, filters);
      dispatch(setLoader(false));
      return roomListByTournamentId;
    } catch (error) {
      dispatch(setLoader(false));
      throw error;
    }
  }
);

export const getRoomDetails = createAsyncThunk("roomDetails", async (id, { dispatch }) => {
  try {
    dispatch(setLoader(true));
    const { data: roomDetails } = await API.getRoomDetails(id);
    dispatch(setLoader(false));
    return roomDetails;
  } catch (error) {
    dispatch(setLoader(false));
    throw error;
  }
});

export const getJoinedMembersListByGroup = createAsyncThunk("joinedMembersListByGroup", async (id, { dispatch }) => {
  try {
    dispatch(setLoader(true));
    const { data: joinedMembersListByGroup } = await API.getJoinedMembersListByGroup(id);
    dispatch(setLoader(false));
    return joinedMembersListByGroup;
  } catch (error) {
    dispatch(setLoader(false));
    throw error;
  }
});
export const getJoinedMembersList = createAsyncThunk("joinedMembersList", async (id, { dispatch }) => {
  try {
    dispatch(setLoader(true));
    const { data: joinedMembersList } = await API.getJoinedMembersList(id);
    dispatch(setLoader(false));
    return joinedMembersList;
  } catch (error) {
    dispatch(setLoader(false));
    throw error;
  }
});

export const getRoomImages = createAsyncThunk("roomImageList", async (id, { dispatch }) => {
  try {
    dispatch(setLoader(true));
    const { data: roomImageList } = await API.getRoomImages(id);
    dispatch(setLoader(false));
    return roomImageList;
  } catch (error) {
    dispatch(setLoader(false));
    throw error;
  }
});

const initialState = {
  tournamentList: {
    data: [],
    error: null,
  },
  tournamentListByGameId: {
    data: [],
    error: null,
  },
  roomListByTournamentId: {
    data: [],
    error: null,
  },
  roomDetails: {
    data: {},
    error: null,
  },
  joinedMembersListByGroup: {
    data: [],
    error: null,
  },
  joinedMembersList: {
    data: [],
    error: null,
  },
  roomImageList: {
    data: [],
    error: null,
  },
};

const tournamentSlice = createSlice({
  name: "TOURNAMENT",
  initialState,
  reducers: {
    setRoomDetails: (state) => {
      state.roomDetails.data = {};
    },
    setJoinedMembersListByGroup: (state) => {
      state.joinedMembersListByGroup.data = [];
    },
    setJoinedMembersList: (state) => {
      state.joinedMembersList.data = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTournamentList.fulfilled, (state, action) => {
        state.tournamentList.data = action.payload;
      })
      .addCase(getTournamentList.rejected, (state, action) => {
        state.tournamentList.error = action.error.message;
      })
      .addCase(getTournamentListByGameId.fulfilled, (state, action) => {
        state.tournamentListByGameId.data = action.payload;
      })
      .addCase(getTournamentListByGameId.rejected, (state, action) => {
        state.tournamentListByGameId.error = action.error.message;
      })
      .addCase(getRoomListByTournamentId.fulfilled, (state, action) => {
        state.roomListByTournamentId.data = action.payload;
      })
      .addCase(getRoomListByTournamentId.rejected, (state, action) => {
        state.roomListByTournamentId.error = action.error.message;
      })
      .addCase(getRoomDetails.fulfilled, (state, action) => {
        state.roomDetails.data = action.payload;
      })
      .addCase(getRoomDetails.rejected, (state, action) => {
        state.roomDetails.error = action.error.message;
      })
      .addCase(getJoinedMembersList.fulfilled, (state, action) => {
        state.joinedMembersList.data = action.payload;
      })
      .addCase(getJoinedMembersList.rejected, (state, action) => {
        state.joinedMembersList.error = action.error.message;
      })
      .addCase(getJoinedMembersListByGroup.fulfilled, (state, action) => {
        state.joinedMembersListByGroup.data = action.payload;
      })
      .addCase(getJoinedMembersListByGroup.rejected, (state, action) => {
        state.joinedMembersListByGroup.error = action.error.message;
      })
      .addCase(getRoomImages.fulfilled, (state, action) => {
        state.roomImageList.data = action.payload;
      })
      .addCase(getRoomImages.rejected, (state, action) => {
        state.roomImageList.error = action.error.message;
      });
  },
});

export default tournamentSlice.reducer;
