import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "../../utils/api.service";
import { setLoader } from "./masterSlice";

export const getSupportList = createAsyncThunk("supportList", async ({ page, filters }, { dispatch }) => {
  try {
    dispatch(setLoader(true));
    const { data: supportList } = await API.getSupportList(page, filters);
    dispatch(setLoader(false));
    return supportList;
  } catch (error) {
    dispatch(setLoader(false));
    throw error;
  }
});

export const getAdminAcceptedSupportList = createAsyncThunk(
  "adminAcceptedSupportList",
  async ({ page, filters }, { dispatch }) => {
    try {
      dispatch(setLoader(true));
      const { data: adminAcceptedSupportList } = await API.getAdminAcceptedSupportList(page, filters);
      dispatch(setLoader(false));
      return adminAcceptedSupportList;
    } catch (error) {
      dispatch(setLoader(false));
      throw error;
    }
  }
);

export const getTicketSupportList = createAsyncThunk("ticketSupportList", async ({ page, filters }, { dispatch }) => {
  try {
    dispatch(setLoader(true));
    const { data: ticketSupportList } = await API.getTicketSupportList(page, filters);
    dispatch(setLoader(false));
    return ticketSupportList;
  } catch (error) {
    dispatch(setLoader(false));
    throw error;
  }
});

const initialState = {
  supportList: {
    data: {},
    error: null,
  },
  adminAcceptedSupportList: {
    data: {},
    error: null,
  },
  ticketSupportList: {
    data: {},
    error: null,
  },
};

const supportSlice = createSlice({
  name: "SUPPORT",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSupportList.fulfilled, (state, action) => {
        state.supportList.data = action.payload;
      })
      .addCase(getSupportList.rejected, (state, action) => {
        state.supportList.error = action.error.message;
      })
      .addCase(getAdminAcceptedSupportList.fulfilled, (state, action) => {
        state.adminAcceptedSupportList.data = action.payload;
      })
      .addCase(getAdminAcceptedSupportList.rejected, (state, action) => {
        state.adminAcceptedSupportList.error = action.error.message;
      })
      .addCase(getTicketSupportList.fulfilled, (state, action) => {
        state.ticketSupportList.data = action.payload;
      })
      .addCase(getTicketSupportList.rejected, (state, action) => {
        state.ticketSupportList.error = action.error.message;
      });
  },
});

export default supportSlice.reducer;
