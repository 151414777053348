import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "../../utils/api.service";
import { setLoader } from "./masterSlice";

export const getBannerList = createAsyncThunk("bannerList", async ({ page, filters }, { dispatch }) => {
  try {
    dispatch(setLoader(true));
    const { data: bannerList } = await API.getBannersList(page, filters);
    dispatch(setLoader(false));
    return bannerList;
  } catch (error) {
    dispatch(setLoader(false));
    throw error;
  }
});

export const getAppRedirectionList = createAsyncThunk("appRedirectionList", async (_, { dispatch }) => {
  try {
    dispatch(setLoader(true));
    const { data: appRedirectionList } = await API.getAppRedirectionList();
    dispatch(setLoader(false));
    return appRedirectionList;
  } catch (error) {
    dispatch(setLoader(false));
    throw error;
  }
});

const initialState = {
  bannerList: {
    data: [],
    error: null,
  },
  appRedirectionList: {
    data: [],
    error: null,
  },
};

const bannerSlice = createSlice({
  name: "BANNER",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBannerList.fulfilled, (state, action) => {
        state.bannerList.data = action.payload;
      })
      .addCase(getBannerList.rejected, (state, action) => {
        state.bannerList.error = action.error.message;
      })
      .addCase(getAppRedirectionList.fulfilled, (state, action) => {
        state.appRedirectionList.data = action.payload;
      })
      .addCase(getAppRedirectionList.rejected, (state, action) => {
        state.appRedirectionList.error = action.error.message;
      });
  },
});

export default bannerSlice.reducer;
