import { toast } from "react-toastify";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { TIME_FORMAT, TIME_FORMAT_24 } from "../app.config";

const capitalizeWords = (str) => {
  const hyphenIndex = str.indexOf("-");
  const underscoreIndex = str.indexOf("_");
  if (hyphenIndex !== -1 || underscoreIndex !== -1) {
    let words;
    if (hyphenIndex !== -1) words = str.split("-");
    else words = str.split("_");
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    return capitalizedWords.join(" ");
  } else {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
};

const formatArrayToString = (array) => {
  let temp = "";
  for (let item of array) {
    if (temp) temp = `${temp}, ${item?.label || item}`;
    else temp = item?.label || item;
  }
  return temp;
};

const validateLessThanMax = (min, max, errMsg) => {
  return min < max || errMsg;
};

const isValidUrl = (url) => {
  const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
  return urlRegex.test(url);
};

const validateTime = (time1, time2, errMsg, extraTime) => {
  let time1Parsed = dayjs(time1, TIME_FORMAT_24);
  const time2Parsed = dayjs(time2, TIME_FORMAT_24);
  extraTime && time1Parsed.add(extraTime, "minute");
  const isTime1Smaller = time1Parsed.isBefore(time2Parsed);
  return isTime1Smaller || errMsg;
};

const isActive = (status) => {
  return status ? "Active" : "Inactive";
};

const formatDate = (date, format) => {
  return dayjs(date).format(format);
};

const formatDateFromDatePicker = (date) => {
  return dayjs(date).format('DD/MM/YYYY hh:mm A');
}

const formatTimeToDate = (time) => {
  dayjs.extend(customParseFormat);
  return dayjs(time, TIME_FORMAT_24);
};

const formatDateLocalToUtc = (date, format) => {
  const localTime = dayjs(date);
  const localOffset = localTime.utcOffset();
  return localTime.subtract(localOffset, "minute").format(format);
};

const formatDateUtcToLocal = (date, format) => {
  const localTime = dayjs(formatTimeToDate(date));
  const localOffset = localTime.utcOffset();
  return localTime.add(localOffset, "minute").format(format);
};

const convert12hrTo24hr = (time) => {
  dayjs.extend(customParseFormat);
  return dayjs(time, TIME_FORMAT).format(TIME_FORMAT_24);
};

const convert24hrTo12hr = (time) => {
  dayjs.extend(customParseFormat);
  return dayjs(time, TIME_FORMAT_24).format(TIME_FORMAT);
};

const convertToBase64 = async (file) => {

  // let video_url = file.type.includes("video") && URL.createObjectURL(file);
  // console.log('video_url...', video_url);

  if ((file.type !== undefined || file.type != null) && file.type.includes("video")) {
    return new Promise((resolve, reject) => {
      // resolve(URL.createObjectURL(file));

      const videoUrl = URL.createObjectURL(file);
      const video = document.createElement('video');

      video.addEventListener('loadeddata', () => {
        const canvas = document.createElement('canvas');
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        const ctx = canvas.getContext('2d');

        // Seek to a specific time if needed (here we use the first frame)
        video.currentTime = 0;

        video.addEventListener('seeked', () => {
          ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
          const thumbnailUrl = canvas.toDataURL('image/png');
          resolve(thumbnailUrl);
        }, {once: true});

        video.currentTime = 0;
      }, {once: true});

      video.addEventListener('error', (e) => {
        reject(new Error(`Error loading video: ${e.message}`));
      });

      video.src = videoUrl;
      video.load();
    });
  } else {
    return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = reject;
    file && fileReader.readAsDataURL(file);
    });
  }
};

const getRandomString = () => {
  var str = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  var length = 12;
  var result = "";
  for (var i = length; i > 0; --i)
    result += str[Math.floor(Math.random() * str.length)];
  return result + JSON.stringify(new Date().getTime());
}

const TOAST_SUCCESS = (message) => {
  return toast.success(message);
};

const TOAST_INFO = (message) => {
  return toast.info(message);
};

const TOAST_ERROR = (message) => {
  return toast.error(message);
};

const TOAST_WARNING = (message) => {
  return toast.warning(message);
};

const getTimeValues = (count) => {
  const days = Math.floor(count / (1000 * 60 * 60 * 24));
  const hours = Math.floor((count % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((count % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((count % (1000 * 60)) / 1000);
  if (days + hours + minutes + seconds <= 0) {
    return { days: 0, hours: 0, minutes: 0, seconds: 0 };
  }
  return {
    days: isNaN(days) ? 0 : days,
    hours: isNaN(hours) ? 0 : hours,
    minutes: isNaN(minutes) ? 0 : minutes,
    seconds: isNaN(seconds) ? 0 : seconds,
  };
};

export {
  capitalizeWords,
  formatArrayToString,
  validateLessThanMax,
  validateTime,
  isValidUrl,
  isActive,
  formatDate,
  formatDateFromDatePicker,
  formatDateLocalToUtc,
  formatDateUtcToLocal,
  formatTimeToDate,
  convert12hrTo24hr,
  convert24hrTo12hr,
  convertToBase64,
  getRandomString,
  getTimeValues,
  TOAST_SUCCESS,
  TOAST_INFO,
  TOAST_ERROR,
  TOAST_WARNING,
};
