import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "../../utils/api.service";
import { setLoader } from "./masterSlice";

export const getSubAdminList = createAsyncThunk("subAdminList", async ({ page, filters }, { dispatch }) => {
  try {
    dispatch(setLoader(true));
    let { data: subAdminList } = await API.getSubAdminList(page, filters);
    subAdminList = {
      ...subAdminList,
      results: [
        ...subAdminList?.results.map((item) => ({
          ...item,
          value: item.id,
          label: item.name,
        })),
      ],
    };
    dispatch(setLoader(false));
    return subAdminList;
  } catch (error) {
    dispatch(setLoader(false));
    throw error;
  }
});

export const getAdminList = createAsyncThunk("adminList", async ({ page, filters }, { dispatch }) => {
  try {
    dispatch(setLoader(true));
    const { data: adminList } = await API.getSubAdminList(page, filters);
    dispatch(setLoader(false));
    return adminList;
  } catch (error) {
    dispatch(setLoader(false));
    throw error;
  }
});

export const getUserDetails = createAsyncThunk("userDetails", async ({ id, user_type }, { dispatch }) => {
  try {
    dispatch(setLoader(true));
    let { data: userDetails } =
      user_type === "sub_admin" || user_type === "admin" ? await API.getSubAdminDetails(id) : await API.getOrganizerDetails(id);
    let tempObj = {};
    if (userDetails?.permissions_groups) {
      for (let parent of userDetails?.permissions_groups) {
        for (let child of parent.menus_permission || parent.permission) {
          tempObj[child.name] = true;
        }
      }
      userDetails.permissions = tempObj;
    }
    dispatch(setLoader(false));
    return userDetails;
  } catch (error) {
    dispatch(setLoader(false));
    throw error;
  }
});

const initialState = {
  subAdminList: {
    data: {},
    error: null,
  },
  adminList: {
    data: {},
    error: null,
  },
  userDetails: {
    data: {},
    error: null,
  },
};

const subAdminSlice = createSlice({
  name: "SUB_ADMIN",
  initialState,
  reducers: {
    setUserDetails: (state, action) => {
      state.userDetails.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSubAdminList.fulfilled, (state, action) => {
        state.subAdminList.data = action.payload;
      })
      .addCase(getSubAdminList.rejected, (state, action) => {
        state.subAdminList.error = action.error.message;
      })
      .addCase(getAdminList.fulfilled, (state, action) => {
        state.adminList.data = action.payload;
      })
      .addCase(getAdminList.rejected, (state, action) => {
        state.adminList.error = action.error.message;
      })
      .addCase(getUserDetails.fulfilled, (state, action) => {
        state.userDetails.data = action.payload;
      })
      .addCase(getUserDetails.rejected, (state, action) => {
        state.userDetails.error = action.error.message;
      });
  },
});

export const { setUserDetails } = subAdminSlice.actions;
export default subAdminSlice.reducer;
