import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import AppSettingsAddWithdraw from "./AppSettingsAddWithdraw";
import AppSettingsEmailConfig from "./AppSettingsEmailConfig";
import AppSettingsGroupChat from "./AppSettingsGroupChat";
import AppSettingsHeader from "./AppSettingsHeader";
import AppSettingsPaymentGateways from "./AppSettingsPaymentGateways";
import AppSettingsWelcome from "./AppSettingsWelcome";
import AppSettingsWorldChat from "./AppSettingsWorldChat";
import { setLoader } from "../../../store/slices/masterSlice";
import { TOAST_ERROR, TOAST_SUCCESS } from "../../../utils/common.service";
import * as API from "../../../utils/api.service";
import { uploadImageOnAWS } from "../../../utils/aws.service";
import AppSettingsPayout from "./AppSettingsPayout";
import AppSettingsAppUpdate from "./AppSettingsAppUpdate";
import AppSettingsProConditions from "./AppSettingsProConditions";
import AppSettingsTDS from "./AppSettingsTDS";
import AppSettingsSMSGateway from "./AppSettingsSMSGateway";
import AppSettingsReferral from "./AppSettingsReferral";
import AppSettingsBlockAPK from "./AppSettingsBlockAPK";
import AppSettingsAds from "./AppSettingsAds";
import AppSettingsBlockWords from "./AppSettingsBlockWords";
import AppSettingsColor from "./AppSettingsColor";
import AppSettingsLocation from "./AppSettingsLocation";
import AppSettingsEmailPurpose from "./AppSettingsEmailPurpose";
import AppSettingsGeneral from "./AppSettingsGeneral";
import AppSettingsBlockImageLabels from "./AppSettingsBlockImageLabels";
import AppSettingsChatBot from "./AppSettingsChatBot";
import AppSettingsReportLimit from "./AppSettingsReportLimit";

const AddCountryWiseSettings = () => {
  const [step, setStep] = useState(1);
  const [appSettings, setAppSettings] = useState({});
  // const {
  //   userDetails: { data: { user_type = "", permissions = {} } = {} } = {},
  // } = useSelector((state) => state.subAdmin);
  console.log("appSettings: ", appSettings);
  const [emailPurposeList, setEmailPurposeList] = useState(null);
  const location = useLocation();

  const {
    countryList: { data: countryList = [] },
  } = useSelector((state) => state.master);
  const dispatch = useDispatch();
  const { id: settingId } = useParams();

  useEffect(() => {
    settingId && getAppSettingsDetails();
  }, [settingId]);

  const getAppSettingsDetails = async () => {
    try {
      dispatch(setLoader(false));
      const { code, message, data } = await API.getAppSettingsDetails(
        settingId
      );
      dispatch(setLoader(false));
      if (code === 1) {
        setAppSettings(data);
      } else TOAST_ERROR(message);
    } catch (err) {
      dispatch(setLoader(false));
      TOAST_ERROR(err.message);
    }
  };

  const onSubmit = async (data) => {
    try {
      dispatch(setLoader(true));
      if (data?.welcome_illustration_images instanceof Blob)
        data.welcome_illustration_images = await uploadImageOnAWS(
          data.welcome_illustration_images,
          "app_setting"
        );
      if (data?.world_chat_illustration_images instanceof Blob)
        data.world_chat_illustration_images = await uploadImageOnAWS(
          data.world_chat_illustration_images,
          "app_setting"
        );
        
      //send data to backend in hh:mm:ss formate
      var second = "00";
      
      if(data.start_time_hour != "" && data.start_time_hour != undefined && data.end_time_hour != "" && data.end_time_hour != undefined  && data.start_time_minute != "" && data.start_time_minute != undefined && data.end_time_minute != "" && data.end_time_minute != undefined){
        data.start_time  =  [data.start_time_hour, data.start_time_minute, second].join(":");
        data.end_time = [data.end_time_hour, data.end_time_minute, second].join(":");
      }

      const { code, message } = settingId
        ? await API.updateAppSettings(settingId, data)
        : await API.addAppSettings(data);
      dispatch(setLoader(false));
      if (code === 1) {
        TOAST_SUCCESS(message);
        settingId && getAppSettingsDetails();
      } else TOAST_ERROR(message);
    } catch (err) {
      dispatch(setLoader(false));
      TOAST_ERROR(err.message);
    }
  };

  const getEmailPurposeList = async () => {
    try {
      dispatch(setLoader(true));
      const { code, message, data } = await API.getEmailPurposeList(1);
      dispatch(setLoader(false));
      if (code === 1) {
        setEmailPurposeList(data);
      } else TOAST_ERROR(message);
    } catch (err) {
      dispatch(setLoader(false));
      TOAST_ERROR(err.message);
    }
  };

  useEffect(() => {
    getEmailPurposeList();
  }, []);

  const showCountryList = useMemo(() => {
    return countryList.map((item) => {
      return (
        <option key={item.id} value={item.value}>
          {item.label}
        </option>
      );
    });
  }, [countryList]);

  const isView = location.pathname.split("/")[2] === "view-settings";

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <AppSettingsWelcome
            onSubmit={onSubmit}
            showCountryList={showCountryList}
            appSettings={appSettings}
            isView={isView}
          />
        );
      case 2:
        return (
          <AppSettingsWorldChat
            onSubmit={onSubmit}
            appSettings={appSettings}
            isView={isView}
          />
        );
      case 3:
        return (
          <AppSettingsGroupChat
            onSubmit={onSubmit}
            appSettings={appSettings}
            isView={isView}
          />
        );
      case 4:
        return (
          <AppSettingsAddWithdraw
            onSubmit={onSubmit}
            appSettings={appSettings}
            isView={isView}
          />
        );
      case 5:
        return (
          <AppSettingsEmailConfig
            onSubmit={onSubmit}
            appSettings={appSettings}
            emailPurposeList={emailPurposeList}
            isView={isView}
          />
        );
      case 6:
        return (
          <AppSettingsPaymentGateways
            onSubmit={onSubmit}
            appSettings={appSettings}
            isView={isView}
          />
        );
      case 7:
        return (
          <AppSettingsPayout
            onSubmit={onSubmit}
            appSettings={appSettings}
            isView={isView}
          />
        );
      case 8:
        return (
          <AppSettingsAppUpdate
            onSubmit={onSubmit}
            appSettings={appSettings}
            isView={isView}
          />
        );
      case 9:
        return (
          <AppSettingsProConditions
            onSubmit={onSubmit}
            appSettings={appSettings}
            isView={isView}
          />
        );
      case 10:
        return (
          <AppSettingsTDS
            onSubmit={onSubmit}
            appSettings={appSettings}
            isView={isView}
          />
        );
      case 13:
        return (
          <AppSettingsSMSGateway
            onSubmit={onSubmit}
            appSettings={appSettings}
            isView={isView}
          />
        );
      case 15:
        return (
          <AppSettingsLocation
            onSubmit={onSubmit}
            appSettings={appSettings}
            isView={isView}
          />
        );
      case 17:
        return (
          <AppSettingsReferral
            onSubmit={onSubmit}
            appSettings={appSettings}
            isView={isView}
          />
        );
      case 18:
        return <AppSettingsBlockWords isView={isView} />;
      case 19:
        return (
          <AppSettingsBlockAPK
            onSubmit={onSubmit}
            appSettings={appSettings}
            isView={isView}
          />
        );
      case 20:
        return (
          <AppSettingsAds
            onSubmit={onSubmit}
            appSettings={appSettings}
            isView={isView}
          />
        );
      case 21:
        return (
          <AppSettingsColor
            onSubmit={onSubmit}
            appSettings={appSettings}
            isView={isView}
          />
        );
      case 22:
        return (
          <AppSettingsEmailPurpose
            emailPurposeList={emailPurposeList}
            getEmailPurposeList={getEmailPurposeList}
            isView={isView}
          />
        );
      case 23:
        return (
          <AppSettingsGeneral
            onSubmit={onSubmit}
            appSettings={appSettings}
            isView={isView}
          />
        );
      case 24:
        return (
          <AppSettingsBlockImageLabels
            onSubmit={onSubmit}
            appSettings={appSettings}
            isView={isView}
          />
        );
      case 25:
        return (
          <AppSettingsChatBot
            onSubmit={onSubmit}
            appSettings={appSettings}
            isView={isView}
          />
        );
      case 26:
        return (
          <AppSettingsReportLimit
            onSubmit={onSubmit}
            appSettings={appSettings}
            isView={isView}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="content">
      <div className="container-fluid g-5">
        {appSettings?.country && (
          <AppSettingsHeader
            step={step}
            onStepChange={(newStep) => setStep(newStep)}
            appSettings={appSettings}
          />
        )}
        {renderStep()}
      </div>
    </div>
  );
};

export default AddCountryWiseSettings;
