import React, { useState, useEffect, useMemo, useRef } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { setLoader } from "../../../store/slices/masterSlice";
import { TOAST_ERROR, getTimeValues } from "../../../utils/common.service";
import * as API from "../../../utils/api.service";
import RecentActivityHeader from "./recentActivityHeader";
import timeline_coversation from "../../../assets/images/site_icons/timeline_coversation.svg";

export default function RecentActivity() {
  const [page, setPage] = useState(1);
  const [activityList, setActivityList] = useState(null);
  const [isNewQuery, setIsNewQuery] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const query = location.search.replace("?", "&");
  const { id: userId } = useParams();
  const scrollToBottomRef = useRef(null);

  const handleScroll = () => {
    const scrollRef = scrollToBottomRef.current;
    if (
      scrollRef.scrollHeight - scrollRef.scrollTop === scrollRef.clientHeight &&
      activityList?.results?.length < activityList?.count
    ) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const getActivityList = async () => {
    try {
      dispatch(setLoader(true));
      let filters = `&user__id=${userId}${
        !query ? "&recent_activity_filter=today" : query
      }`;
      const { data, code, message } = await API.getUserActivityList(
        page,
        filters
      );
      dispatch(setLoader(false));
      if (code === 1) {
        if (isNewQuery) {
          setActivityList(data);
          setIsNewQuery(false);
        } else if (activityList) {
          const updatedActivityList = {
            ...activityList,
            results: [...activityList.results, ...data.results],
          };
          setActivityList(updatedActivityList);
        } else setActivityList(data);
      } else TOAST_ERROR(message);
    } catch (error) {
      dispatch(setLoader(false));
      TOAST_ERROR(error.message);
    }
  };

  useEffect(() => {
    !isNewQuery && getActivityList();
  }, [userId, page]);

  useEffect(() => {
    isNewQuery && getActivityList();
  }, [isNewQuery]);

  const handleQueryChange = () => {
    setIsNewQuery(true);
    page > 1 && setPage(1);
  };

  const showRecentActivity = useMemo(() => {
    if (activityList?.results?.length === 0) return <h4>No data found</h4>;
    else
      return activityList?.results?.map((item) => {
        const { days, hours, minutes, seconds } = getTimeValues(
          new Date().getTime() - new Date(item?.insert_date).getTime()
        );
        return (
          <div key={item.id} className="timeline-item">
            <div className="timeline-pin">
              <div className="round_pin">
                <img src={timeline_coversation} alt="icon" />
              </div>
            </div>
            <div className="timeline-content">
              <div className="timeline_heading_block">
                <h5>{item?.text}</h5>
                <p>
                  {days
                    ? `${days} days`
                    : hours
                    ? `${hours} hrs`
                    : minutes
                    ? `${minutes} mins`
                    : seconds
                    ? `${seconds} sec`
                    : "N/A"}
                  &nbsp; ago by{" "}
                  <Link to={`/users/view-user-details/${item?.user?.id}`}>
                    {" "}
                    {item?.user?.name}{" "}
                  </Link>
                </p>
              </div>
              <div className="rich-list-item">
                <div className="rich-list-content">
                  <h4 className="rich-list-title">{item?.description}</h4>
                </div>
              </div>
            </div>
          </div>
        );
      });
  }, [activityList]);

  return (
    <div className="portlet">
      <RecentActivityHeader handleQueryChange={handleQueryChange} />
      <div className="portlet-body">
        <div className="row">
          <div className="col-12 col-md-12">
            <div
              className="timeline rich-list-bordered rich-list-action lcs-scroll-box"
              ref={scrollToBottomRef}
              onScroll={handleScroll}
            >
              {showRecentActivity}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
