import { Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { setModalStatus } from "../../../store/slices/masterSlice";
import {
  ACCEPT_CHAT_SUPPORT,
  ADD_CATEGORY,
  ADD_MEMBERSHIP_FEATURE,
  ADD_SPOTLIGHT,
  CHAT_GROUP_MODAL,
  DELETE_MODAL,
  UPDATE_ORDER,
  UPDATE_TICKET,
} from "../../../app.config";

const CustomModal = ({ title, buttonName, onSave, children, isView }) => {
  const { isModalOpen } = useSelector((state) => state.master);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(
      setModalStatus({
        modalType: "",
        isOpen: false,
        data: null,
      })
    );
  };

  const handleSave = async () => {
    await onSave();
    isModalOpen.modalType !== ADD_SPOTLIGHT &&
      isModalOpen.modalType !== UPDATE_TICKET &&
      isModalOpen.modalType !== ADD_CATEGORY &&
      isModalOpen.modalType !== UPDATE_ORDER &&
      isModalOpen.modalType !== CHAT_GROUP_MODAL &&
      isModalOpen.modalType !== ADD_MEMBERSHIP_FEATURE &&
      handleClose();
  };

  return (
    <Modal
      show={isModalOpen.isOpen}
      size={isModalOpen.modalType === DELETE_MODAL ? "sm" : "lg"}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
        {/* {title==="Add Group"&&(
          <div className="floating-save">
						<button className="btn btn-primary btn-md common_hover px-5">Save</button>
					</div>)} */}

        <button onClick={handleClose} type="button" className="btn btn-label-secondary btn-icon common_hover">
          <i className="fa fa-times"></i>
        </button>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      {title === "Add Card" || title === "Device Ban" || title === "IP Ban" ? (
        <></>
      ) : (
        !isView &&
        title !== "Add Group" && (
          <Modal.Footer>
            <button
              onClick={isModalOpen.modalType === ACCEPT_CHAT_SUPPORT ? async () => await onSave("join") : handleSave}
              className={`${isModalOpen.modalType === DELETE_MODAL ? "btn-danger" : "btn-primary"
                } btn btn-lg common_hover`}
            >
              {buttonName || "Save"}
            </button>
            {isModalOpen.modalType === ACCEPT_CHAT_SUPPORT ? (
              <button onClick={async () => await onSave("reject")} className="btn btn-lg btn-label-primary">
                Reject
              </button>
            ) : (
              <button onClick={handleClose} className="btn btn-label-primary btn-lg common_hover">
                {title === "Edit Cash Balance" ? "Debit" : "Cancel"}
              </button>
            )}
          </Modal.Footer>
        )
      )}
    </Modal>
  );
};

export default CustomModal;

{
  /* <div className="modal fade">
  <div className="modal-dialog modal-dialog-scrollable modal-lg">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">{title}</h5>
        <button
          type="button"
          className="btn btn-label-secondary btn-icon common_hover"
        >
          <i className="fa fa-times"></i>
        </button>
      </div>
      <div className="modal-body">{children}</div>
      <div className="modal-footer">
        <button className="btn btn-primary btn-lg common_hover">
          Save
        </button>
        <button className="btn btn-label-primary btn-lg common_hover">
          Cancel
        </button>
      </div>
    </div>
  </div>
</div> */
}
